

const initstate = {
    InsuranceMasterEdit:{},
    IPPatientDetailes:{},
};

const InsuranceStore = (state = initstate, action) => {

    switch (action.type) {

        case 'InsuranceMaster':
            console.log(action);
            return { ...state, InsuranceMasterEdit: action.value };

        case 'IPPDetailes':
            console.log(action);
            return { ...state, IPPatientDetailes: action.value };

        default:
            return state;
    }
};

export default InsuranceStore;