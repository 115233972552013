import React, { useEffect, useState } from "react";
// import './PatientList.css'
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import profileimg from "../assets/profileimg.jpeg";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});
const EmployeeList = () => {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  console.log(userRecord);
  const [filteredRows, setFilteredRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const [columns] = React.useState([
    { field: "id", headerName: "Serial No", width: 70 },
    { field: "employeeid", headerName: "Employee ID", width: 100 },
    {
      field: "employeephoto",
      headerName: "Employee Photo",
      width: 120,
      renderCell: (params) => (
        <img
          src={params.value || profileimg}
          // src={`data:image/jpg;base64, ${params.value}`}
          alt="Employee"
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />
      ),
    },
    { field: "employeename", headerName: "Employee Name", width: 150 },
    { field: "phone", headerName: "Phone No", width: 120 },
    { field: "designation", headerName: "Designation", width: 160 },
    { field: "location", headerName: "Branch Location", width: 140 },

    {
      field: "Action ",
      headerName: "Profile",
      width: 70,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
    {
      field: "Edit ",
      headerName: "Edit",
      width: 80,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleEditList(params)}>
            <EditIcon />
          </Button>
        </>
      ),
    },
  ]);

  useEffect(() => {
    axios
      .get(
        `https://clinic.vesoftometic.co.in/HRmanagement/get_employee_personaldetails?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        const Records = data.map((userdata, index) => ({
          id: index + 1,
          employeeid: userdata.EmployeeID,
          employeephoto: `data:image/*;base64,${userdata.EmployeePhoto}`,
          employeename: userdata.EmployeeName,
          phone: userdata.PhoneNumber,
          designation: userdata.Designation,
          location: userdata.Locations,
          // department: userdata.Department
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userRecord]);

  const navigate = useNavigate();

  const handleList = (params) => {
    console.log(params);
    const employeeid = params.row.employeeid;
    const location = params.row.location;
    axios
      .get(
        `https://clinic.vesoftometic.co.in/HRmanagement/get_for_employeeprofile?employeeid=${employeeid}&location=${location}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        console.log(response.data);
        dispatchvalue({ type: "foremployeedata", value: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    navigate("/Home/Employee-Profile");
  };

  const handleEditList = (params) => {
    console.log(params);
    const employeeid = params.row.employeeid;

    axios
      .get(
        `https://clinic.vesoftometic.co.in/HRmanagement/getfor_employeeedit?employeeid=${employeeid}`
      )
      .then((response) => {
        console.log(response.data);
        dispatchvalue({ type: "foremployeeedit", value: response.data });
        navigate("/Home/Employee-Register");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    }
  };

  useEffect(() => {
    console.log(searchQuery1);
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.employeename.toLowerCase();
      
      // Check if row.phone exists and is a string before calling toLowerCase()
      const lowerCasephoneno = typeof row.phone === 'string' ? row.phone.toLowerCase() : '';

      const lowerCaseQuery = searchQuery.toLowerCase();
      const lowerCaseQuery1 = searchQuery1.toLowerCase();

      // Check if searchQuery is for FirstName and if it matches
      const matchesFirstName = lowerCaseSupplierName.includes(lowerCaseQuery);
      
      // Check if lowerCasephoneno is a string before calling includes()
      const matchesphoneno = typeof lowerCasephoneno === 'string' ? lowerCasephoneno.includes(lowerCaseQuery1) : false;

      // Combine the conditions based on search type
      return (
        (matchesFirstName || !searchQuery ) && (matchesphoneno || !searchQuery1)
      );
    });

    setFilteredRows(filteredData);
    setPage(0); // Reset page to 0 when the search query changes
  }, [searchQuery, searchQuery1, rows]);


  return (
    <div>
      <div className="appointment">
        <div className="h_head">
          <h3>Employee List</h3>
        </div>

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Employee Name :</label>
            <input
              type="text"
              id="FirstName"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Phone No :</label>
            <input
              type="text"
              id="PhoneNo"
              value={searchQuery1}
              onChange={handleSearchChange}
              placeholder="Enter the Phone No"
            />
          </div>
          {/* <button className="btn_1">
            <SearchIcon />
          </button> */}
        </div>

        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {filteredRows.length === 0 && (
          <div className="norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeList;
