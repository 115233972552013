import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Webcam from 'react-webcam';
import DrawIcon from '@mui/icons-material/Draw';
import SaveIcon from '@mui/icons-material/Save';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import LineWeightRoundedIcon from '@mui/icons-material/LineWeightRounded';
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import { BsEraserFill } from "react-icons/bs";
import './Canva.css';
import axios from "axios";
import html2canvas from 'html2canvas';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';


function Canva({ setoriginalImagecanva, setmergedImagecanva, setshowcamera }) {
    const [lines, setLines] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [colour, setColour] = useState('red');
    const [linewidth, setLineWidth] = useState(2);
    const drawingPadRef = useRef(null);
    const webcamRef = useRef(null);
    const [thickopen, setthickopen] = useState(false);
    const [clropen, setclropen] = useState(false)
    const colorOptions = ['red', 'blue', 'black'];
  // -------------------------------------
  const [facingMode, setFacingMode] = useState('user');
  const devices = ['iPhone', 'iPad', 'Android', 'Mobile', 'Tablet','desktop']
  const [IsmobileorNot,setIsmobileorNot] = useState(null);

  useEffect(() => {
    // Use an axios request within useEffect to avoid infinite rendering
    axios.get('https://clinic.vesoftometic.co.in/patientmanagement/detect_device')
      .then((response) => {
        setIsmobileorNot(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // The empty dependency array ensures that this effect runs only once on mount

  const videoConstraints = {
    facingMode: facingMode,
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };
//   --------------------------

    const handlehidecamera = () => {
        setshowcamera(false)
    }


    const handleWebSidebar1 = () => {
        setthickopen(!thickopen)
    }
    const handleWebSidebar = () => {
        setclropen(!clropen);
    };


    const handleWidth = (widthValue) => {
        setLineWidth(widthValue);
    };

    const handleColor = (color) => {
        setColour(color);
    };

    const handleMouseDown = (event) => {
        event.preventDefault();  // Prevent default behavior for touch events
        setIsDrawing(true);
        setLines([...lines, { points: [], colour, linewidth }]);
    };

    const handleMouseMove = (event) => {
        if (!isDrawing) return;

        const newLines = [...lines];
        const currentLine = newLines[newLines.length - 1];

        // Adjust coordinates for both touch and mouse events
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        const clientY = event.touches ? event.touches[0].clientY : event.clientY;

        const rect = drawingPadRef.current.getBoundingClientRect();

        currentLine.points.push({
            x: clientX - rect.left,
            y: clientY - rect.top,
        });
        setLines(newLines);
    };
    const handleMouseUp = () => {
        setIsDrawing(false);
    };

    const captureImage = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setCapturedImage(imageSrc);
    };

    const retakeImage = () => {
        setCapturedImage(null); // Reset capturedImage to null
        setLines([]); // Clear the drawn lines
    };
    const clearlines = () => {
        setLines([])
    }

    const saveImages = () => {
        // saveOriginalImage();
        setoriginalImagecanva(capturedImage)
        saveMergedImage();
        setshowcamera(false)
    };

    // const saveOriginalImage = () => {
    //     if (capturedImage) {
    //         const link = document.createElement('a');
    //         link.href = capturedImage;
    //         link.download = 'original_image.png';
    //         link.click();
    //     }
    // };

    const saveMergedImage = () => {
        if (capturedImage && lines.length > 0) {
            // Capture the contents of the drawingPadRef (both image and SVG)
            html2canvas(drawingPadRef.current, { useCORS: true }).then((canvas) => {
                const mergedImage = canvas.toDataURL('image/jpeg');

                // Create a download link for the merged image
                // const link = document.createElement('a');
                // link.href = mergedImage;
                // link.download = 'merged_image.png';
                // link.click();
                setmergedImagecanva(mergedImage)
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>Canva</title>
            </Helmet>
            <div className="web_App">
                <div className="web_head">
                    <h3>Image</h3>
                </div>
                {capturedImage ? (
                    <div className="camera-container">
                        <div className="web_camera">
                            <div className='web_camera_svg'
                                ref={drawingPadRef}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onTouchStart={handleMouseDown}
                                onTouchMove={handleMouseMove}
                                onTouchEnd={handleMouseUp}
                            >
                                <img
                                    src={capturedImage}
                                    alt="background"
                                />
                                <svg >
                                    {lines.map((line, index) => (
                                        <polyline
                                            key={index}
                                            points={line.points
                                                .map((point) => `${point.x},${point.y}`)
                                                .join(' ')}
                                            stroke={line.colour}
                                            strokeWidth={line.linewidth}
                                            fill="none"
                                        />
                                    ))}
                                </svg>
                            </div>
                            <div className="web_sidebar">
                                <div className="w_sidebar1" title="Save" onClick={saveImages}>
                                    <SaveIcon className="w_side_icon" />
                                </div>
                                <div className="w_sidebar1" title="Retake" onClick={retakeImage}>
                                    <PhotoCameraIcon className="w_side_icon" />
                                </div>
                                <div className="w_sidebar1" title="Clear" onClick={clearlines}>
                                    <BsEraserFill className="w_side_icon" />
                                </div>
                                <div className="w_sidebar1 width_side width_side_incr" title="Color" onClick={handleWebSidebar}>
                                    <ColorLensRoundedIcon className="w_side_icon" />
                                    {clropen && (
                                        <div className="color_options">
                                            {colorOptions.map((color) => (
                                                <div className='clr_opt' title={color}>
                                                    <DrawIcon
                                                        key={color}
                                                        className="color_option"
                                                        style={{ color }}
                                                        onClick={() => handleColor(color)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="w_sidebar1 width_side" title="Line Width" onClick={handleWebSidebar1}>
                                    <LineWeightRoundedIcon className="w_side_icon" />
                                    {thickopen && (
                                        <div className="thick_options">
                                            <div className='clr_opt' title='small'>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    className='font_size_1'
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth='1px' // Set the desired line thickness here
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    onClick={() => handleWidth(3)}
                                                >
                                                    <line x1="3" y1="12" x2="21" y2="12" />
                                                </svg>
                                            </div>
                                            <div className='clr_opt' title='medium'>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    className='font_size_1'
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth='2.5px' // Set the desired line thickness here
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    onClick={() => handleWidth(3)}
                                                >
                                                    <line x1="3" y1="12" x2="21" y2="12" />
                                                </svg>
                                            </div>
                                            <div className='clr_opt' title='large'>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    className='font_size_1'
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth='5px' // Set the desired line thickness here
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    onClick={() => handleWidth(3)}
                                                >
                                                    <line x1="3" y1="12" x2="21" y2="12" />
                                                </svg>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                    </div>
                ) : (
                    <div className="camera-container">
                       {/* ---------------------- */}
                   <div className='RotateButton_canva'>
                   <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        mirrored={facingMode === 'user'} 
                        className="web_cam"
                        videoConstraints={videoConstraints}
                    />
                    {devices.includes(IsmobileorNot) &&
                    <div className='web_cam__1'>
                        <button onClick={switchCamera}>
                           <CameraswitchIcon/>
                    </button>
                    </div>}
                   </div>
                   {/* ----------- */}
                        <div className="web_btn">
                            <button onClick={captureImage} className="btncon_add">
                                Capture
                            </button>
                            
                            <button onClick={handlehidecamera} className="btncon_add">
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Canva;
