import React, { useState, useEffect } from "react";
import "./PasswordChange.css";
import Axios from "axios";
import { useSelector } from "react-redux";

function PasswordChange( ) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newImageProfile, setNewImageProfile] = useState(null);
  const [newImageCover, setNewImageCover] = useState(null);
  
  const userRecord = useSelector(state=>state.userRecord?.UserData);

  
  useEffect(() => {
    Axios.get("https://clinic.vesoftometic.co.in/profile/getuserprofile", {
      params: {
        user_id: userRecord?.user_id,
      },
    })
      .then((response) => {
        if (response.data) {
          const userData = response.data;
          // console.log(userRecord?.user_id);
          // console.log(userData);
          
  
          // Convert base64 strings to Blobs
          const profileBlob = dataURItoBlob(
            `data:image/*;base64,${userData.Profile_image}`
          );
          const coverBlob = dataURItoBlob(
            `data:image/*;base64,${userData.Cover_image}`
          );
  
          setNewImageProfile(profileBlob);
          setNewImageCover(coverBlob);
        } else {
          console.error("User data not found or empty");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [userRecord?.user_id]);
  
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // Function to handle password change
  const handlePasswordChange = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    console.log("kkkkkkkk",newPassword)
    // Check if newPassword and confirmPassword match
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      setNewPassword('');
      setConfirmPassword('');
    } else {
      // Make an Axios API call to change the password in the database
      Axios.post(`https://clinic.vesoftometic.co.in/profile/updatepassword/${userRecord?.user_id}`, {
        newPassword: newPassword,
      })
        .then((response) => {
          // Handle success
          console.log(response);
          alert("Password updated successfully.");
        })
        .catch((error) => {
          // Handle error
          console.error("Error changing password:", error);
        });
    }
  };

  return (
    <form>
      <div className="container_pass">
        <div className="head_img_head1 ">
        <div className="head_img1 ">
  {newImageCover && <img src={URL.createObjectURL(newImageCover)} alt="Cover" />}
</div>
          <div className="prof_round_head">
          <div className="profile_round">
  {newImageProfile && <img src={URL.createObjectURL(newImageProfile)} alt="Profile" />}
</div>
            <div className="user_name1">
              <div className="name1">
                <div className="rolenam1">
                  <h4>{userRecord?.username.toUpperCase()}</h4></div>
                <br></br>
                <div className="rolenam1" ><h6>{userRecord?.role_name.toLowerCase()}</h6>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="user_items1">

          <div className="forms1 pack1">
            <div className="pack2 ">
              <label className="first2">New Password:</label>
              <input
                type="password"
                className="inp1"
                placeholder="Enter your Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="new-password"
              />
            </div>

          </div>

          <div className="forms1 pack1">
            <div className="pack2 ">
              <label className="first2">Confirm Password:</label>
              <input
                type="password"
                className="inp1"
                placeholder="Confirm your Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="new-password"
              />
            </div>
          </div>
          <button className="savebtn123" onClick={handlePasswordChange} >
          Confirm
        </button>
        </div>
    

      </div>
    </form>
  );
};

export default PasswordChange;