import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import "./LabMaster.css";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const LabMaster = () => {
  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const [defaultDate, setDefaultDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [lab_patient_name, setlab_patient_name] = useState(" ");

  const lab_Patient_ID = formData?.PatientID;

  const Lab_Doctor_name = formData?.DoctorName;

  const visitNo = formData?.visitNo;

  console.log(lab_Patient_ID);

  const [Labdetailesarr, setLabdetailesarr] = useState([]);

  const [OneLabname, setOneLabname] = useState("");

  const [Temp_testnamelist, setTemp_testnamelist] = useState([]);
  console.log("Temp_testnamelist :", Temp_testnamelist);

  useEffect(() => {
    axios
      .get("https://clinic.vesoftometic.co.in/labtest/get_Text_master_information")
      .then((response) => setLabdetailesarr(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://clinic.vesoftometic.co.in/labtest/get_patient_name?patientid=${lab_Patient_ID}`
      )
      .then((response) => {
        console.log(response.data);
        const fullName = response.data.full_name;

        // Assuming you have a state setter function like setlab_patient_name
        setlab_patient_name(fullName);
      })
      .catch((err) => console.log(err));
  }, [lab_Patient_ID]); // Add lab_Patient_ID as a dependency to rerun the effect when it changes

  const handleCheckboxChange = (testName) => {
    // Check if the testName is already in the list
    if (Temp_testnamelist.includes(testName)) {
      // If it is, remove it
      setTemp_testnamelist((prevList) =>
        prevList.filter((name) => name !== testName)
      );
    } else {
      // If it's not, add it
      setTemp_testnamelist((prevList) => [...prevList, testName]);
    }
  };

  const DeleteTestname = (Name) => {
    let Balancearr = Temp_testnamelist.filter((ele) => {
      return ele.Test_Name !== Name;
    });
    setTemp_testnamelist(Balancearr);
    setOneLabname("");
  };

  const handleShowPreview = () => {
    localStorage.setItem("formData", JSON.stringify(formData));
    window.open(`/Preview, "_blank"`);
  };

  const test_name_submit = () => {
    const url = "https://clinic.vesoftometic.co.in/labtest/Post_Passiont_lab_detailes";
    const formData = new FormData();
    formData.append("lab_Patient_ID", lab_Patient_ID);
    formData.append("lab_patient_name", lab_patient_name);
    formData.append("Date", defaultDate);
    formData.append("DoctorName", Lab_Doctor_name);
    formData.append("visitNo", visitNo);
    formData.append("location", userRecord?.location);
    // formData.append('Test_namelist',Temp_testnamelist)

    // Convert Temp_testnamelist to JSON and append it as a FormData field
    // const testNames = Temp_testnamelist.map((item) => item.Test_Name);
    formData.append("Test_namelist", JSON.stringify(Temp_testnamelist));

    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data.message);
        successMsg(response.data.message);

        setTemp_testnamelist([]);
      })
      .catch((error) => {
        console.error(error);
        errmsg("Error Occured");
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <div className="form-container22">
        <div className="h_head">
          <h4>Lab Service</h4>
        </div>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="lastName">
              Patient ID <span>:</span>
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              required
              value={lab_Patient_ID}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="firstName">
              Patient Name <span>:</span>
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={lab_patient_name}
              onChange={(e) => {
                setlab_patient_name(e.target.value);
              }}
              required
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="Doctor_name">
              Doctor Name <span>:</span>
            </label>
            <input
              type="text"
              id="Doctor_name"
              name="Doctor_name"
              value={Lab_Doctor_name}
              required
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="lastName">
              Date <span>:</span>
            </label>
            <input
              type="Date"
              id="lastName"
              name="lastName"
              value={defaultDate}
              onChange={(e) => setDefaultDate(e.target.value)}
              required
            />
          </div>
        </div>

        <br />

        <div className="Add_items_Purchase_Master">
          <span>Add Test Name</span>
        </div>
        <div className="Check_box_testname">
          <div className="Check_box_testname_sub">
            <div className="Check_box_testname_sub343">
              <h4>PRIMARY INVESTIGATION</h4>
              <h5>(Thyroid Function Test)</h5>
            </div>
            <div className="dsjed83w">
              <div>
                <div className="Check_box_testname_sub_con">
                  <label>
                    T3<span>:</span>
                  </label>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange("T3")}
                  />
                </div>
                <div className="Check_box_testname_sub_con">
                  <label>
                    T4 <span>:</span>
                  </label>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange("T4")}
                  />
                </div>
                <div className="Check_box_testname_sub_con">
                  <label>
                    TSH <span>:</span>
                  </label>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange("TSH")}
                  />
                </div>
                <div className="Check_box_testname_sub_con">
                  <label>
                    HB <span>:</span>
                  </label>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange("HB")}
                  />
                </div>
              </div>
              <div>
                <div className="Check_box_testname_sub_con">
                  <label>
                    Vitamin D <span>:</span>
                  </label>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange("Vitamin D")}
                  />
                </div>
                <div className="Check_box_testname_sub_con">
                  <label>
                    Vitamin B12 <span>:</span>
                  </label>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange("Vitamin B12")}
                  />
                </div>
                <div className="Check_box_testname_sub_con">
                  <label>
                    Ferritin <span>:</span>
                  </label>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange("Ferritin")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="Check_box_testname_sub">
            <h4>HEAMATOLOGY</h4>
            <div className="Check_box_testname_sub_con">
              <label>
                Complete Blood Count <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("Complete Blood Count")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                Bleeding Time <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("Bleeding Time")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                Clotting Time <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("Clotting Time")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                Prothrombin Time <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("Prothrombin Time")}
              />
            </div>
          </div>
          <div className="Check_box_testname_sub">
            <h4>SEROLOGY</h4>
            <div className="Check_box_testname_sub_con">
              <label>
                HBSAg<span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("HBSAg")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                HIV <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("HIV")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                HCV <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("HCV")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                HbA1c <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("HbA1c")}
              />
            </div>
          </div>
          <div className="Check_box_testname_sub">
          <div className="Check_box_testname_sub343">
            <h4>BIO CHEMISTRY</h4>
            <h5>(Blood Glucose)</h5>
         
            
             
              </div>
              <div className="dsjed83w">
              <div>
            <div className="Check_box_testname_sub_con">
              <label>
                FBS<span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("FBS")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                PPBS <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("PPBS")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                RBS <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("RBS")}
              />
            </div>
         </div>
         </div>
        </div>

          <div className="Check_box_testname_sub">
            <h4>OTHERS</h4>
            <div className="Check_box_testname_sub_con">
              <label>
                ECG<span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("ECG")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                BP<span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("BP")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                USG of Abdomen & Pelvis <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange("USG of Abdomen & Pelvis")}
              />
            </div>
            <div className="Check_box_testname_sub_con">
              <label>
                Prostate Specific Antigen Test <span>:</span>
              </label>
              <input
                type="checkbox"
                onChange={() =>
                  handleCheckboxChange("Prostate Specific Antigen Test")
                }
              />
            </div>
          </div>
        </div>

        {Temp_testnamelist.length === 0 ? (
          ""
        ) : (
          <>
            <div className="Selected-table-container">
              <table className="selected-medicine-table2">
                <thead>
                  <tr>
                    <th id="vital_Twidth">S No</th>
                    <th id="vital_Twidth">Name</th>
                  </tr>
                </thead>
                <tbody>
                  {Temp_testnamelist &&
                    Temp_testnamelist.map((ele, ind) => (
                      <tr key={"mytbl" + ind}>
                        <td>{ind + 1}</td>
                        <td>{ele}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="Savebtn_class">
              <button
                className="btncon_add prs-ad-sub-btn"
                onClick={test_name_submit}
              >
                Save
              </button>
              <button
                className="btncon_add prs-ad-sub-btn"
                onClick={handleShowPreview}
              >
                Preview
              </button>
            </div>
          </>
        )}
        <ToastContainer />
      </div>
    </>
  );
};
export default LabMaster;
