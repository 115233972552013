import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./Navigation.css";
import { useNavigate } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import Preview from "./Preview";
import { useSelector } from "react-redux";

function Procedure() {
  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const navigate = useNavigate();
  console.log(formData);
  // console.log(userRecord)
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const yourStyles = {
    position: "absolute",
    inset: "100px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(97 90 90 / 75%)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "0px",
  };

  const [Ratecard, setRatecard] = useState({
    RatecardType: "",
    InsuranceName: "",
    ClientName: "",
  });

  const [ProcedureName, setProcedureName] = useState([]);
  const [ProcedureData, setProcedureData] = useState([]);
  const [Isedit, setIsedit] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [formValues, setFormValues] = useState({
    DoctorName: "",
    ProcedureName: "",
    Sessions: "",
    GraftCount: "",
    Complementry: "",
    nextAppointment: "",
    Amount: "",
    Gstcharge: "",
    TotalAmount: "",
    Discount: 0,
    appointmentDate: "",
  });

  console.log(formValues);
  useEffect(() => {
    axios
      .get(
        `https://clinic.vesoftometic.co.in/doctorsworkbench/get_procedurename_fromratecard?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        setProcedureName(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let ratecardType;
    if (formValues.ProcedureName !== "") {
      if (Ratecard.RatecardType === "Insurance") {
        ratecardType = Ratecard.InsuranceName;
        axios
          .get(
            `https://clinic.vesoftometic.co.in/usercontrol/get_RateCard_Insurance_Charge?servicetype=Procedure&servicename=${formValues.ProcedureName}&ratecardtype=${ratecardType}&location=${userRecord?.location}`
          )
          .then((response) => {
            const data = response.data.data[0];
            if (data.Charge) {
              setFormValues((prev) => ({
                ...prev,
                Amount: +data.Charge,
                Gstcharge: data.GstCharge,
              }));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (Ratecard.RatecardType === "Client") {
        ratecardType = Ratecard.ClientName;
        axios
          .get(
            `https://clinic.vesoftometic.co.in/usercontrol/get_RateCard_client_Charge?servicetype=Procedure&servicename=${formValues.ProcedureName}&ratecardtype=${ratecardType}&location=${userRecord?.location}`
          )
          .then((response) => {
            const data = response.data.data[0];
            if (data.Charge) {
              setFormValues((prev) => ({
                ...prev,
                Amount: +data.Charge,
                Gstcharge: data.GstCharge,
              }));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (Ratecard.RatecardType === "General") {
        ratecardType = Ratecard.RatecardType;
        axios
          .get(
            `https://clinic.vesoftometic.co.in/usercontrol/get_RateCard_Service_Charge?servicetype=Procedure&servicename=${
              formValues.ProcedureName
            }&ratecardtype=${ratecardType}&location=${"ALL"}}`
          )
          .then((response) => {
            const data = response.data.data[0];
            console.log(data);
            if (data.Charge) {
              setFormValues((prev) => ({
                ...prev,
                Amount: +data.Charge,
                Gstcharge: data.GstCharge,
              }));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [
    formValues.ProcedureName,
    Ratecard.ClientName,
    Ratecard.InsuranceName,
    Ratecard.RatecardType,
    userRecord?.location,
  ]);

  useEffect(() => {
    if (formData.PatientID) {
      axios
        .get(
          `https://clinic.vesoftometic.co.in/patientmanagement/get_communication_address_billing/${formData.PatientID}/${userRecord?.location}`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data[0];
          setRatecard({
            RatecardType: data.RatecardType,
            InsuranceName: data.InsuranceName,
            ClientName: data.ClientName,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [formData.PatientID]);

  useEffect(() => {
    console.log(
      formValues.Sessions,
      formValues.GraftCount,
      formValues.ProcedureName,
      formValues.Amount
    );

    if (formValues.ProcedureName.split("-")[0].includes("HT")) {
      const total = formValues.Amount * +formValues.GraftCount;
      setFormValues((prev) => ({
        ...prev,
        TotalAmount: total,
      }));
    } else {
      const total = formValues.Amount * formValues.Sessions;
      setFormValues((prev) => ({
        ...prev,
        TotalAmount: total,
      }));
    }
  }, [
    formValues.Sessions,
    formValues.GraftCount,
    formValues.ProcedureName,
    formValues.Amount,
  ]);

  const handleonchange = (e) => {
    const { name, value } = e.target;
    if (name === "ProcedureName") {
      if (value.split("-")[0].includes("HT")) {
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
          Sessions: 1,
        }));
      } else {
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
          Sessions: 0,
        }));
      }
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAdd = () => {
    const requiredFields = [];
    const existingItem = requiredFields.filter((field) => !formValues[field]);
    if (existingItem.length === 0) {
      const form = {
        PatientID: formData.PatientID,
        PatientName: formData.firstName + " " + formData.lastName,
        VisitID: formData.visitNo,
        ServiceType: "Procedure",
        DoctorName: formData.DoctorName,
        ProcedureName: formValues.ProcedureName,
        Sessions: +formValues.Sessions,
        GraftCount: +formValues.GraftCount,
        Complementry: +formValues.Complementry,
        PaidSessions: 0,
        theraphist_session_completed: 0,
        RemainingSessions: +formValues.Sessions,
        Amount: formValues.Amount,
        Gstcharge: formValues.Gstcharge,
        TotalAmount: formValues.TotalAmount,
        Discount: 0,
        nextAppointment: formValues.nextAppointment,
        Status: "Pending",
        appointmentDate: formData.AppointmentDate,
        location: userRecord?.location,
      };
      console.log(form);
      handleclear();
      const alreadyexist = ProcedureData.find(
        (p) => p.ProcedureName === form.ProcedureName
      );
      if (!alreadyexist) {
        setProcedureData((prev) => [...prev, form]);
      } else {
        alert("Procedure Already Exist");
      }
    }
  };
  const handleclear = () => {
    setFormValues({
      DoctorName: "",
      ProcedureName: "",
      Sessions: "",
      GraftCount: "",
      Complementry: "",
      nextAppointment: "",
      Amount: "",
      Gstcharge: "",
      TotalAmount: "",
      Discount: "",
      appointmentDate: "",
    });
  };
  const handleEdit = (client, index) => {
    setIsedit(index);
    setFormValues({ ...client });
  };
  const handleUpdate = () => {
    const requiredFields = [];
    const existingItem = requiredFields.filter((field) => !formValues[field]);
    if (existingItem.length === 0) {
      handleclear();
      setIsedit(null);
      const form = {
        PatientID: formData.PatientID,
        PatientName: formData.firstName + " " + formData.lastName,
        VisitID: formData.visitNo,
        ServiceType: "Procedure",
        DoctorName: formData.DoctorName,
        ProcedureName: formValues.ProcedureName,
        Sessions: +formValues.Sessions,
        GraftCount: +formValues.GraftCount,
        Complementry: +formValues.Complementry,
        PaidSessions: 0,
        theraphist_session_completed: 0,
        RemainingSessions: +formValues.Sessions,
        Amount: formValues.Amount,
        Gstcharge: formValues.Gstcharge,
        TotalAmount: formValues.TotalAmount,
        Discount: 0,
        nextAppointment: formValues.nextAppointment,
        Status: "Pending",
        appointmentDate: formData.AppointmentDate,
        location: userRecord?.location,
      };
      const updateddate = [...ProcedureData];
      updateddate[Isedit] = form;
    }
  };

  const handleSave = () => {
    const procedure = ProcedureData.flatMap((p) => {
      const additionalRows = [];

      if (+p.Complementry > 0 && p.ProcedureName.split("-")[0].includes("HT")) {
        // Create a new row for complementary procedure
        alert("hi");
        additionalRows.push({
          ProcedureName: "PRP",
          Therapist_Name: p.DoctorName,
          Sessions: p.Complementry,
          appointmentDate: formData.AppointmentDate,
          PatientID: formData.PatientID,
          createdBy: userRecord?.username,
          DoctorName: formData.DoctorName,
          Status: formData.Status,
          VisitID: formData.visitNo,
          nextAppointment: p.nextAppointment,
          branchlocation: userRecord?.location,
        });
      }

      // Original row for the current procedure
      return [
        {
          ProcedureName: p.ProcedureName,
          Therapist_Name: p.DoctorName,
          Sessions: p.Sessions,
          appointmentDate: formData.AppointmentDate,
          PatientID: formData.PatientID,
          createdBy: userRecord?.username,
          DoctorName: formData.DoctorName,
          Status: formData.Status,
          VisitID: formData.visitNo,
          nextAppointment: p.nextAppointment,
          branchlocation: userRecord?.location,
        },
        ...additionalRows,
      ];
    });

    axios
      .post(
        "https://clinic.vesoftometic.co.in/doctorsworkbench/insert_procedure",
        procedure
      )
      .then((response) => {
        console.log(response);

        successMsg(response.data.message);
        axios
          .post(
            "https://clinic.vesoftometic.co.in/GeneralBilling/insertGeneral_Billing_Data",
            ProcedureData
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error("Error insert Billing:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

    console.log(procedure);
    console.log(ProcedureData);
    // console.log(+ConsultancyDiscount);
    // console.log(formData);
    // setTimeout(() => {
    //   navigate("/Treament-QueueList2");
    // }, 1000)
  };

  // const handleShowPreview=()=>{

  // }

  return (
    <>
      <Helmet>
        <title>Procedure</title>
      </Helmet>
      <div className="new-patient-registration-form">
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="ProcedureName" >Treatment Procedure<span>:</span></label>
            <select
              id="ProcedureName"
              name="ProcedureName"
              value={formValues.ProcedureName}
              onChange={handleonchange}
            >
              <option value="">Select </option>
              {Array.isArray(ProcedureName) ? (
                ProcedureName.map((procedure, index) => (
                  <option key={index} value={procedure}>
                    {procedure}
                  </option>
                ))
              ) : (
                <option disabled>No procedure available</option>
              )}
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="Sessions" >Numberof Sessions<span>:</span></label>
            <input
              type="number"
              id="Sessions"
              name="Sessions"
              readOnly={formValues.ProcedureName.split("-")[0].includes("HT")}
              value={formValues.Sessions}
              onChange={handleonchange}
            />
          </div>

          {formValues.ProcedureName.split("-")[0].includes("HT") && (
            <>
              <div className="RegisForm_1">
                <label htmlFor="Discount">No of Grafts<span>:</span></label>
                <input
                  type="number"
                  id="GraftCount"
                  name="GraftCount"
                  value={formValues.GraftCount}
                  onChange={handleonchange}
                  required
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="ConsultancyDiscount">Complementary PRP<span>:</span></label>
                <input
                  type="number"
                  id="ConsultancyDiscount"
                  name="Complementry"
                  value={formValues.Complementry}
                  onChange={handleonchange}
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="nextAppointment">Next Appointment<span>:</span></label>
                <input
                  type="date"
                  id="nextAppointment"
                  name="nextAppointment"
                  value={formValues.nextAppointment}
                  required
                  onChange={handleonchange}
                />
              </div>
            </>
          )}
          <div className="RegisForm_1">
            <label htmlFor="nextAppointment">Next Appointment<span>:</span></label>
            <input
              type="date"
              id="nextAppointment"
              name="nextAppointment"
              value={formValues.nextAppointment}
              required
              onChange={handleonchange}
            />
          </div>
        </div>

        <div className="new-button-container">
          <button
            classNa
            className="btncon_add prs-ad-sub-btn"
            onClick={Isedit !== null ? handleUpdate : handleAdd}
          >
            {Isedit !== null ? "Update" : "Add"}
          </button>
        </div>


        <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
                <th>Service Type</th>
                <th>Therapist Name</th>
                <th>Procedure Name</th>
                <th>Sessions</th>
                <th>GraftCount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {ProcedureData.map((client, index) => (
                <tr key={index}>
                  <td>{client.ServiceType}</td>
                  <td>{client.DoctorName}</td>
                  <td>{client.ProcedureName}</td>
                  <td>{client.Sessions}</td>
                  <td>{client.GraftCount}</td>
                  <td>
                    <button className="delnamebtn" onClick={() => handleEdit(client, index)}>
                      <EditNoteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleSave}>
            Save
          </button>
          <button className="RegisterForm_1_btns" onClick={openModal}>
            Preview
          </button>
        </div>
        <ToastContainer />

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{ content: { ...yourStyles } }}
        >
          <Preview
            userRecord={userRecord}
            modalContent={modalContent}
            formData={formData}
          />
        </Modal>
      </div>
    </>
  );
}

export default Procedure;
