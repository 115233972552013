import React, { useEffect, useState } from "react";
// import './PatientList.css'
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});
const EmployeeQueList = ( ) => {

  const dispatchvalue =useDispatch();


  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [userRecord, setUserRecord] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");

  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  console.log(userRecord);

  useEffect(() => {
    console.log(searchQuery1);
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.employeename.toLowerCase();
      
      // Check if row.phone exists and is a string before calling toLowerCase()
      const lowerCasephoneno = typeof row.phone === 'string' ? row.phone.toLowerCase() : '';

      const lowerCaseQuery = searchQuery.toLowerCase();
      const lowerCaseQuery1 = searchQuery1.toLowerCase();

      // Check if searchQuery is for FirstName and if it matches
      const matchesFirstName = lowerCaseSupplierName.includes(lowerCaseQuery);
      
      // Check if lowerCasephoneno is a string before calling includes()
      const matchesphoneno = typeof lowerCasephoneno === 'string' ? lowerCasephoneno.includes(lowerCaseQuery1) : false;

      // Combine the conditions based on search type
      return (
        (matchesFirstName || !searchQuery ) && (matchesphoneno || !searchQuery1)
      );
    });

    setFilteredRows(filteredData);
    setPage(0); // Reset page to 0 when the search query changes
  }, [searchQuery, searchQuery1, rows]);


  useEffect(() => {
    const userRecord = localStorage.getItem("token");

    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split(".")[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      console.log("decodedTokenData", decodedTokenData);
      setUserRecord(decodedTokenData);
    }
  }, []);

  const [columns] = React.useState([
    { field: "id", headerName: "Serial No", width: 85 },
    { field: "employeeid", headerName: "Employee ID", width: 130 },
    { field: "employeename", headerName: "Employee Name", width: 150 },
    { field: "phone", headerName: "Phone No", width: 150 },
    { field: "Designation", headerName: "Designation", width: 180 },
    { field: "DateofJoining", headerName: "Date of Joining", width: 150 },
    { field: "location", headerName: "Branch Location", width: 150 },

    {
      field: "action ",
      headerName: "View",
      width: 100,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
    {
      field: "views ",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList1(params)}>
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
    {
      field: "Edit ",
      headerName: "Edit",
      width: 80,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleEditList(params)}>
            <EditIcon />
          </Button>
        </>
      ),
    },
  ]);

  const handleList = (params) => {
    console.log(params);
    const employeeid = params.row.employeeid;
    const location = params.row.location;

    axios
      .get(
        `https://clinic.vesoftometic.co.in/HRmanagement/get_for_employeeprofile?employeeid=${employeeid}&location=${location}`
      )
      .then((response) => {
        console.log(response.data);
        dispatchvalue({type : 'foremployeedata' , value : (response.data)});
      })
      .catch((error) => {
        console.log(error);
      });

    navigate("/Home/Employee-Profile");
  };

  const handleList1 = (params) => {
    console.log(params);
    const employeeid = params.row.employeeid;
    const location = params.row.location;

    axios
      .get(
        `https://clinic.vesoftometic.co.in/HRmanagement/get_for_employeeprofile?employeeid=${employeeid}&location=${location}`
      )

      .then((response) => {
        console.log(response.data);
        dispatchvalue({type :'foruserregisteremployeedata' , value : (response?.data || [])});
        navigate("/Home/Register-User"); // Navigate to userregister page
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditList = (params) => {
    console.log(params);
    const employeeid = params.row.employeeid;
    const location = params.row.location;

    axios
      .get(
        `https://clinic.vesoftometic.co.in/HRmanagement/get_for_employeeprofile?employeeid=${employeeid}&location=${location}`
      )

      .then((response) => {
        console.log(response.data);
        dispatchvalue({type : 'foredituserregisteremployeedata' , value :(response?.data)});
        navigate("/Home/Register-User"); // Navigate to userregister page
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(
        `https://clinic.vesoftometic.co.in/HRmanagement/get_employee_personaldetails?location=${userRecord.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        const Records = data.map((userdata, index) => ({
          id: index + 1,
          employeeid: userdata.EmployeeID,
          employeename: userdata.EmployeeName,
          phone: userdata.PhoneNumber,
          Designation: userdata.Designation,
          location: userdata.Locations,
          DateofJoining: userdata.DateofJoining,
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userRecord]);
  const [page, setPage] = React.useState(0);

  const pageSize = 10;
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  //   const totalPages = Math.ceil(PatientData.length / 10);

  const navigate = useNavigate();

  const [EmployeeName, setEmployeeName] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    axios
      .get("https://clinic.vesoftometic.co.in/HRmanagement/get_employee_personaldetails")
      .then((response) => {
        console.log(response.data);
        const data = response.data;

        const filteredData = data.filter((user) => {
          const lowerCaseFirstName = user.EmployeeName.toLowerCase();
          const lowerCasePhoneNo = user.PhoneNumber.toString();

          const lowerCaseSearch = EmployeeName.toLowerCase();
          const lowerCasePhoneSearch = PhoneNumber.toLowerCase();

          return (
            (EmployeeName === "" ||
              lowerCaseFirstName.startsWith(lowerCaseSearch)) &&
            (PhoneNumber === "" ||
              lowerCasePhoneNo.startsWith(lowerCasePhoneSearch))
          );
        });

        if (filteredData.length === 0) {
          alert("No matching employees found.");
          setRows([]);
        } else {
          const rows = filteredData.map((userdata, index) => ({
            id: index + 1,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            phone: userdata.PhoneNumber,
            department: userdata.Department,
          }));
          setRows(rows);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="appointment">
        <div className="h_head">
          <h3>Employee List</h3>
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Employee Name :</label>
            <input
              type="text"
              id="FirstName"
              onChange={(e) => setEmployeeName(e.target.value)}
              value={EmployeeName}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Phone No :</label>
            <input
              type="number"
              id="PhoneNo"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={PhoneNumber}
              placeholder="Enter the Phone No"
            />
          </div>
          <button className="btn_1" onClick={handleSearch}>
            <SearchIcon />
          </button>
        </div>
       
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {filteredRows.length === 0 && (
          <div className="norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeQueList;
