import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import axios from 'axios';
import SlotManagementPro from './SlotManagementPro';

const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeader: {
                    backgroundColor: 'hsl(33, 100%, 50%)',
                },
                root: {
                    '& .MuiDataGrid-window': {
                        overflow: 'hidden !important',
                    },
                },
                cell: {
                    borderTop: '0px !important',
                    borderBottom: '1px solid  hsl(33, 100%, 50%) !important',
                },
            },
        },
    },
});

function SlotManagement({ userRecord, isSidebarOpen }) {
    const [totalSlots, setTotalSlots] = useState('');
    const [slot, setSlot] = useState('');
    const [roleData, setRoleData] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [doctorOptions, setDoctorOptions] = useState([]);
    const [page, setPage] = useState(0);
    const pageSize = 10;
    const [isModalVisible, setModalVisible] = useState(false);
    const [newSlotdata, setNewSlotdata] = useState({});

    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const totalPages = Math.ceil(roleData.length / 10);

    const fetchDoctorSlotManagementData = () => {
        axios.get('https://clinic.vesoftometic.co.in/usercontrol/get_doctor_slot_management')
          .then((response) => {
            const fetchedData = response.data.map((row, index) => ({ ...row, id: index + 1 }));
            setRoleData(fetchedData);
          })
          .catch((error) => {
            console.error('Error fetching doctor slot management data:', error);
          });
      };

    useEffect(() => {
     
        axios.get('https://clinic.vesoftometic.co.in/usercontrol/get_slot_data')
            .then((response) => {
                const { totalSlots } = response.data;
                // Update the state with the retrieved data
                setTotalSlots(totalSlots);
            })
            .catch((error) => {
                console.error('Error fetching slots:', error);
            });

            axios
            .get('https://clinic.vesoftometic.co.in/usercontrol/get_doctor_info')
            .then((response) => {
              const fetchedDoctors = response.data.map((doctor, index) => ({
                id: index,
                name: doctor,
              }));
              setDoctorOptions(fetchedDoctors);
            })
            .catch((error) => {
              console.error('Error fetching doctor information:', error);
            });


        // Fetch doctor slot management data
        fetchDoctorSlotManagementData();


        // axios.get('http://your-api-endpoint')
        //   .then((response) => {

        //     setRoleData(response.data);
        //   })
        //   .catch((error) => {
        //     console.error('Error fetching role data:', error);
        //   });
    }, []);

    const handleTotalSlotsSave = () => {
        if (!totalSlots || isNaN(totalSlots) || totalSlots <= 0) {
            console.error('Invalid Total No Slot value');
            return;
        }

        axios.post('https://clinic.vesoftometic.co.in/usercontrol/Slot_Insert', {
            totalSlots: totalSlots,
        })
            .then((response) => {
                console.log('Total No Slot saved successfully:', response.data);
            })
            .catch((error) => {
                console.error('Error saving Total No Slot:', error);
            });
    };

    const handleDoctorSlotSave = () => {

        console.log('Selected Doctor:', selectedDoctor);
        console.log('Slot:', slot);

        if (!selectedDoctor || !selectedDoctor.name || !slot || isNaN(slot) || slot <= 0) {
            console.error('Invalid Doctor Name or Slot value');
            console.error('Invalid Doctor Name or Slot value. Selected Doctor:', selectedDoctor, 'Slot:', slot);
            return;
        }

        axios.post('https://clinic.vesoftometic.co.in/usercontrol/doctor_slot_management', {

            slot: slot,
            doctorName: selectedDoctor.name,

        })
            .then((response) => {
                console.log(response.data);
                // After successful save, fetch updated data
                fetchDoctorSlotManagementData();
            })
            .catch((error) => {
                console.error('Error saving slots:', error);
            });

        setSlot('');
        setSelectedDoctor(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!selectedDoctor || !slot || !totalSlots) {
            return;
        }

        setTotalSlots('');
        setSlot('');
        setSelectedDoctor(null);
    };

    const handleOpenModal = (params) => {
        setNewSlotdata(params);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const columns = [
        { field: 'doctor_name', headerName: 'Doctor Name', width: 450 },
        {
          field: 'no_of_slot',
          headerName: 'No of Slot',
          width: 400,
          renderCell: (params) => (
            <div>
              {params.value}
              <IconButton className='tempt_edit' onClick={() => handleOpenModal(params.row)}>
                <EditNoteIcon />
              </IconButton>
            </div>
          ),
        },
      ];

    return (
        <>
            <Helmet>
                <title>Slot Management</title>
            </Helmet>
            <div className="appointment">
                <div className="h_head">
                    <h3>Slot Management</h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="con_1 ">
                        <div className="inp_1 totalslot_width">
                            <label htmlFor="totalSlots">Total No Slot (Day):</label>
                            <input
                                type="text"
                                placeholder="Enter Total No of Slot"
                                id="totalSlots"
                                name="totalSlots"
                                value={totalSlots}
                                onChange={(e) => setTotalSlots(e.target.value)}
                            />
                        </div>
                        <button className="btn_1" type="submit" onClick={handleTotalSlotsSave}>
                            Save
                        </button>
                    </div>
                    <div className="con_1 ">
                        <div className="inp_1">
                            <label htmlFor="doctorName">Doctor Name:</label>
                            <select
    id="doctorName"
    name="doctorName"
    className='doctr_select2'
    value={selectedDoctor ? selectedDoctor.id : ''}
    onChange={(e) => {
        const selectedId = e.target.value;
        const doctor = doctorOptions.find((d) => d.id === parseInt(selectedId));
        setSelectedDoctor(doctor);
    }}
>
    <option value="">Select Doctor</option>
    {doctorOptions.map((doctor) => (
        <option key={doctor.id} value={doctor.id}>
            {doctor.name}
        </option>
    ))}
</select>

                        </div>
                        <div className="inp_1">
                            <label htmlFor="slot">Slot:</label>
                            <input
                                type="text"
                                placeholder="Enter Slot"
                                id="slot"
                                name="slot"
                                value={slot}
                                onChange={(e) => setSlot(e.target.value)}
                            />
                        </div>
                        <button className="btn_1" type="submit" onClick={handleDoctorSlotSave}>
                            Add
                        </button>
                    </div>
                </form>
                <ThemeProvider theme={theme}>
                    <div className=" grid_1">
                        <DataGrid
                            rows={roleData.slice(page * pageSize, (page + 1) * pageSize)}
                            columns={columns}
                            pageSize={100}
                            getRowId={(row) => row.doctor_name}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            onPageChange={handlePageChange}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className="data_grid"
                        />
                        {roleData.length > 10 && (
                            <div className="grid_foot">
                                <button
                                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </div>
                </ThemeProvider>
            </div>
            {isModalVisible && userRecord && (
                 <SlotManagementPro
                 onClose={handleCloseModal}
                 isSidebarOpen={isSidebarOpen}
                 newSlotdata={newSlotdata}
                 updateProcedureData={(updatedData) => {
                   // Update the roleData in the parent component
                   setRoleData(prevData => {
                     const updatedIndex = prevData.findIndex(item => item.id === updatedData.id);
                     if (updatedIndex !== -1) {
                       const newData = [...prevData];
                       newData[updatedIndex] = updatedData;
                       return newData;
                     }
                     return prevData;
                   });
                 }}
               />
            )}
        </>
    );
}

export default SlotManagement;