import axios from 'axios';
import { addDays, format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import Webcam from "react-webcam";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';



const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });
  
const QuickStockMasterCentral = ( ) => {

    const dispatchvalue  = useDispatch();

    const  GRNedit = useSelector(state=>state.userRecord?.GRNedit)

    const userRecord = useSelector(state=>state.userRecord?.UserData)

    const navigate = useNavigate();
    const cu_date = format(new Date(), 'yyyy-MM-dd');
    const [QuickRecieveFormData, setQuickRecieveFormData] = useState({
        ProductCategory: '',
        GRN_Invoice_NO: '',
        GRN_Date: cu_date,
        SupplierCode: '',
        PurchaseRaiseInvoice: 'Null',
        SupplierName: '',
        Supplier_Bill_No: '',
        Supplier_Bill_Date: '',
        Supplier_Net_Amount: '',
        Supplier_Bill_Due_Date: '',
        CreatedBy: '',
        Location: 'CentralStore',
        RecievedBy: '',
        Purchaserise_date: 'Null',
    })
    const [PurchaseRecieveFormData, setPurchaseRecieveFormData] = useState({
        Item_Code: '',
        Item_Name: '',
        Generic_Name: '',
        Strength: '',
        UOM: '',
        Pack_type: '',
        Pack_Quantity: '',
        PurchaseQuantity: '',
        TotalQuantity: '',
        HSNCode: '',
        BatchNo: '',
        ManufactureDate: '',
        ExpiryDate: '',
        MRP: '',
        PurchaseRateForStrip:'',
        PurchaseRateForStripGST:'',
        Purchase_Rate_TotalAmount:'',
        Purchase_Rate_TotalAmount_Dis:'',
        Purchase_Rate:'',
        Purchase_Rate_withtax:'',
        GST_Type: '',
        Tax_Percentage: '',
        DiscountType: '',
        Discount: '',
        Tax_totalAmount: '',
        TotalAmount: '' 
    })
    const [Finel_Amountdata, setFinel_Amountdata] = useState({
        Grand_Total: '',
        Total_Discount_Type: '',
        Total_Discount: '',
        Finel_NetAmount: ''
    })
    const [Item_Codedetailes, setItem_Codedetailes] = useState([])
    const [Supplierdetailes, setSupplierdetailes] = useState([])
    const [showCamera, setShowCamera] = useState(false);
    const [capturedImageSec, setCapturedImageSec] = useState(null);
    const webcamRef = useRef(null);
    const [isImageCaptured, setIsImageCaptured] = useState(false);
    const [datalist1, setdatalist1] = useState([])
    const [page, setPage] = useState(0);
    const pageSize = 10;
    const showdown = datalist1.length;      
    const totalPages = Math.ceil(datalist1.length / 10);
    const [Selected_File, setSelected_File] = useState(null);
    const [facingMode, setFacingMode] = useState('user');
    const devices = ['iPhone', 'iPad', 'Android', 'Mobile', 'Tablet', 'desktop']
    const [IsmobileorNot, setIsmobileorNot] = useState(null);
    const [isedit, setisedit] = useState(null)
    const [supplierduedate, setsupplierduedate] = useState('')
    useEffect(() => {
        // Use an axios request within useEffect to avoid infinite rendering
        axios.get('https://clinic.vesoftometic.co.in/patientmanagement/detect_device')
            .then((response) => {
                setIsmobileorNot(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []); // The empty dependency array ensures that this effect runs only once on mount


    useEffect(() => {

        axios.get('https://clinic.vesoftometic.co.in/quickstockreceive/get_supplier_Master_Name')
            .then((response) => {
                const data = response.data.filter((ele) => {
                    return ele.SupplierType === QuickRecieveFormData.ProductCategory
                        && ele.Status === "Active"
                })
                console.log('mm', data)
                setSupplierdetailes(data)

            })
            .catch((error) => {
                console.error('Error fetching patients data:', error);
            });


        if (QuickRecieveFormData.ProductCategory !== '') {

            if (QuickRecieveFormData.ProductCategory === 'Medical') {
                axios
                    .get('https://clinic.vesoftometic.co.in/quickstockreceive/get_Medical_GRN_InvoiceNo')
                    .then((response) => {
                        console.log('INV:', response.data.nextGRN);
                        setQuickRecieveFormData((prevData) => ({
                            ...prevData,
                            GRN_Invoice_NO: response.data.nextGRN,
                        }));
                    })
                    .catch((error) => {
                        // Handle errors here
                        console.error("Error fetching data:", error);
                    });
                axios
                    .get('https://clinic.vesoftometic.co.in/quickstockreceive/get_Medical_ProductMaster_data')
                    .then((response) => {
                        console.log('ITEM:', response.data);
                        let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                        setItem_Codedetailes(A_Status)
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            }

            else if (QuickRecieveFormData.ProductCategory === 'Non_Medical') {
                axios
                    .get('https://clinic.vesoftometic.co.in/quickstockreceive/get_Non_Medical_GRN_InvoiceNo')
                    .then((response) => {
                        console.log('INV:', response.data.nextGRN);
                        setQuickRecieveFormData((prevData) => ({
                            ...prevData,
                            GRN_Invoice_NO: response.data.nextGRN,
                        }));
                    })
                    .catch((error) => {
                        // Handle errors here
                        console.error("Error fetching data:", error);
                    });
                axios.get('https://clinic.vesoftometic.co.in/quickstockreceive/get_NONMedical_ProductMaster_data')
                    .then((response) => {
                        let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                        setItem_Codedetailes(A_Status)
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            }

            else if (QuickRecieveFormData.ProductCategory === 'Assets') {
                axios
                    .get('https://clinic.vesoftometic.co.in/quickstockreceive/get_Assets_GRN_InvoiceNo')
                    .then((response) => {
                        console.log('INV:', response.data.nextGRN);
                        setQuickRecieveFormData((prevData) => ({
                            ...prevData,
                            GRN_Invoice_NO: response.data.nextGRN,
                        }));
                    })
                    .catch((error) => {
                        // Handle errors here
                        console.error("Error fetching data:", error);
                    });
                axios
                    .get('https://clinic.vesoftometic.co.in/quickstockreceive/get_Assets_ProductMaster_data')
                    .then((response) => {
                        console.log('ITEM:', response.data);
                        let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                        setItem_Codedetailes(A_Status)
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            }

            else if (QuickRecieveFormData.ProductCategory === 'Stationary') {
                axios
                    .get('https://clinic.vesoftometic.co.in/quickstockreceive/get_Stationary_GRN_InvoiceNo')
                    .then((response) => {
                        console.log('INV:', response.data.nextGRN);
                        setQuickRecieveFormData((prevData) => ({
                            ...prevData,
                            GRN_Invoice_NO: response.data.nextGRN,
                        }));
                    })
                    .catch((error) => {
                        // Handle errors here
                        console.error("Error fetching data:", error);
                    });
                axios
                    .get('https://clinic.vesoftometic.co.in/quickstockreceive/get_Stationary_ProductMaster_data')
                    .then((response) => {
                        console.log('ITEM:', response.data);
                        let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                        setItem_Codedetailes(A_Status)
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            }
            else if (QuickRecieveFormData.ProductCategory === 'Non_Stationary') {
                axios
                    .get('https://clinic.vesoftometic.co.in/quickstockreceive/get_Non_Stationary_GRN_InvoiceNo')
                    .then((response) => {
                        console.log('INV:', response.data.nextGRN);
                        setQuickRecieveFormData((prevData) => ({
                            ...prevData,
                            GRN_Invoice_NO: response.data.nextGRN,
                        }));
                    })
                    .catch((error) => {
                        // Handle errors here
                        console.error("Error fetching data:", error);
                    });
                axios
                    .get('https://clinic.vesoftometic.co.in/quickstockreceive/get_NONStationary_ProductMaster_data')
                    .then((response) => {
                        console.log('ITEM:', response.data);
                        let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                        setItem_Codedetailes(A_Status)
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            }
        }
    }, [QuickRecieveFormData.ProductCategory])


    const videoConstraints = {
        facingMode: facingMode,
    };

    const switchCamera = () => {
        setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
    };
    const handleOpenCamera = () => {
        setShowCamera(true);
    };
    const handleHideCamera = () => {
        setShowCamera(false);
    };

    const handleRecaptureImageSec = () => {
        setCapturedImageSec(null);
        setIsImageCaptured(false);
    };

    const capturedImageSecnd = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        const blob = dataURItoBlob(imageSrc);
        setCapturedImageSec(blob);
        setIsImageCaptured(true);
    };

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };
    const handleCloseCamera = () => {
        setShowCamera(false);
    };
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    const handleClear=()=>{
        setPurchaseRecieveFormData({
        Item_Code: '',
        Item_Name: '',
        Generic_Name: '',
        Strength: '',
        UOM: '',
        Pack_type: '',
        Pack_Quantity: '',
        PurchaseQuantity: '',
        TotalQuantity: '',
        HSNCode: '',
        BatchNo: '',
        ManufactureDate: '',
        ExpiryDate: '',
        MRP: '',
        PurchaseRateForStrip:'',
        PurchaseRateForStripGST:'',
        Purchase_Rate_TotalAmount:'',
        Purchase_Rate_TotalAmount_Dis:'',
        Purchase_Rate:'',
        Purchase_Rate_withtax:'',
        GST_Type: '',
        Tax_Percentage: '',
        DiscountType: '',
        Discount: '',
        Tax_totalAmount: '',
        TotalAmount: ''   
        })
    }
    const handleInputFinel_AmountdataChange = (e) => {
        const { name, value } = e.target;

        setFinel_Amountdata((prev) => ({
            ...prev,
            [name]: value,
        }));
    }
    const handleInputChange1 = (event) => {
        const { name, value } = event.target;
        if (name === 'ProductCategory') {
            setQuickRecieveFormData((prevData) => ({
                ...prevData,
                [name]: value,
                GRN_Invoice_NO: '',
                SupplierCode: '',
                SupplierName: '',
                Supplier_Bill_No: '',
                Supplier_Bill_Date: '',
                Supplier_Net_Amount: '',
                Supplier_Bill_Due_Date: '',

            }));
            setdatalist1([])
            handleClear()
            setSelected_File(null)
            setCapturedImageSec(null)

        } else if (name === 'SupplierCode') {
            let sup_n = Supplierdetailes.find((ele) => ele.SupplierCode === value);
            
            if (sup_n) {
                axios.get(`https://clinic.vesoftometic.co.in/quickstockreceive/get_supplier_Master_Data?selectedSupplierIndex=${value}`)
            .then(response => {
                
                console.log('SuplierData:', response.data);
                
                let datecalculatte = response.data[0].Payment_Due_Days
                setsupplierduedate(+datecalculatte)
                

            })
                setQuickRecieveFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                    SupplierName: sup_n.SupplierName,
                }));
            } else{
                setQuickRecieveFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                    SupplierName: '', // Set default value or handle accordingly
                }));
            }
        } else if (name === 'SupplierName') {
            let sup_c = Supplierdetailes.find((ele) => ele.SupplierName === value);
            if (sup_c) {
                axios.get(`https://clinic.vesoftometic.co.in/quickstockreceive/get_supplier_Master_Data?selectedSupplierIndex=${sup_c.SupplierCode}`)
            .then(response => {
                
                console.log('SuplierData:', response.data);
                
                let datecalculatte = response.data[0].Payment_Due_Days
                setsupplierduedate(+datecalculatte)
                

            })
                setQuickRecieveFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                    SupplierCode: sup_c.SupplierCode,
                }));
            } else {
                setQuickRecieveFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                    SupplierCode: '', // Set default value or handle accordingly
                }));
            }
        }else if(name==='Supplier_Bill_Date'){
            const paymentDueDays = parseInt(+supplierduedate, 10);
    
            const supplierBillDate = new Date(value); // Convert the string to a Date object
            
            const newdates= addDays(new Date(supplierBillDate),paymentDueDays)

            setQuickRecieveFormData((prevData) => ({
                ...prevData,
                [name]: value,
                Supplier_Bill_Due_Date:format(newdates,'yyyy-MM-dd'),
                SupplierName: '', // Set default value or handle accordingly
            }));
        } else {
            setQuickRecieveFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

      useEffect(()=>{
        if(PurchaseRecieveFormData.Discount !== '' || PurchaseRecieveFormData.PurchaseRateForStrip !== '' ||
        PurchaseRecieveFormData.PurchaseQuantity !=='' || PurchaseRecieveFormData.Pack_Quantity !=='' ){
        
        const newval1= +PurchaseRecieveFormData.PurchaseRateForStrip ||0
        const newval2= +newval1 * (+PurchaseRecieveFormData.PurchaseQuantity) ||0 ||0
        const newval3 =  +PurchaseRecieveFormData.Discount || 0
        let discountamt=0

            console.log('3333',newval1,newval2,newval3)

      
            if(PurchaseRecieveFormData.DiscountType==='Cash'){
                
                discountamt = newval2 - newval3

            }else if(PurchaseRecieveFormData.DiscountType==='Percentage'){
                discountamt= newval2 -((newval2 * newval3)/100)
            }else{
                discountamt = newval2

                setPurchaseRecieveFormData((prev)=>({
                    ...prev,
                    Discount: '',
                }))
                
            }
           
        const nweval3 = +discountamt / ((+PurchaseRecieveFormData.Pack_Quantity ||0) * (+PurchaseRecieveFormData.PurchaseQuantity ||0)) ||0
        console.log(discountamt)
        setPurchaseRecieveFormData((prev)=>({
            ...prev,
            Purchase_Rate_TotalAmount: newval2.toFixed(2),
            Purchase_Rate_TotalAmount_Dis:discountamt.toFixed(2),
            Purchase_Rate:nweval3.toFixed(2)
        }))

        
        }
    },[PurchaseRecieveFormData.PurchaseRateForStrip,PurchaseRecieveFormData.PurchaseQuantity,PurchaseRecieveFormData.Discount
       ,PurchaseRecieveFormData.Pack_Quantity,PurchaseRecieveFormData.DiscountType])


    useEffect(()=>{
        const newval1 = +PurchaseRecieveFormData.Tax_Percentage || 0
        const newval2 =  ((+PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis * +newval1)/100) ||0
        const newval3 =  +PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis + ((+PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis * +newval1)/100)
        const newval4 =  (+PurchaseRecieveFormData.PurchaseRateForStrip ||0) + newval1
        const newval5 =  newval4 / +PurchaseRecieveFormData.Pack_Quantity ||0

        
        console.log('4444',newval1,newval2,newval3)

        if(PurchaseRecieveFormData.GST_Type==='GST'){
            setPurchaseRecieveFormData((prev)=>({
                ...prev,
                Tax_totalAmount:newval2.toFixed(2), 
                TotalAmount:newval3.toFixed(2),
                PurchaseRateForStripGST:newval4.toFixed(2),
                Purchase_Rate_withtax:newval5.toFixed(2),
            }))
        }else if(PurchaseRecieveFormData.GST_Type==='IGST'){
            setPurchaseRecieveFormData((prev)=>({
                ...prev,
                Tax_totalAmount:newval2.toFixed(2), 
                TotalAmount:newval3.toFixed(2),
                PurchaseRateForStripGST:newval4.toFixed(2),
                Purchase_Rate_withtax:newval5.toFixed(2),
            }))
        }else{
            setPurchaseRecieveFormData((prev)=>({
                ...prev,
                Tax_totalAmount:0, 
                TotalAmount: +PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis,
                Tax_Percentage:'',
            }))

        }
    },[PurchaseRecieveFormData.Purchase_Rate_withtax,PurchaseRecieveFormData.PurchaseRateForStripGST,
PurchaseRecieveFormData.Tax_Percentage,PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis,
PurchaseRecieveFormData.GST_Type])

    useEffect(() => {
        if (datalist1.length !== 0) {
            // console.log('datalist1:',datalist1)

            let G_Total = 0;

            datalist1.map((ele, ind) => {
                return (G_Total += +ele.TotalAmount);
            });

            console.log('G_Total:', G_Total);

            setFinel_Amountdata((prev) => ({
                ...prev,
                Grand_Total: G_Total.toFixed(2),
                Finel_NetAmount: G_Total.toFixed(2),
            }))

        }
    }, [datalist1])

    useEffect(() => {

        if (Finel_Amountdata.Total_Discount_Type === 'Cash') {

            let CashDiscountAmount = Finel_Amountdata.Grand_Total - Finel_Amountdata.Total_Discount;
            setFinel_Amountdata((prevData) => ({
                ...prevData,
                Finel_NetAmount: CashDiscountAmount.toFixed(2)

            }))
        } else if (Finel_Amountdata.Total_Discount_Type === 'Percentage') {

            const percentageDiscount = Math.max(0, Math.min(Finel_Amountdata.Total_Discount, 100));

            const percentageDiscountAmount = (Finel_Amountdata.Grand_Total * (percentageDiscount / 100));
            let CashDiscountAmount = Finel_Amountdata.Grand_Total - percentageDiscountAmount;

            setFinel_Amountdata((prevData) => ({
                ...prevData,
                Finel_NetAmount: CashDiscountAmount.toFixed(2)
            }))
        }
        else {
            setFinel_Amountdata((prevData) => ({
                ...prevData,
                Total_Discount: '',
                Finel_NetAmount: Finel_Amountdata.Grand_Total
            }))
        }

    }, [Finel_Amountdata.Total_Discount_Type, Finel_Amountdata.Total_Discount, Finel_Amountdata.Grand_Total])


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if(QuickRecieveFormData.ProductCategory===''){
            alert('please select the Product Category')
        }else{
            if(QuickRecieveFormData.ProductCategory=== 'Medical'){
                if (name === 'Item_Code') {
    
                    const Set_datas = Item_Codedetailes.find((ele) => { return ele.ItemCode === value })
                    if (Set_datas) {
                        setPurchaseRecieveFormData((prevData) => ({
                            ...prevData,
                            [name]: value,
                            Item_Name: Set_datas.ItemName,
                            Generic_Name: Set_datas.GenericName,
                            Strength: Set_datas.Strength,
                            UOM: Set_datas.UOM,
                            Pack_type: Set_datas.ProductType,
                            Pack_Quantity: Set_datas.ProductQuantity,
                        }));
                    }
                    else {
                        setPurchaseRecieveFormData((prevData) => ({
                            ...prevData,
                            [name]: value,
                            Item_Name: '',
                            Generic_Name: '',
                            Strength: '',
                            UOM: '',
                            Pack_type: '',
                            Pack_Quantity: '',
                        }));
                    }
                }
                else if (name === 'Item_Name') {
        
                    const Set_datas = Item_Codedetailes.find((ele) => { return ele.ItemName === value })
        
                    if (Set_datas) {
                        setPurchaseRecieveFormData((prevData) => ({
                            ...prevData,
                            [name]: value,
                            Item_Code: Set_datas.ItemCode,
                            Generic_Name: Set_datas.GenericName,
                            Strength: Set_datas.Strength,
                            UOM: Set_datas.UOM,
                            Pack_type: Set_datas.ProductType,
                            Pack_Quantity: Set_datas.ProductQuantity,
                        }));
                    } else {
                        setPurchaseRecieveFormData((prevData) => ({
                            ...prevData,
                            [name]: value,
                            Item_Code: '',
                            Generic_Name: '',
                            Strength: '',
                            UOM: '',
                            Pack_type: '',
                            Pack_Quantity: '',
                            
                        }));
                    }
                } else if(name === 'PurchaseQuantity'){
                    setPurchaseRecieveFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                        TotalQuantity: +value * +prevData.Pack_Quantity
                    }));
                } {
                    setPurchaseRecieveFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                    }));
                }
            }else{
                if (name === 'Item_Code') {
    
                    const Set_datas = Item_Codedetailes.find((ele) => { return ele.ItemCode === value })
                    if (Set_datas) {
                        setPurchaseRecieveFormData((prevData) => ({
                            ...prevData,
                            [name]: value,
                            Item_Name: Set_datas.ItemName,
                            Pack_type: Set_datas.ProductType,
                            Pack_Quantity: Set_datas.ProductQuantity,
                            Generic_Name: 'Null',
                            Strength: 'Null',
                            UOM: 'Null',
                            HSNCode: 'Null',
                            BatchNo: 'Null',
                            ManufactureDate: 'Null',
                            ExpiryDate: 'Null',
                            Selling_Rate: 'Null',
                            Taxable_Selling_Rate: 'Null',
                        }));
                    }
                    else {
                        setPurchaseRecieveFormData((prevData) => ({
                            ...prevData,
                            [name]: value,
                            Item_Name: '',
                            Pack_type: '',
                            Pack_Quantity: '',
                        }));
                    }
                }
                else if (name === 'Item_Name') {
        
                    const Set_datas = Item_Codedetailes.find((ele) => { return ele.ItemName === value })
        
                    if (Set_datas) {
                        setPurchaseRecieveFormData((prevData) => ({
                            ...prevData,
                            [name]: value,
                            Item_Code: Set_datas.ItemCode,
                            Pack_type: Set_datas.ProductType,
                            Pack_Quantity: Set_datas.ProductQuantity,
                            Generic_Name: 'Null',
                            Strength: 'Null',
                            UOM: 'Null',
                            HSNCode: 'Null',
                            BatchNo: 'Null',
                            ManufactureDate: 'Null',
                            ExpiryDate: 'Null',
                            Selling_Rate: 'Null',
                            Taxable_Selling_Rate: 'Null',
                        }));
                    } else {
                        setPurchaseRecieveFormData((prevData) => ({
                            ...prevData,
                            [name]: value,
                            Item_Code: '',
                            Pack_type: '',
                            Pack_Quantity: '',
                        }));
                    }
                }else if(name === 'PurchaseQuantity'){
                    setPurchaseRecieveFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                        TotalQuantity: +value * +prevData.Pack_Quantity
                    }));
                }else if(name==='Discount') {
                    if(PurchaseRecieveFormData.DiscountType!==''){
                        if(PurchaseRecieveFormData.DiscountType==='Cash'){
                            if(+value > +PurchaseRecieveFormData.Purchase_Rate_TotalAmount){
                                alert(`The discount value Must Under the PurchaseRateForStrip ${PurchaseRecieveFormData.Purchase_Rate_TotalAmount}`)
                            }else{
                                setPurchaseRecieveFormData((prev) => ({
                                    ...prev,
                                    [name]: value,
                                }));
                            }
                            
                        }else{
                            if(+value > 100){
                                alert(`The discount percentage Must Under the 100`)
                            }else{
                                setPurchaseRecieveFormData((prev) => ({
                                    ...prev,
                                    [name]: value,
                                }));
                            }
                        }
                    }else{
                        alert('Please select the Discount Type')
                        setPurchaseRecieveFormData((prev) => ({
                            ...prev,
                            [name]: '',
                        }));
                    }
                } {
                    setPurchaseRecieveFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                    }));
                }
            }   
        }

    };
    console.log(PurchaseRecieveFormData)
    
    const dynamicColumns1 = () => {
        if (QuickRecieveFormData.ProductCategory === 'Medical') {
            return (
                [
                    { field: 'Item_Code', headerName: 'Item Code', width: 200 },
                    { field: 'Item_Name', headerName: 'Item Name', width: 200 },
                    { field: 'Generic_Name', headerName: 'Generic Name', width: 150 },
                    { field: 'Strength', headerName: 'Strength', width: 120 },
                    { field: 'UOM', headerName: 'UOM', width: 100 },
                    { field: 'ManufactureDate', headerName: 'Manufacture Date', width: 170 },
                    { field: 'ExpiryDate', headerName: 'Expiry Date', width: 150 },
                    { field: 'HSNCode', headerName: 'HSN Code', width: 170 },
                    { field: 'BatchNo', headerName: 'Batch No', width: 170 },
                    { field: 'Pack_type', headerName: 'Pack Type', width: 150 },
                    { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 150 },
                    { field: 'PurchaseQuantity', headerName: 'Purchase Quantity', width: 170 },
                    { field: 'TotalQuantity', headerName: 'Total Quantity', width: 170 },
                    { field: 'MRP', headerName: 'MRP', width: 150 },
                    { field: 'Purchase_Rate_perpack', headerName: 'Purchase Rate', width: 170 },
                    { field: 'GST_Type', headerName: 'GST Type', width: 170 },
                    { field: 'Tax_Percentage', headerName: 'Tax Percentage', width: 170 },
                    { field: 'Purchase_Rate_withtax', headerName: 'Taxable Purchase Rate', width: 170 },
                    { field: 'Taxable_Selling_Rate', headerName: 'Taxable Selling Rate', width: 170 },
                    { field: 'DiscountType', headerName: 'Discount Type', width: 170 },
                    { field: 'Discount', headerName: 'Discount', width: 170 },
                    { field: 'Purchase_Rate', headerName: 'Purchase Rate / Unit', width: 170 },
                    { field: 'TotalAmount', headerName: 'Total Amount', width: 170 },
                    {
                        field: 'actions',
                        headerName: 'Actions',
                        width: 120,
                        renderCell: (params) => (
                            <>
                                <Button className='cell_btn' onClick={() => handleRequestEdit1(params.row)}>
                                    <EditIcon />
                                </Button>
                            </>
                        ),
                    },
                ]
            )
        } else {
            return (
                [
                    { field: 'Item_Code', headerName: 'Item Code', width: 200 },
                    { field: 'Item_Name', headerName: 'Item Name', width: 200 },
                    { field: 'Pack_type', headerName: 'Pack Type', width: 150 },
                    { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 150 },
                    { field: 'PurchaseQuantity', headerName: 'Purchase Quantity', width: 170 },
                    { field: 'TotalQuantity', headerName: 'Total Quantity', width: 170 },
                    { field: 'MRP', headerName: 'MRP', width: 150 },
                    { field: 'Purchase_Rate_perpack', headerName: 'Purchase Rate', width: 170 },
                    { field: 'GST_Type', headerName: 'GST Type', width: 170 },
                    { field: 'Tax_Percentage', headerName: 'Tax Percentage', width: 170 },
                    { field: 'Purchase_Rate_withtax', headerName: 'Taxable Purchase Rate', width: 170 },
                    { field: 'DiscountType', headerName: 'Discount Type', width: 170 },
                    { field: 'Discount', headerName: 'Discount', width: 170 },
                    { field: 'Purchase_Rate', headerName: 'Purchase Rate / Unit', width: 170 },
                    { field: 'TotalAmount', headerName: 'Total Amount', width: 170 },
                    {
                        field: 'actions',
                        headerName: 'Actions',
                        width: 120,
                        renderCell: (params) => (
                            <>
                                <Button className='cell_btn' onClick={() => handleRequestEdit1(params.row)}>
                                    <EditIcon />
                                </Button>
                            </>
                        ),
                    },
                ]
            )
        }
    };
    const handleAdd=()=>{
            let requiredFields=[]
            if(QuickRecieveFormData.ProductCategory==='Medical'){
                requiredFields = [
                    'Item_Code',
                    'Item_Name',
                    'Generic_Name',
                    'Strength',
                    'UOM',
                    'Pack_type',
                    'Pack_Quantity',
                    'PurchaseQuantity',
                    'TotalQuantity',
                    'HSNCode',
                    'BatchNo',
                    'ManufactureDate',
                    'ExpiryDate',
                    'MRP',
                    'PurchaseRateForStrip',
                    'PurchaseRateForStripGST',
                    'Purchase_Rate_TotalAmount',
                    'Purchase_Rate_TotalAmount_Dis',
                    'Purchase_Rate',
                    'Purchase_Rate_withtax',
                    'GST_Type',
                    'Tax_Percentage',
                    'Tax_totalAmount',
                    'TotalAmount'
                ];
            }else{
                requiredFields = [
                    'Item_Code',
                    'Item_Name',
                    'Pack_type',
                    'Pack_Quantity',
                    'PurchaseQuantity',
                    'TotalQuantity',
                    'MRP',
                    'PurchaseRateForStrip',
                    'PurchaseRateForStripGST',
                    'Purchase_Rate_TotalAmount',
                    'Purchase_Rate_TotalAmount_Dis',
                    'Purchase_Rate',
                    'Purchase_Rate_withtax',
                    'GST_Type',
                    'Tax_Percentage',
                    'Tax_totalAmount',
                    'TotalAmount'
                ];
            }
             
    
    
            if (PurchaseRecieveFormData.DiscountType !== '') {
                requiredFields.push('Discount');
            }
            if ((PurchaseRecieveFormData.GST === null && PurchaseRecieveFormData.IGST === null) || (PurchaseRecieveFormData.GST === 0 && PurchaseRecieveFormData.IGST === 0) || (PurchaseRecieveFormData.GST === '' && PurchaseRecieveFormData.IGST === '')) {
                requiredFields.push('GST');
            }
    
            const missingFields = requiredFields.filter((field) => !PurchaseRecieveFormData[field]);
    
            if (missingFields.length === 0) {
                const existingItem = datalist1.find((item) => item.Item_Code === PurchaseRecieveFormData.Item_Code);

        if (existingItem) {
            alert(`Item with code ${PurchaseRecieveFormData.Item_Code} already exists in the cart.`);
        } else {
                console.log('PurchaseRecieveFormData', PurchaseRecieveFormData)
               
                const data = { 'id': datalist1.length + 1, ...PurchaseRecieveFormData }
    
                console.log('dataArray', data)
                setdatalist1((prev) => [...prev, data]);
                handleClear()
            }
            } else {
                alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
            }
        

    }
    const handleRequestEdit1 = (params) => {
        setPurchaseRecieveFormData({ ...params });
        setisedit(params);
    }
    const handleUpdate=()=>{
       
        let requiredFields=[]
            if(QuickRecieveFormData.ProductCategory==='Medical'){
                requiredFields = [
                    'Item_Code',
                    'Item_Name',
                    'Generic_Name',
                    'Strength',
                    'UOM',
                    'Pack_type',
                    'Pack_Quantity',
                    'PurchaseQuantity',
                    'TotalQuantity',
                    'HSNCode',
                    'BatchNo',
                    'ManufactureDate',
                    'ExpiryDate',
                    'MRP',
                    'PurchaseRateForStrip',
                    'PurchaseRateForStripGST',
                    'Purchase_Rate_TotalAmount',
                    'Purchase_Rate_TotalAmount_Dis',
                    'Purchase_Rate',
                    'Purchase_Rate_withtax',
                    'GST_Type',
                    'Tax_Percentage',
                    'Tax_totalAmount',
                    'TotalAmount'
                ];
            }else{
                requiredFields = [
                    'Item_Code',
                    'Item_Name',
                    'Pack_type',
                    'Pack_Quantity',
                    'PurchaseQuantity',
                    'TotalQuantity',
                    'MRP',
                    'PurchaseRateForStrip',
                    'PurchaseRateForStripGST',
                    'Purchase_Rate_TotalAmount',
                    'Purchase_Rate_TotalAmount_Dis',
                    'Purchase_Rate',
                    'Purchase_Rate_withtax',
                    'GST_Type',
                    'Tax_Percentage',
                    'Tax_totalAmount',
                    'TotalAmount'
                ];
            }
    
            
    
            if (PurchaseRecieveFormData.DiscountType !== '') {
                requiredFields.push('Discount');
            }
            if ((PurchaseRecieveFormData.GST === null && PurchaseRecieveFormData.IGST === null) || (PurchaseRecieveFormData.GST === 0 && PurchaseRecieveFormData.IGST === 0) || (PurchaseRecieveFormData.GST === '' && PurchaseRecieveFormData.IGST === '')) {
                requiredFields.push('GST');
            }
            const missingFields = requiredFields.filter((field) => !PurchaseRecieveFormData[field]);
    
            if (missingFields.length === 0) {
                const indexOfItemToUpdate = datalist1.findIndex((item) => item.Item_Code === isedit.Item_Code);
                setdatalist1((prev) => {
                    const updatedCart = [...prev];
                    updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData;
                    return updatedCart;
                });
                setisedit(null);
                handleClear()
            } else {
                alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
            }
        
    }




    const handleSubmit = () => {
        if (+QuickRecieveFormData.Supplier_Net_Amount === +Finel_Amountdata.Finel_NetAmount) {
            const requiredFields = [
                'ProductCategory',
                'GRN_Invoice_NO',
                'GRN_Date',
                'SupplierCode',
                'SupplierName',
                'Supplier_Bill_No',
                'Supplier_Bill_Date',
                'Supplier_Net_Amount',
                'Supplier_Bill_Due_Date',
            ]
            const missingFields = requiredFields.filter((field) => !QuickRecieveFormData[field])

            if (missingFields.length === 0 &&(capturedImageSec || Selected_File)) {


                const myarradata =[
                    ...datalist1.map((row, ind) => ({
                        Order_Quantity: 0,
                        RecievedOrder_Quantity:row.PurchaseQuantity,
                        RecievedTotalQuantity:row.TotalQuantity,
                        ...row,
                    }))
                ]
    
                console.log('datalist1:', myarradata)

                let FormData_all = new FormData();
                const newdataa = {
                    OverallPurchaseRecieveFormData: { 
                        ...QuickRecieveFormData
                     },
                     Finel_Amountdata: { ...Finel_Amountdata },
                     datalist1: [...myarradata]
                };
                console.log(newdataa)
                console.log("Selected_File", capturedImageSec ? capturedImageSec : Selected_File);
                const data4Supplierpay = {
                    ProductType: QuickRecieveFormData.ProductCategory,
                    SupplierCode: QuickRecieveFormData.SupplierCode,
                    SupplierName: QuickRecieveFormData.SupplierName,
                    GRNInvoice: QuickRecieveFormData.GRN_Invoice_NO,
                    GRNDate: QuickRecieveFormData.GRN_Date,
                    Supplier_Bill_Date: QuickRecieveFormData.Supplier_Bill_Date,
                    GRNDueDate: QuickRecieveFormData.Supplier_Bill_Due_Date,
                    GRNInvoiceAmount: QuickRecieveFormData.Supplier_Net_Amount,
                    status: 'Pending',
                    location: 'CentralStore',
                    createdBy: userRecord?.username
                }

                axios.post('https://clinic.vesoftometic.co.in/quickstockreceive/insert_supplier_pay_detials', data4Supplierpay) 
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error('Error posting GRN data:', error);
                });


                for (let key in newdataa) {
                    FormData_all.append(key, JSON.stringify(newdataa[key]));
                }
                FormData_all.append("Selected_File", capturedImageSec ? capturedImageSec : Selected_File)

             
                if (QuickRecieveFormData.ProductCategory === 'Medical') {
                    axios.post('https://clinic.vesoftometic.co.in/quickstockreceive/insert_Medical_GRN_data', FormData_all)
                        .then((response) => {
                            console.log(response.data);
                            navigate('/Home/Quick_Stock_Recieve_List');
                        })
                        .catch((error) => {
                            console.error('Error posting GRN data:', error);
                        });
                }
                else if (QuickRecieveFormData.ProductCategory === 'Non_Medical') {

                    axios.post('https://clinic.vesoftometic.co.in/quickstockreceive/insert_Non_Medical_GRN_data', FormData_all)
                        .then((response) => {
                            console.log(response.data);
                            navigate('/Home/Quick_Stock_Recieve_List');

                        })
                        .catch((error) => {
                            console.error('Error posting GRN data:', error);
                        });

                }

                else if (QuickRecieveFormData.ProductCategory === 'Assets') {

                    axios.post('https://clinic.vesoftometic.co.in/quickstockreceive/insert_Assets_GRN_data', FormData_all)
                        .then((response) => {
                            console.log(response.data);
                            navigate('/Home/Quick_Stock_Recieve_List');

                        })
                        .catch((error) => {
                            console.error('Error posting GRN data:', error);
                        });



                }

                else if (QuickRecieveFormData.ProductCategory === 'Stationary') {

                    axios.post('https://clinic.vesoftometic.co.in/quickstockreceive/insert_Stationary_GRN_data', FormData_all)
                        .then((response) => {
                            console.log(response.data);
                            navigate('/Home/Quick_Stock_Recieve_List');

                        })
                        .catch((error) => {
                            console.error('Error posting GRN data:', error);
                        });

                }

                else if (QuickRecieveFormData.ProductCategory === 'Non_Stationary') {

                    axios.post('https://clinic.vesoftometic.co.in/quickstockreceive/insert_Non_Stationary_GRN_data', FormData_all)
                        .then((response) => {
                            console.log(response.data);
                            navigate('/Home/Quick_Stock_Recieve_List');
                        })
                        .catch((error) => {
                            console.error('Error posting GRN data:', error);
                        });

                }
            } else {
                alert(`Please fill out all required fields: ${missingFields.length!==0?missingFields.join(', '):'Bill Document'}`);
            }




        }
        else {
            alert('Please Check Net Amount')
        }
    };


    const handleUpdatedatas = () => {
        if (+QuickRecieveFormData.Supplier_Net_Amount === +Finel_Amountdata.Finel_NetAmount) {

            const myarradata =[
                ...datalist1.map((row, ind) => ({
                    Order_Quantity: 'Nill',
                    RecievedOrder_Quantity:row.PurchaseQuantity,
                    RecievedTotalQuantity:row.TotalQuantity,
                    ...row,
                }))
            ]

            console.log('datalist1:', myarradata)

            let FormData_all = new FormData();
            const newdataa = {
                QuickRecieveFormData: { ...QuickRecieveFormData },
                Finel_Amountdata: { ...Finel_Amountdata },
                datalist1: [...myarradata],
                product_category: { product_category: QuickRecieveFormData.ProductCategory }
            };

           
                        


            for (let key in newdataa) {
                FormData_all.append(key, JSON.stringify(newdataa[key]));
            }

            FormData_all.append("Selected_File", capturedImageSec ? capturedImageSec : Selected_File)

            axios.post('https://clinic.vesoftometic.co.in/quickstockreceive/update_GRN_data', FormData_all)
                .then((response) => {
                    console.log(response.data);
                    navigate('/Home/Quick_Stock_Recieve_List');
                    
                    dispatchvalue({type : 'GRNedit' , value :({})})
                })
                .catch((error) => {
                    console.error('Error posting GRN data:', error);
                });
        } else {
            alert('Please Check Net Amount');
        }
    }
    return (
        <div className="Supplier_Master_Container">
            <div className="Product_Master_h_head">
                <h3>GRN Invoice</h3>
            </div>
            <div className='Stoct_Product_master_Container'>
                <div className='Stoct_Product_master_form'>
                    <div className="Stoct_Product_master_form_row">
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Product Category :</label>

                            <select
                                name='ProductCategory'
                                value={QuickRecieveFormData.ProductCategory}
                                onChange={handleInputChange1}
                            >
                                <option value=''>Select</option>
                                <option value='Medical'>Medical</option>
                                <option value='Non_Medical'>Non Medical</option>
                                <option value='Assets'>Assets</option>
                                <option value='Stationary'>Stationary</option>
                                <option value='Non_Stationary'>Non Stationary</option>
                            </select>
                        </div>
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">GRN Invoice No :</label>
                            <input
                                type="text"
                                name="GRN_Invoice_NO"
                                placeholder="Enter Invoice No"
                                value={QuickRecieveFormData.GRN_Invoice_NO}
                                onChange={handleInputChange1}
                                className='Stoct_Product_master_form_Input'
                            />
                        </div>
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">GRN Date :</label>
                            <input
                                type="date"
                                name="GRN_Date"
                                placeholder="Enter Invoice No"
                                value={QuickRecieveFormData.GRN_Date}
                                onChange={handleInputChange1}
                                className='Stoct_Product_master_form_Input'
                            />
                        </div>

                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Supplier Code :</label>

                            <input
                                type="text"
                                name="SupplierCode"
                                list="SupplierCode_list"
                                placeholder="Enter Supplier Code"
                                value={QuickRecieveFormData.SupplierCode}
                                onChange={handleInputChange1}
                                className='Stoct_Product_master_form_Input'
                                autoComplete="off"
                            />
                            <datalist id="SupplierCode_list">
                                {Supplierdetailes.map((item, index) => (
                                    <option key={index} value={item.SupplierCode}></option>
                                ))}
                            </datalist>
                        </div>


                    </div>

                </div>
            </div>

            <div className='Stoct_Product_master_Container'>
                <div className='Stoct_Product_master_form'>
                    <div className="Stoct_Product_master_form_row">

                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Supplier Name :</label>

                            <input
                                type="text"
                                name="SupplierName"
                                list="SupplierName_list"
                                placeholder="Enter Supplier Name"
                                value={QuickRecieveFormData.SupplierName}
                                onChange={handleInputChange1}
                                className='Stoct_Product_master_form_Input'
                                autoComplete="off"
                            />
                            <datalist id="SupplierName_list">
                                {Supplierdetailes.map((item, index) => (
                                    <option key={index} value={item.SupplierName}></option>
                                ))}
                            </datalist>
                        </div>
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Supplier Bill No :</label>
                            <input
                                type="text"
                                name="Supplier_Bill_No"
                                placeholder="Enter Supplier Bill No"
                                value={QuickRecieveFormData.Supplier_Bill_No}
                                className='Stoct_Product_master_form_Input'
                                onChange={handleInputChange1}
                            />
                        </div>
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Supplier Bill Date :</label>
                            <input
                                type="date"
                                name="Supplier_Bill_Date"
                                placeholder="Enter Invoice No"
                                value={QuickRecieveFormData.Supplier_Bill_Date}
                                className='Stoct_Product_master_form_Input'
                                onChange={handleInputChange1}
                            />
                        </div>
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Supplier Bill Due Date :</label>
                            <input
                                type="date"
                                name="Supplier_Bill_Due_Date"
                                placeholder="Enter Supplier Name"
                                value={QuickRecieveFormData.Supplier_Bill_Due_Date}
                                className='Stoct_Product_master_form_Input'
                                onChange={handleInputChange1}
                            />
                        </div>

                    </div>

                </div>
            </div>

            <div className='Stoct_Product_master_Container'>
                <div className='Stoct_Product_master_form'>
                    <div className="Stoct_Product_master_form_row">

                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Bill Net Amount :</label>
                            <input
                                type="number"
                                name="Supplier_Net_Amount"
                                placeholder="Enter Supplier Code"
                                value={QuickRecieveFormData.Supplier_Net_Amount}
                                autoComplete='off'
                                className='Stoct_Product_master_form_Input'
                                onChange={handleInputChange1}
                            />
                        </div>


                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="patientPhoto">Bill Document:</label>
                            <div className="file-input-444">
                                <div className="input-container-99">
                                    <input
                                        type="file"
                                        id="fileChooser1"
                                        name="fileChooser1"
                                        accept=".pdf, image/*" // Corrected accept attribute
                                        required
                                        onChange={(e) => {
                                            // Use e.target.files to access selected files
                                            const selectedFile = e.target.files[0];

                                            // Check if a file was selected
                                            if (selectedFile) {
                                                // Do something with the selected file, e.g., set state or call a function
                                                setSelected_File(selectedFile);
                                            }
                                        }}
                                    />
                                    <label htmlFor="fileChooser1" className="choose-file-button-99" >
                                        Choose File
                                    </label>
                                </div>
                                <span className="Stoct_Product_master_form_Label" >or</span>
                                <div className="input-container-99">
                                    <button
                                        onClick={handleOpenCamera}
                                        className="choose-file-button-55"
                                    >
                                        Take Pic
                                    </button>

                                    {showCamera && (
                                        <div
                                            className="showcamera_takepic"
                                            onClick={handleHideCamera}
                                        >
                                            <div
                                                className="showcamera_1_takepic1"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                {isImageCaptured ? ( // Display the captured image
                                                    <img
                                                        src={URL.createObjectURL(capturedImageSec)}
                                                        alt="captured"
                                                        className="captured-image11"
                                                    />
                                                ) : (
                                                    <div className="camera-container">
                                                        <div className="web_head">
                                                            <h3>Image</h3>
                                                        </div><br></br>
                                                        <div className='RotateButton_canva'>
                                                            <Webcam
                                                                audio={false}
                                                                ref={webcamRef}
                                                                screenshotFormat="image/jpeg"
                                                                mirrored={facingMode === 'user'}
                                                                className="web_cam"
                                                                videoConstraints={videoConstraints}
                                                            />
                                                            {!devices.includes(IsmobileorNot) &&
                                                                <div className="web_cam__1">
                                                                    <button onClick={switchCamera}>
                                                                        <CameraswitchIcon />
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="web_btn">
                                                    {isImageCaptured ? ( // Render the Recapture button if an image is captured
                                                        <button
                                                            onClick={handleRecaptureImageSec}
                                                            className="btncon_add"
                                                        >
                                                            Recapture
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={capturedImageSecnd}
                                                            className="btncon_add"
                                                        >
                                                            Capture
                                                        </button>
                                                    )}
                                                    <button
                                                        onClick={handleCloseCamera}
                                                        className="btncon_add"
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className="Add_items_Purchase_Master">
                <span>Add Items</span>
            </div>
            {
                    QuickRecieveFormData.ProductCategory === 'Medical' ?

                        <div className='Stoct_Product_master_Container'>
                            <div className='Stoct_Product_master_form'>
                                <div className="Stoct_Product_master_form_row">
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Item Code :</label>
                                      
                                        <input
                                        type="text"
                                        name="Item_Code"
                                        list="Item_Code_list"
                                        placeholder="Enter Item Code"
                                        value={PurchaseRecieveFormData.Item_Code}
                                        onChange={handleInputChange}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                        autoComplete="off"
                                    />
                                    <datalist id="Item_Code_list">
                                        {Item_Codedetailes.map((item, index) => (
                                            <option key={index} value={item.ItemCode}></option>
                                        ))}
                                    </datalist>
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="code">Item Name :</label>
                                        
                                        <input
                                        type="text"
                                        name="Item_Name"
                                        list="Item_Code_name"
                                        placeholder="Enter Item Name"
                                        value={PurchaseRecieveFormData.Item_Name}
                                        onChange={handleInputChange}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                    />
                                     <datalist id="Item_Code_name">
                                        {Item_Codedetailes.map((item, index) => (
                                            <option key={index} value={item.ItemName}></option>
                                        ))}
                                    </datalist>
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Generic Name :</label>
                                        <input
                                            type="text"
                                            name="Generic_Name"
                                            placeholder="Enter Generic Name"
                                            value={PurchaseRecieveFormData.Generic_Name}
                                            onChange={handleInputChange}
                                            readOnly
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Strength :</label>
                                        <input
                                            type="text"
                                            name="Strength"
                                            placeholder="Enter Strength"
                                            value={PurchaseRecieveFormData.Strength}
                                            onChange={handleInputChange}
                                            readOnly
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                </div>


                                <div className="Stoct_Product_master_form_row">

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">UOM :</label>
                                        <input
                                            type="text"
                                            name="UOM"
                                            placeholder="Enter UOM"
                                            value={PurchaseRecieveFormData.UOM}
                                            required
                                            readOnly
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="code">Pack Type :</label>
                                        <input
                                            type="text"
                                            name="Pack_type"
                                            placeholder="Enter Pack Type"
                                            value={PurchaseRecieveFormData.Pack_type}
                                            required
                                            readOnly
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Pack Quantity :</label>
                                        <input
                                            type="number"
                                            name="Pack_Quantity"
                                            placeholder="Enter Pack Quantity"
                                            value={PurchaseRecieveFormData.Pack_Quantity}
                                            readOnly
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="code">Purchase Quantity :</label>
                                        <input
                                            type="number"
                                            name="PurchaseQuantity"
                                            placeholder="Enter Purchase Quantity "
                                            value={PurchaseRecieveFormData.PurchaseQuantity}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>






                                </div>
                                

                                <div className="Stoct_Product_master_form_row">

                                <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="code"> Total Quantity :</label>
                                        <input
                                            type="number"
                                            name="TotalQuantity"
                                            placeholder="Enter Total Quantity"
                                            value={PurchaseRecieveFormData.TotalQuantity}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">HSN Code :</label>
                                        <input
                                            type="text"
                                            name="HSNCode"
                                            placeholder="Enter HSNCode"
                                            value={PurchaseRecieveFormData.HSNCode}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Batch No :</label>
                                        <input
                                            type="text"
                                            name="BatchNo"
                                            placeholder="Enter Batch No"
                                            value={PurchaseRecieveFormData.BatchNo}
                                            onChange={handleInputChange}
                                            required
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>


                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="code">Manufacture Date :</label>
                                        <input
                                            type="date"
                                            name="ManufactureDate"
                                            placeholder="Enter ManufactureDate"
                                            value={PurchaseRecieveFormData.ManufactureDate}
                                            onChange={handleInputChange}
                                            required
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>






                                </div>

                                <div className="Stoct_Product_master_form_row">  

                                 <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Expiry Date :</label>
                                         <input
                                             type="date"
                                             name="ExpiryDate"
                                             placeholder="Enter ExpiryDate"
                                             value={PurchaseRecieveFormData.ExpiryDate}
                                             onChange={handleInputChange}
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>

                                 <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code">MRP :</label>
                                         <input
                                             type="number"
                                             name="MRP"
                                             placeholder="Enter MRP"
                                             value={PurchaseRecieveFormData.MRP}
                                             onChange={handleInputChange}
                                             required
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>


                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code">Purchase Rate (per Pack):</label>
                                         <input
                                             type="number"
                                             name="PurchaseRateForStrip"
                                             placeholder="Enter Purchase Rate"
                                             value={PurchaseRecieveFormData.PurchaseRateForStrip}
                                             onChange={handleInputChange}
                                             required
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>

                                     

                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Total Purchase Rate :</label>
                                         <input
                                             type="number"
                                             name="Purchase_Rate_TotalAmount"
                                             placeholder="Enter Total Purchase Rate"
                                             value={PurchaseRecieveFormData.Purchase_Rate_TotalAmount}
                                             onChange={handleInputChange}
                                             className='Stoct_Product_master_form_Input'
                                             disabled={PurchaseRecieveFormData.DiscountType === ''}
                                         />
                                     </div>

                                 
                                     


                                            

                                     
                                    
 
                                 </div>
                                 

                                 <div className="Stoct_Product_master_form_row"> 

                                 <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Discount Type :</label>
                                         <select
                                             value={PurchaseRecieveFormData.DiscountType}
                                             name='DiscountType'
                                             onChange={handleInputChange}
                                             disabled={Finel_Amountdata.Total_Discount_Type !== ''}
                                         >
                                             <option value=''>Select</option>
                                             <option value='Cash'>Cash</option>
                                             <option value='Percentage'>Percentage</option>
                                         </select>
                                     </div>
                                 
                                 <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Discount :</label>
                                         <input
                                             type="number"
                                             name="Discount"
                                             placeholder="Enter Discount"
                                             value={PurchaseRecieveFormData.Discount}
                                             onChange={handleInputChange}
                                             className='Stoct_Product_master_form_Input'
                                             disabled={PurchaseRecieveFormData.DiscountType === ''}
                                         />
                                     </div>

                                 

                                 <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code">Total Taxable Purchase Rate :</label>
                                         <input
                                             type="number"
                                             name="PurchaseRateForStripGST"
                                             placeholder="Enter Purchase Rate"
                                             value={PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis}
                                             readOnly
                                             required
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>

                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code"> Taxable Purchase Rate (Per Quantity):</label>
                                         <input
                                             type="number"
                                             name="Purchase_Rate"
                                             placeholder="Enter Purchase Rate"
                                             value={PurchaseRecieveFormData.Purchase_Rate}
                                             onChange={handleInputChange}
                                             readOnly
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>

                                     
 


                                 

                                 

                                     </div>

                                     <div className="Stoct_Product_master_form_row">  

                                    
                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Tax Type:</label>
                                         <select
                                             value={PurchaseRecieveFormData.GST_Type}
                                             name='GST_Type'
                                             onChange={handleInputChange}
                                         >
                                             <option value=''>Select</option>
                                             <option value='GST'>GST</option>
                                            <option value='IGST'>IGST</option>
                                            <option value='NON_GST'>NON-GST</option>
                                         </select>
                                     </div>

                                 <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code">Tax Percentage</label>
                                         <select
                                             name="Tax_Percentage"
                                             placeholder="Enter Tax Percentage"
                                             value={PurchaseRecieveFormData.Tax_Percentage}
                                             onChange={handleInputChange}
                                             required
                                             disabled={PurchaseRecieveFormData.GST_Type === 'NON_GST' || PurchaseRecieveFormData.GST_Type === 'NON_GST'}
                                         >
                                             <option value=''>Select</option>
                                             <option value={18}>18 %</option>
                                            <option value={12}>12 %</option>
                                            <option value={5}>5 %</option>
                                         </select>
                                     </div>

                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Tax Amount :</label>
                                         <input
                                             type="number"
                                             name="Tax_totalAmount"
                                             placeholder="Enter Tax Amount"
                                             value={PurchaseRecieveFormData.Tax_totalAmount}
                                             onChange={handleInputChange}
                                             required
                                             readOnly
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>
                                     
                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Amount :</label>
                                         <input
                                             type="number"
                                             name="TotalAmount"
                                             placeholder="Enter Total Amount"
                                             value={PurchaseRecieveFormData.TotalAmount}
                                             onChange={handleInputChange}
                                             required
                                             readOnly
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>
                                 
                                     

                                   </div>



                                
                            </div>
                        </div>
                        :

                        <div className='Stoct_Product_master_Container'>
                            <div className='Stoct_Product_master_form'>
                                <div className="Stoct_Product_master_form_row">
                                <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Item Code :</label>
                                      
                                        <input
                                        type="text"
                                        name="Item_Code"
                                        list="Item_Code_list"
                                        placeholder="Enter Item Code"
                                        value={PurchaseRecieveFormData.Item_Code}
                                        onChange={handleInputChange}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                        autoComplete="off"
                                    />
                                    <datalist id="Item_Code_list">
                                        {Item_Codedetailes.map((item, index) => (
                                            <option key={index} value={item.ItemCode}></option>
                                        ))}
                                    </datalist>
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="code">Item Name :</label>
                                        
                                        <input
                                        type="text"
                                        name="Item_Name"
                                        list="Item_Code_name"
                                        placeholder="Enter Item Name"
                                        value={PurchaseRecieveFormData.Item_Name}
                                        onChange={handleInputChange}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                    />
                                     <datalist id="Item_Code_name">
                                        {Item_Codedetailes.map((item, index) => (
                                            <option key={index} value={item.ItemName}></option>
                                        ))}
                                    </datalist>
                                    </div>
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="code">Pack Type :</label>
                                        <input
                                            type="text"
                                            name="Pack_type"
                                            placeholder="Enter Pack Type"
                                            readOnly
                                            value={PurchaseRecieveFormData.Pack_type}
                                            required
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>


                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Pack Quantity :</label>
                                        <input
                                            type="number"
                                            name="Pack_Quantity"
                                            placeholder="Enter Pack Quantity"
                                            readOnly      
                                            value={PurchaseRecieveFormData.Pack_Quantity}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>



                                </div>



                               

                                <div className="Stoct_Product_master_form_row">

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Purchase Quantity :</label>
                                        <input
                                            type="text"
                                            name="PurchaseQuantity"
                                            placeholder="Enter Purchase Quantity"
                                            value={PurchaseRecieveFormData.PurchaseQuantity}
                                            className='Stoct_Product_master_form_Input'
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Quantity :</label>
                                        <input
                                            type="text"
                                            name="TotalQuantity"
                                            placeholder="Enter Total Quantity"
                                            
                                            value={PurchaseRecieveFormData.TotalQuantity}
                                            onChange={handleInputChange}
                                            required
                                            className='Stoct_Product_master_form_Input'
                                            readOnly
                                        />
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">MRP (Per Quantity) :</label>
                                        <input
                                            type="text"
                                            name="MRP"
                                            placeholder="Enter MRP"
                                            value={PurchaseRecieveFormData.MRP}
                                            onChange={handleInputChange}
                                            required
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>


                                    <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code">Purchase Rate :</label>
                                         <input
                                             type="number"
                                             name="PurchaseRateForStrip"
                                             placeholder="Enter Purchase Rate"
                                             value={PurchaseRecieveFormData.PurchaseRateForStrip}
                                             onChange={handleInputChange}
                                             required
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>




                                </div>

                                <div className="Stoct_Product_master_form_row">

                                 <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code">Total Purchase Rate :</label>
                                         <input
                                             type="number"
                                             name="PurchaseRateForStripGST"
                                             placeholder="Enter Purchase Rate"
                                             value={PurchaseRecieveFormData.Purchase_Rate_TotalAmount}
                                             readOnly
                                             required
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>


                                 <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Discount Type :</label>
                                         <select
                                             value={PurchaseRecieveFormData.DiscountType}
                                             name='DiscountType'
                                             onChange={handleInputChange}
                                             disabled={Finel_Amountdata.Total_Discount_Type !== ''}
                                         >
                                             <option value=''>Select</option>
                                             <option value='Cash'>Cash</option>
                                             <option value='Percentage'>Percentage</option>
                                         </select>
                                     </div>

                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Discount :</label>
                                         <input
                                             type="text"
                                             name="Discount"
                                             placeholder="Enter Discount"
                                             value={PurchaseRecieveFormData.Discount}
                                             onChange={handleInputChange}
                                             className='Stoct_Product_master_form_Input'
                                             disabled={PurchaseRecieveFormData.DiscountType === ''}
                                         />
                                     </div>

                                         
                                 

                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code">Taxable Total Purchase Rate :</label>
                                         <input
                                             type="number"
                                             name="Purchase_Rate_TotalAmount_Dis"
                                             placeholder="Enter Purchase Rate"
                                             value={PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis}
                                             readOnly
                                             required
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>

                                    
                        
                                     </div>

                                     <div className="Stoct_Product_master_form_row">
                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code">Taxable Purchase Rate (Per Quantity) :</label>
                                         <input
                                             type="number"
                                             name="Purchase_Rate"
                                             placeholder="Enter Purchase Rate"
                                             value={PurchaseRecieveFormData.Purchase_Rate}
                                             readOnly
                                             required
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>

                                     

                                    
                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Tax Type:</label>
                                         <select
                                             value={PurchaseRecieveFormData.GST_Type}
                                             name='GST_Type'
                                             onChange={handleInputChange}
                                         >
                                             <option value=''>Select</option>
                                             <option value='GST'>GST</option>
                                            <option value='IGST'>IGST</option>
                                            <option value='NON_GST'>NON-GST</option>
                                         </select>
                                     </div>


                                 <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="code">Tax Percentage</label>
                                         <select
                                             name="Tax_Percentage"
                                             placeholder="Enter Tax Percentage"
                                             value={PurchaseRecieveFormData.Tax_Percentage}
                                             onChange={handleInputChange}
                                             required
                                             disabled={PurchaseRecieveFormData.GST_Type === 'NON_GST' || PurchaseRecieveFormData.GST_Type === 'NON_GST'}
                                         >
                                             <option value=''>Select</option>
                                             <option value={18}>18 %</option>
                                            <option value={12}>12 %</option>
                                            <option value={5}>5 %</option>
                                         </select>
                                     </div>
                                    
                                     <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Tax Amount :</label>
                                         <input
                                             type="text"
                                             name="Tax_totalAmount"
                                             placeholder="Enter Total Tax Amount"
                                             value={PurchaseRecieveFormData.Tax_totalAmount}
                                             onChange={handleInputChange}
                                             required
                                             readOnly
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>
                                     

                                     


                                    
                                     
                                     </div>
                                    <div className='Stoct_Product_master_form_row'>
                                    <div className="Stoct_Product_master_form_row_div">
                                         <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Amount :</label>
                                         <input
                                             type="text"
                                             name="TotalAmount"
                                             placeholder="Enter Total Amount"
                                             value={PurchaseRecieveFormData.TotalAmount}
                                             onChange={handleInputChange}
                                             required
                                             readOnly
                                             className='Stoct_Product_master_form_Input'
                                         />
                                     </div>
                                    </div>


                                
                            </div>
                        </div>
                }
                <div className='Stoct_Product_master_form_Save'>
                        <button className=' Stoct_Product_master_form_Save_button' onClick={isedit ? handleUpdate : handleAdd} >
                            {isedit ? "Update" : "Add"}
                        </button>
                    </div>
                    {datalist1.length !== 0 &&
                    <div className='IP_grid'>
                        <ThemeProvider theme={theme}>
                            <div className='IP_grid_1'>
                                <DataGrid
                                    rows={datalist1.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                    columns={dynamicColumns1()} // Use dynamic columns here
                                    pageSize={10}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10]}
                                    onPageChange={handlePageChange}
                                    hideFooterPagination
                                    hideFooterSelectedRowCount
                                    className=' Ip_data_grid'
                                />
                                {showdown > 0 && datalist1.length > 10 && (
                                    <div className='IP_grid_foot'>
                                        <button
                                            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                            disabled={page === 0}
                                        >
                                            Previous
                                        </button>
                                        Page {page + 1} of {totalPages}
                                        <button
                                            onClick={() =>
                                                setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                            }
                                            disabled={page === totalPages - 1}
                                        >
                                            Next
                                        </button>
                                    </div>
                                )}
                            </div>
                        </ThemeProvider>
                        {showdown !== 0 && datalist1.length !== 0 ? (
                            ''
                        ) : (
                            <div className='IP_norecords'>
                                <span>No Records Found</span>
                            </div>
                        )}
                    </div>}
                    {datalist1.length !== 0 ?
                    <div className='Stoct_Product_master_Container'>
                        <div className='Stoct_Product_master_form'>
                            <div className="Stoct_Product_master_form_row">

                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Grand Total :</label>
                                    <input
                                        type="number"
                                        name="Grand_Total"
                                        placeholder="Enter Grand Amount"
                                        value={Finel_Amountdata.Grand_Total}
                                        required
                                        readOnly
                                        className='Stoct_Product_master_form_Input'
                                    />
                                </div>

                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Total Discount Type :</label>
                                    <select
                                        value={Finel_Amountdata.Total_Discount_Type}
                                        name='Total_Discount_Type'
                                        onChange={handleInputFinel_AmountdataChange}
                                        disabled={datalist1.some(ele => ele.DiscountType !== '')}
                                    >
                                        <option value=''>Select</option>
                                        <option value='Cash'>Cash</option>
                                        <option value='Percentage'>Percentage</option>
                                    </select>
                                </div>

                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Total Discount :</label>
                                    <input
                                        type="number"
                                        name="Total_Discount"
                                        placeholder="Enter Discount"
                                        value={Finel_Amountdata.Total_Discount}
                                        onChange={handleInputFinel_AmountdataChange}
                                        className='Stoct_Product_master_form_Input'
                                        disabled={Finel_Amountdata.Total_Discount_Type === '' || datalist1.some(ele => ele.DiscountType !== '')}
                                    />
                                </div>
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Net Amount :</label>
                                    <input
                                        type="number"
                                        name="Finel_NetAmount"
                                        placeholder="Enter Total Amount"
                                        value={Finel_Amountdata.Finel_NetAmount}
                                        readOnly
                                        required
                                        className='Stoct_Product_master_form_Input'
                                    />
                                </div>

                            </div>
                        </div>
                    </div> : ''
                }
                {datalist1.length !== 0 &&
                    <div className='Stoct_Product_master_form_Save'>
                        {GRNedit && Object.keys(GRNedit).length !== 0 ? <button className=' Stoct_Product_master_form_Save_button' onClick={handleUpdatedatas}>
                            Update
                        </button> :
                            <button className=' Stoct_Product_master_form_Save_button' onClick={handleSubmit}>
                                Submit
                            </button>}
                    </div>}
        </div>
    )
}

export default QuickStockMasterCentral;
