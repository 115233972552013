import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import '../SupplierMaster/SupplierMaster.css'
import { useSelector } from 'react-redux';


const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeader: {
                    backgroundColor: "var(--ProjectColor)",
                    textAlign: 'Center',
                },
                root: {
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
                        textAlign: 'center',
                        display: 'flex !important',
                        justifyContent: 'center !important'
                    },
                    "& .MuiDataGrid-window": {
                        overflow: "hidden !important",
                    },
                },
                cell: {
                    borderTop: "0px !important",
                    borderBottom: "1px solid  var(--ProjectColor) !important",
                    display: 'flex',
                    justifyContent: 'center'
                },
            },
        },
    },
});

export default function IndentApprove() {

    const userRecord = useSelector(state => state.userRecord?.UserData)

    const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)


    const navigate = useNavigate();
    const [page1, setPage1] = useState(0);
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [Productype, setProductype] = useState('');
    const [summa, setsumma] = useState([])
    const [selectedShow, setSelectedShow] = useState(false);
    const [selectedShow1, setSelectedShow1] = useState(false);
    const [statustype, setstatustype] = useState('Waiting')
    const [Rowdata, setRowdata] = useState([]);
    const [SelectedRowss, setSelectedRowss] = useState(null);

    const showdown1 = Rowdata.length;
    const pageSize1 = 10;



    useEffect(() => {

        if (Productype === 'MedicalConsumable' ||
        Productype === 'MedicalNonConsumable' ||
        Productype === 'NonMedicalConsumable' ||
        Productype === 'NonMedicalNonConsumable' ||
        Productype === 'Surgical' ) {
            axios.get(`https://clinic.vesoftometic.co.in/IndentRaise/get_medical_purchase_Raise_indent_data?Location=${userRecord?.location}&Status=${statustype}&Productype=${Productype}`)
                .then((response) => {
                    const data = response.data.medical_purchase_orders;
                    console.log('out', data.medical_purchase_orders);
                    setsumma([
                        ...data.map((row, ind) => ({
                            id: ind + 1,
                            ...row,
                        }))
                    ]);
                })
                .catch((error) => {
                    console.error('Error fetching patients data:', error);
                });
        
        }
        else if (Productype === 'Assets') {
            axios.get(`https://clinic.vesoftometic.co.in/IndentRaise/get_assets_purchase_Raise_indent_data?Location=${userRecord?.location}&Status=${statustype}`)
                .then((response) => {
                    const data = response.data.assets_purchase_orders;
                    console.log('out', data.assets_purchase_orders);
                    setsumma([
                        ...data.map((row, ind) => ({
                            id: ind + 1,
                            ...row,
                        }))
                    ]);
                })
                .catch((error) => {
                    console.error('Error fetching patients data:', error);
                });
        }
        else if (Productype === 'Stationary') {
            axios.get(`https://clinic.vesoftometic.co.in/IndentRaise/get_stationary_purchase_Raise_indent_data?Location=${userRecord?.location}&Status=${statustype}`)
                .then((response) => {
                    const data = response.data.stationary_purchase_orders;
                    console.log('out', data.stationary_purchase_orders);
                    setsumma([
                        ...data.map((row, ind) => ({
                            id: ind + 1,
                            ...row,
                        }))
                    ]);
                })
                .catch((error) => {
                    console.error('Error fetching patients data:', error);
                });
        }
        else if (Productype === 'Non_Stationary') {
            axios.get(`https://clinic.vesoftometic.co.in/IndentRaise/get_non_stationary_purchase_Raise_indent_data?Location=${userRecord?.location}&Status=${statustype}`)
                .then((response) => {
                    const data = response.data.non_stationary_purchase_orders;
                    console.log('out', data.non_stationary_purchase_orders);
                    setsumma([
                        ...data.map((row, ind) => ({
                            id: ind + 1,
                            ...row,
                        }))
                    ]);
                })
                .catch((error) => {
                    console.error('Error fetching patients data:', error);
                });
        }

    }, [Productype, statustype, selectedShow1]);
    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {
        const index = params.row.items
        setSelectedRowss(params.row.IndentRaise_InvoiceNo)
        console.log('index:', index)
        let B_data = index.map((p, index) => ({
            id: index + 1,
            ...p
        }))
        setRowdata(B_data)
        setSelectedShow(true);

    };
    const handleApproveEdit = (params) => {
        const allRejected = params.row.items.every((node) => node.Status === 'Rejected');
        console.log(allRejected);
        const updatedRowdata = params.row.items.map(item => {
            if (item.Status !== 'Rejected') {
                return { ...item, Status: 'Approved' };
            } else {
                return item;
            }
        });
        const data = updatedRowdata.map((row) => ({
            Productype: Productype,
            index: params.row.IndentRaise_InvoiceNo,
            Item_Code: row.Item_Code,
            Approved_Quantity: row.Approved_Quantity,
            Status: row.Status,

        }))
        console.log(data)
        if (allRejected) {
            alert('All items are Rejected');
            const editrow = {
                Productype: Productype,
                index: params.row.IndentRaise_InvoiceNo,
                Status: 'Rejected',
                Approved_By: userRecord?.username
            }
            axios.post(`https://clinic.vesoftometic.co.in/IndentRaise/Update_indent_Raise_Appove`, editrow)
                .then((response) => {
                    console.log(response.data)
                    setSelectedShow1(!selectedShow1)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            if (params.row.Status === 'Waiting') {
                const editrow = {
                    Productype: Productype,
                    index: params.row.IndentRaise_InvoiceNo,
                    Status: 'Pending',
                    Approved_By: userRecord?.username
                }
                const resultReject = window.confirm('Are you sure ,you want to Approve it ?')

                if (resultReject) {
                    axios.post(`https://clinic.vesoftometic.co.in/IndentRaise/Update_indent_Raise_Appove`, editrow)
                        .then((response) => {
                            console.log(response.data)
                            setSelectedShow1(!selectedShow1)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    axios.post(`https://clinic.vesoftometic.co.in/IndentRaise/Approve_mull_the_indent`, data)
                        .then((response) => {
                            console.log(response.data)
                            setSelectedShow1(!selectedShow1)
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                }
            } else if (params.row.Status === 'Recieved') {
                const editrow1 = {
                    Productype: Productype,
                    index: params.row.IndentRaise_InvoiceNo,
                    Status: 'Approved',
                    Approved_By: userRecord?.username
                }
                const resultReject = window.confirm('Are you sure ,you want to Approve it ?')

                if (resultReject) {
                    axios.post(`https://clinic.vesoftometic.co.in/IndentRaise/Update_indent_Recieve_Appove`, editrow1)
                        .then((response) => {
                            console.log(response.data)
                            setSelectedShow1(!selectedShow1)
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                }
            }
        }


    }



    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        console.log('ss', summa)

        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.IndentRaise_InvoiceNo.toLowerCase();

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery)
            );
        });

        setFilteredRows(filteredData);
    }, [searchQuery, summa]);





    const handlePageChange1 = (params) => {
        setPage1(params.page);
    };
    const totalPages1 = Math.ceil(Rowdata.length / 10);


    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'IndentRaise_InvoiceNo', headerName: 'IndentRaise InvoiceNo', width: 180 },
        { field: 'RequestDate', headerName: 'Request Raise Date', width: 180 },
        { field: 'RequestFrom', headerName: 'Request From', width: 150 },
        { field: 'RequestTo', headerName: 'Request To', width: 150 },
        { field: 'Reason', headerName: 'Reason', width: 120 },
        { field: 'Status', headerName: 'Status', width: 120 },
        {
            field: 'View',
            headerName: 'View',
            width: 100,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <VisibilityIcon />
                    </Button>
                    {params.row.Status === 'Waiting' &&
                        <Button className='cell_btn' onClick={() => handleApproveEdit(params)}>
                            <CheckCircleOutlineIcon />
                        </Button>}
                </>
            ),
        },

    ];

    let dynamicColumns1 = [
    { field: 'Item_Code', headerName: 'Item Code', width: 120 },
    { field: 'Item_Name', headerName: 'Item Name', width: 130 },
    { field: 'Generic_Name', headerName: 'Generic Name', width: 130 },
    { field: 'Strength', headerName: 'Strength', width: 80 },
    { field: 'UOM', headerName: 'UOM', width: 60 },
    { field: 'Pack_type', headerName: 'Pack Type', width: 100 },
    { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 80 },
    { field: 'Order_Quantity', headerName: 'Ordered Quantity', width: 80 },
    { field: 'Approved_Quantity', headerName: 'Approved Quantity', width: 80 },
    { field: 'Status', headerName: 'Status', width: 80 },
    {
        field: 'View',
        headerName: 'Action',
        width: 100,
        renderCell: (params) => (
            params.row.Status === "Waiting" ? (
                <>
                    <Button className='cell_btn' onClick={() => handleApproveEditIndent(params)}>
                        <EditIcon />
                    </Button>
                    <Button className='cell_btn' onClick={() => handleRejectEdit(params)}>
                        <DeleteForeverIcon />
                    </Button>
                </>
            ) : (
                'Action Completed'
            )
        ),
    },
];

// Remove 'Strength' and 'UOM' columns based on the condition
if (Productype === 'NonMedicalConsumable' || Productype === 'NonMedicalNonConsumable') {
    dynamicColumns1 = dynamicColumns1.filter(column => column.field !== 'Strength' && column.field !== 'UOM');
}

    
    const handleApproveEditIndent = (params) => {

        if (params.row.Status !== "Rejected") {

            const newApprovedQuantity = parseFloat(prompt('Enter Approved Quantity:', params.row.Approved_Quantity));

            if (!isNaN(newApprovedQuantity) && newApprovedQuantity <= params.row.Order_Quantity) {
                // Update the Approved_Quantity and Status
                params.row.Approved_Quantity = newApprovedQuantity;
                const data = {
                    Productype: Productype,
                    index: SelectedRowss,
                    Item_Code: params.row.Item_Code,
                    Approved_Quantity: params.row.Approved_Quantity,
                    Status: params.row.Status,

                }
                axios.post(`https://clinic.vesoftometic.co.in/IndentRaise/Reject_the_indent`, data)
                    .then((response) => {
                        console.log(response.data)
                        setSelectedShow1(!selectedShow1)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                // params.row.Status = 'Approved';
                // Add code to update the data in your backend/database
            } else {
                alert('Invalid Approved Quantity. Please enter a valid number Less than or equal Order Quantity.');
            }
        } else {
            alert('The selected Item is Rejected')
        }
    };
    const handleRejectEdit = (params) => {
        if (params.row.Status !== "Rejected") {
            const conform = window.confirm('Are you sure ,want to reject the Item')
            if (conform) {
                params.row.Approved_Quantity = 0;
                params.row.Status = 'Rejected';
                console.log(params.row)
                const data = {
                    Productype: Productype,
                    index: SelectedRowss,
                    Item_Code: params.row.Item_Code,
                    Approved_Quantity: params.row.Approved_Quantity,
                    Status: params.row.Status,

                }
                axios.post(`https://clinic.vesoftometic.co.in/IndentRaise/Reject_the_indent`, data)
                    .then((response) => {
                        console.log(response.data)
                        setSelectedShow1(!selectedShow1)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            }

        } else {
            alert('The selected Item is Already Rejected')
        }
    };
    const dynamicColumns2 = [
        { field: 'Item_Code', headerName: 'Item Code', width: 150 },
        { field: 'Item_Name', headerName: 'Item Name', width: 150 },
        { field: 'Pack_type', headerName: 'Pack Type', width: 120 },
        { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 120 },
        { field: 'Order_Quantity', headerName: 'Ordered Quantity', width: 120 },
        { field: 'Approved_Quantity', headerName: 'Approved Quantity', width: 120 },
        { field: 'Status', headerName: 'Status', width: 120 },
        {
            field: 'View',
            headerName: 'Action',
            width: 140,
            renderCell: (params) => (
                params.row.Status === "Waiting" ? (
                    <>
                        <Button className='cell_btn' onClick={() => handleApproveEditIndent(params)}>
                            <EditIcon />
                        </Button>
                        <Button className='cell_btn' onClick={() => handleRejectEdit(params)}>
                            <DeleteForeverIcon />
                        </Button>
                    </>
                ) : (
                    'Action Completed'
                )
            ),
        }

    ]




    return (
        <>
      <div className="appointment">
        <div className="h_head h_head_h_2">
                    <h4>Indent Appove List</h4>
                    <div className="doctor_select_1 selt-dctr-nse vcxw2er">
                        <label htmlFor="Calender"> Product Type :</label>
                        <select className='Product_Master_div_select_opt' value={Productype} onChange={(e) => { setProductype(e.target.value) }}>
                            <option value=''>Select</option>
                            <option value="MedicalConsumable">Medical Consumable</option>
                            <option value="MedicalNonConsumable">
                                Medical Non-Consumable
                            </option>
                            <option value="NonMedicalConsumable">Non MedicalConsumable</option>
                            <option value="NonMedicalNonConsumable">Non MedicalNon-Consumable</option>
                            <option value="Surgical">Surgical</option>
                            <option value='Assets'>Assets</option>
                            <option value='Stationary'>Stationary</option>
                            <option value='Non_Stationary'>Non Stationary</option>
                        </select>
                    </div>

                </div>
                <div className="con_1 ">
          <div className="inp_1">
                        <label htmlFor="input">Invoice No <span>:</span></label>
                        <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Invoice No' />
                    </div>
                    <div className="inp_1">
                        <label htmlFor="input">Status <span>:</span></label>
                        <select
                            value={statustype}
                            onChange={(e) => setstatustype(e.target.value)}
                        >
                            <option value='Waiting'>Waiting</option>
                            <option value='Pending'>Pending</option>
                            <option value='Rejected'>Rejected</option>
                            <option value='Issued'>Issued</option>
                        </select>
                    </div>

                </div>
                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                    {selectedShow && (
                        <div
                            className={
                                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
                            } onClick={() => {
                                setSelectedShow(false);
                            }}

                        >
                            <div className="newwProfiles" style={{ padding: '10px', boxSizing: 'border-box' }} onClick={(e) => e.stopPropagation()}>
                                <div className="appointment">
                                    <div className="calendar_head">
                                        <h3>{Productype}List</h3>
                                    </div>
                                    <div className="con_1 ">




                                    </div>
                                    <div className='IP_grid'>
                                        <ThemeProvider theme={theme}>
                                            <div className='IP_grid_1'>
                                                <DataGrid
                                                    rows={Rowdata.slice(page1 * pageSize1, (page1 + 1) * pageSize1)} // Display only the current page's data
                                                    columns={(Productype === 'MedicalConsumable' ||
                                                    Productype === 'MedicalNonConsumable' ||
                                                    Productype === 'NonMedicalConsumable' ||
                                                    Productype === 'NonMedicalNonConsumable' ||
                                                    Productype === 'Surgical' ) ? dynamicColumns1 : dynamicColumns2} // Use dynamic columns here

                                                    pageSize={10}
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: {
                                                                pageSize: 10,
                                                            },
                                                        },
                                                    }}
                                                    pageSizeOptions={[10]}
                                                    onPageChange={handlePageChange1}
                                                    hideFooterPagination
                                                    hideFooterSelectedRowCount
                                                    className='data_grid'
                                                />
                                                {showdown1 > 0 && Rowdata.length > 10 && (
                                                    <div className='IP_grid_foot'>
                                                        <button
                                                            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                                            disabled={page === 0}
                                                        >
                                                            Previous
                                                        </button>
                                                        Page {page1 + 1} of {totalPages1}
                                                        <button
                                                            onClick={() =>
                                                                setPage((prevPage) => Math.min(prevPage + 1, totalPages1 - 1))
                                                            }
                                                            disabled={page1 === totalPages1 - 1}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </ThemeProvider>
                                        {showdown1 !== 0 && Rowdata.length !== 0 ? (
                                            ''
                                        ) : (
                                            <div className='IP_norecords'>
                                                <span>No Records Found</span>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                                        <button
                                            className="closeicon-cs"
                                            onClick={() => {
                                                setSelectedShow(false);
                                            }}

                                        >
                                            close
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
