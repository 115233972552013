import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { saveAs } from "file-saver";
import { ToastContainer } from "react-toastify";

const DayBook = () => {
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");

  console.log(formattedDate);

  const [SearchformData, setSearchformData] = useState({
    Location: "",
    VoucherType: "",
    VoucherStatus: "",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });

  const [Location, setLocation] = useState([]);

  const [Totaldata, setTotaldata] = useState({
    DebitTotal: "",
    CreditTotal: "",
  });

  const [Vouchersarray, setVouchersarray] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      if (value === "Current") {
        setSearchformData({
          ...SearchformData,
          CurrentDate: formattedDate,
          [name]: value,
        });
      } else {
        setSearchformData({
          ...SearchformData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else {
      setSearchformData({
        ...SearchformData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");
    setSearchformData((prev) => ({
      ...prev,
      date: formattedDate,
    }));
    axios
      .get(`https://clinic.vesoftometic.co.in/usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data.map((p) => p.location_name);

        console.log([...data, "CentralStore"]);
        setLocation([...data, "CentralStore"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handletoSearch = () => {
    const params = {
      Location: SearchformData.Location,
      VoucherType: SearchformData.VoucherType,
      VoucherStatus: SearchformData.VoucherStatus,
      DateType: SearchformData.DateType,
      CurrentDate: SearchformData.CurrentDate,
      FromDate: SearchformData.FromDate,
      ToDate: SearchformData.ToDate,
    };

    let requiredFields = [];
    if (SearchformData.DateType === "Customize") {
      requiredFields = ["Location", "DateType", "FromDate", "ToDate"];
    } else {
      requiredFields = ["Location", "DateType"];
    }
    const existingItem = requiredFields.filter(
      (field) => !SearchformData[field]
    );
    if (existingItem.length > 0) {
      alert(`Please Fill the RequiredFields : ${existingItem.join(",")} `);
    } else {
      axios
        .get(
          `https://clinic.vesoftometic.co.in/FinanceMagement/get_Voucher_Entry_details_Daybook`,
          { params }
        )
        .then((response) => {
          console.log("111", response.data);

          const Gdata = response.data.data;

          const getalldata = Gdata.map((p, index) => ({
            id: index + 1,
            ...p,
          }));

          const DTotal = getalldata.reduce(
            (total, ele) => total + parseFloat(ele.Debitmethod || 0),
            0
          );

          const CTotal = getalldata.reduce(
            (total, ele) => total + parseFloat(ele.Creditmethod || 0),
            0
          );

          setVouchersarray(getalldata);

          setTotaldata((prev) => ({
            ...prev,
            DebitTotal: DTotal.toFixed(2),
            CreditTotal: CTotal.toFixed(2),
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleExportToExcel = () => {
    if (Vouchersarray.length !== 0) {
      const columns = [
        { dataKey: "id", header: "S.NO" },
        { dataKey: "Voucher_date", header: "Date" },
        { dataKey: "Voucher_Number", header: "Invoice Number" },
        { dataKey: "Voucher_Type", header: "Voucher Type" },
        { dataKey: "Ledger_Name", header: "Particulars" },
        { dataKey: "Status", header: "Status" },
        { dataKey: "Debitmethod", header: "Debit" },
        { dataKey: "Creditmethod", header: "Credit" },
      ];

      const header = columns.map((col) => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...Vouchersarray.map((row) => columns.map((col) => row[col.dataKey])),
        "", // Empty row for spacing
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, "DayBookReport.csv");
    } else {
      alert("No Data to Save");
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Day Book</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="">
            Location <span>:</span>
          </label>
          <select
            name="Location"
            value={SearchformData.Location}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {Location.map((p) => (
              <option key={p} value={p}>
                {p.toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Voucher Type<span>:</span>
          </label>
          <select
            name="VoucherType"
            value={SearchformData.VoucherType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Contra">Contra</option>
            <option value="Payment">Payment</option>
            <option value="Receipt">Receipt</option>
            <option value="Journal">Journal</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Voucher Status<span>:</span>
          </label>
          <select
            name="VoucherStatus"
            value={SearchformData.VoucherStatus}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Available">Available</option>
            <option value="Canceled">Canceled</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Date Type <span>:</span>
          </label>
          <select
            name="DateType"
            value={SearchformData.DateType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Current">Current Date</option>
            <option value="Customize">Customize</option>
          </select>
        </div>

        {SearchformData.DateType === "Current" && (
          <div className="RegisForm_1">
            <label htmlFor="">
              Current Date <span>:</span>
            </label>
            <input
              type="date"
              name="CurrentDate"
              value={SearchformData.CurrentDate}
              onChange={handleChange}
            />
          </div>
        )}
        {SearchformData.DateType === "Customize" && (
          <>
            <div className="RegisForm_1">
              <label htmlFor="">
                From Date <span>:</span>
              </label>
              <input
                type="date"
                name="FromDate"
                value={SearchformData.FromDate}
                onChange={handleChange}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="">
                To Date <span>:</span>
              </label>
              <input
                type="date"
                name="ToDate"
                value={SearchformData.ToDate}
                onChange={handleChange}
              />
            </div>
          </>
        )}
      </div>

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handletoSearch}>
          Search
        </button>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Date</th>
              <th>Voucher Number</th>
              <th>Voucher Type</th>
              <th>Particulars</th>
              <th>Status</th>
              <th>Debit Amount</th>
              <th>Credit Amount</th>
            </tr>
          </thead>
          {Vouchersarray.length !== 0 && (
            <tbody>
              {Vouchersarray.map((row, index) => (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.Voucher_date}</td>
                  <td>{row.Voucher_Number}</td>
                  <td>{row.Voucher_Type}</td>
                  <td>{row.Ledger_Name}</td>
                  <td>{row.Status}</td>
                  <td>{row.Debitmethod}</td>
                  <td>{row.Creditmethod}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{Totaldata.DebitTotal}</td>
                <td>{Totaldata.CreditTotal}</td>
              </tr>
            </tbody>
          )}
        </table>
        <ToastContainer />
      </div>

      {Vouchersarray.length !== 0 && (
        <div className="PrintExelPdf">
          <button onClick={handleExportToExcel}>Save Exel</button>
        </div>
      )}
    </div>
  );
};

export default DayBook;
