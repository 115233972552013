import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';



const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const AddvaceStatus = () => {
  const userRecord=useSelector(state=>state.userRecord?.UserData)
  const [openModal, setOpenModal] = useState(false);

  
  const columns = [

    { field: 'employeeid', headerName: 'Employee ID', width: 100 },
    { field: 'employeename', headerName: 'Employee Name', width: 150 },
    // { field: 'designation', headerName: 'Designation', width: 100 },
    { field: 'reqdate', headerName: 'Request Date', width: 120 },
    { field: 'reqamount', headerName: 'Request Amount', width: 120 },
    { field: 'reason', headerName: 'Reason', width: 150 },
    
    { field: 'status', headerName: 'Status', width: 130 },
   
  ];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = () => {
    const employeeId = userRecord.EmployeeId;
    fetch(`https://clinic.vesoftometic.co.in/HRmanagement/get_req_advance_register_recent?Employeeid=${employeeId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            designation: userdata.Designation,
            reqdate: userdata.RequestDate,
            reqamount: userdata.RequestAmount,
            reason: userdata.Reason,
            installment: userdata.RepaymentDue,
            status: userdata.Status,
            rejectreason: userdata.RejectReason,
            approvedby: userdata.ApproverName,
            issueddate: userdata.IssuedDate,
            issuedby: userdata.IssueverName
          }));
          setRows(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showdown = rows.length;


 
  
  


  return (
    <div>
    
      <ThemeProvider theme={theme}>
        <div style={{  width: '100%' }}>
          <div className="grid_1" style={{ overflowX: 'auto', maxWidth: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              hideFooter={true}
              isCellEditable={(params) => params.row.id !== 1}
              className=' data_grid'
            />
          </div>
        </div>
      </ThemeProvider>
      {showdown !== 0 && rows.length !== 0 ? (
          ''
        ) : (
          <div className='IP_norecords'>
            <span>No Records Found</span>
          </div>
        )}
    </div>
  );
};

export default AddvaceStatus;
