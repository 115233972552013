import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RateCardMaster.css";
import EditIcon from "@mui/icons-material/Edit";
import { saveAs } from "file-saver";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function RateCardMaster() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const [expanded, setExpanded] = useState(false);
  const [doctorOptions, setDoctorOptions] = useState([]);

  const [ServiceData, setServiceData] = useState([]);
  const [ServiceEdit, setServiceEdit] = useState(false);
  const [editedServiceIndex, setEditedServiceIndex] = useState(null);

  const [clientData, setClientData] = useState([]);
  const [clientEdit, setClientEdit] = useState(false);
  const [editedClientIndex, setEditedClientIndex] = useState(null);

  const [InsuranceData, setInsuranceData] = useState([]);
  const [InsuranceEdit, setInsuranceEdit] = useState(false);
  const [editedInsuranceIndex, setEditedInsuranceIndex] = useState(null);

  const [LabData, setLabData] = useState([]);
  const [LabEdit, setLabEdit] = useState(false);
  const [editedLabIndex, setEditedLabIndex] = useState(null);
  const [SelectedFile, setSelectedFile] = useState(null);
  const [formData1, setFormData1] = useState({
    ServiceType: "",
    DoctorName: "",
    ProcedureName: "",
    GeneralCharge: "",
    SpecialCharge: "",
    GSTCharge: "",
    VIPCharge: "",
  });
  const [formData2, setFormData2] = useState({
    ServiceType: "",
    DoctorName: "",
    ProcedureName: "",
    ClientName: "",
    Charge: "",
    GSTCharge: "",
  });
  const [formData3, setFormData3] = useState({
    ServiceType: "",
    DoctorName: "",
    ProcedureName: "",
    InsuranceName: "",
    GSTCharge: "",
    Charge: "",
  });
  const [formData4, setFormData4] = useState({
    TestName: "",
    Charge: "",
    GSTCharge: "",
  });

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleFormChange1 = (e) => {
    const { name, value } = e.target;

    if (name === "ServiceType") {
      if (value === "DoctorConsultation") {
        setFormData1((prevFormData) => ({
          ...prevFormData,
          ServiceType: value,
          ProcedureName: "Null",
          DoctorName: "",
        }));
      } else if (value === "Procedure") {
        setFormData1((prevFormData) => ({
          ...prevFormData,
          ServiceType: value,
          ProcedureName: "",
          DoctorName: "Null",
        }));
      }
    } else {
      setFormData1((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const handleFormChange2 = (e) => {
    const { name, value } = e.target;
    if (name === "ServiceType") {
      if (value === "DoctorConsultation") {
        setFormData2((prevFormData) => ({
          ...prevFormData,
          ServiceType: value,
          ProcedureName: "Null",
          DoctorName: "",
        }));
      } else if (value === "Procedure") {
        setFormData2((prevFormData) => ({
          ...prevFormData,
          ServiceType: value,
          ProcedureName: "",
          DoctorName: "Null",
        }));
      }
    } else {
      setFormData2((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const handleFormChange3 = (e) => {
    const { name, value } = e.target;
    if (name === "ServiceType") {
      if (value === "DoctorConsultation") {
        setFormData3((prevFormData) => ({
          ...prevFormData,
          ServiceType: value,
          ProcedureName: "Null",
          DoctorName: "",
        }));
      } else if (value === "Procedure") {
        setFormData3((prevFormData) => ({
          ...prevFormData,
          ServiceType: value,
          ProcedureName: "",
          DoctorName: "Null",
        }));
      }
    } else {
      setFormData3((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const handleFormChange4 = (e) => {
    const { name, value } = e.target;
    setFormData4((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddService = () => {
    if (
      formData1.ServiceType &&
      formData1.DoctorName &&
      formData1.ProcedureName &&
      formData1.GeneralCharge &&
      formData1.SpecialCharge &&
      formData1.GSTCharge &&
      formData1.VIPCharge
    ) {
      setServiceData((prevClientData) => [...prevClientData, formData1]);

      setFormData1({
        ServiceType: "",
        DoctorName: "",
        ProcedureName: "",
        GeneralCharge: "",
        SpecialCharge: "",
        GSTCharge: "",
        VIPCharge: "",
      });

      // axios.post('https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_Service_Charge', formData1)
      //   .then((response) => {
      //     console.log('Service added successfully:', response.data);
      //   })
      //   .catch((error) => {
      //     console.error('Error adding service:', error);
      //   });

      setServiceEdit(false);
    } else {
      userwarn("Please fill in all the fields");
    }
  };

  const handleAddClient = () => {
    if (
      formData2.DoctorName &&
      formData2.ProcedureName &&
      formData2.ClientName &&
      formData2.ServiceType &&
      formData2.Charge
    ) {
      setClientData((prevClientData) => [...prevClientData, formData2]);

      setFormData2({
        ServiceType: "",
        DoctorName: "",
        ProcedureName: "",
        ClientName: "",
        Charge: "",
        GSTCharge: "",
      });

      console.log(formData2);
      setClientEdit(false);
    } else {
      userwarn("Please fill in all the fields");
    }
  };
  const handleAddInsurance = () => {
    if (
      formData3.DoctorName &&
      formData3.ProcedureName &&
      formData3.InsuranceName &&
      formData3.ServiceType &&
      formData3.Charge
    ) {
      setInsuranceData((prevClientData) => [...prevClientData, formData3]);

      setFormData3({
        ServiceType: "",
        DoctorName: "",
        ProcedureName: "",
        InsuranceName: "",
        Charge: "",
        GSTCharge: "",
      });
      console.log(formData3);
      setInsuranceEdit(false);
    } else {
      userwarn("Please fill in all the fields");
    }

    // axios.post('https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_insurance_Charge', formData3)
    //   .then((response) => {
    //     console.log(response.data)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  };
  const handleAddLab = () => {
    if (formData4.TestName && formData4.Charge) {
      setLabData((prevClientData) => [...prevClientData, formData4]);

      setFormData4({
        TestName: "",
        Charge: "",
        GSTCharge: "",
      });
      console.log(formData4);

      setLabEdit(false);
    } else {
      userwarn("Please fill in all the fields");
    }

    // axios.post('https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_lab_Charge', formData4)
    //   .then((response) => {
    //     console.log('Service added successfully:', response.data);
    //   })
    //   .catch((error) => {
    //     console.error('Error adding service:', error);
    //   });
  };
  const handleUpdateService = () => {
    if (
      formData1.ServiceType &&
      formData1.DoctorName &&
      formData1.ProcedureName &&
      formData1.GeneralCharge &&
      formData1.SpecialCharge &&
      formData1.VIPCharge
    ) {
      setServiceData((prevClientData) => {
        const updatedClientData = [...prevClientData];
        updatedClientData[editedServiceIndex] = formData1;
        return updatedClientData;
      });

      setFormData1({
        ServiceType: "",
        DoctorName: "",
        ProcedureName: "",
        GeneralCharge: "",
        SpecialCharge: "",
        GSTCharge: "",
        VIPCharge: "",
      });
      setServiceEdit(false);
    } else {
      userwarn("Please fill in all the fields");
    }

    // axios.post('https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_Service_Charge', formData1)
    //   .then((response) => {
    //     console.log('Service added successfully:', response.data);
    //   })
    //   .catch((error) => {
    //     console.error('Error adding service:', error);
    //   });
  };
  const handleUpdateClient = () => {
    if (formData2.ClientName && formData2.ServiceType && formData2.Charge) {
      setClientData((prevClientData) => {
        const updatedClientData = [...prevClientData];
        updatedClientData[editedClientIndex] = formData2;
        return updatedClientData;
      });

      setFormData2({
        ServiceType: "",
        DoctorName: "",
        ProcedureName: "",
        ClientName: "",
        Charge: "",
        GSTCharge: "",
      });
      setClientEdit(false);
    } else {
      userwarn("Please fill in all the fields");
    }
    // axios.post('https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_client_Charge', formData2)
    //   .then((response) => {
    //     console.log(response.data)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  };
  const handleUpdateInsurance = () => {
    if (formData3.InsuranceName && formData3.ServiceType && formData3.Charge) {
      setInsuranceData((prevClientData) => {
        const updatedClientData = [...prevClientData];
        updatedClientData[editedInsuranceIndex] = formData3;
        return updatedClientData;
      });

      setFormData3({
        ServiceType: "",
        DoctorName: "",
        ProcedureName: "",
        InsuranceName: "",
        Charge: "",
        GSTCharge: "",
      });
      setInsuranceEdit(false);
    } else {
      userwarn("Please fill in all the fields");
    }

    // axios.post('https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_insurance_Charge', formData3)
    //   .then((response) => {
    //     console.log(response.data)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  };
  const handleUpdateLab = () => {
    if (formData4.TestName && formData4.ChargeType && formData4.Charge) {
      setLabData((prevClientData) => {
        const updatedClientData = [...prevClientData];
        updatedClientData[editedLabIndex] = formData4;
        return updatedClientData;
      });

      setFormData4({
        TestName: "",
        Charge: "",
        GSTCharge: "",
      });
      setLabEdit(false);
    } else {
      userwarn("Please fill in all the fields");
    }

    // axios.post('https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_lab_Charge', formData4)
    //   .then((response) => {
    //     console.log('Service added successfully:', response.data);
    //   })
    //   .catch((error) => {
    //     console.error('Error adding service:', error);
    //   });
  };

  const handleEditService = (index) => {
    setFormData1(ServiceData[index]);
    setEditedServiceIndex(index);
    setServiceEdit(true);
  };
  const handleEditClient = (index) => {
    setFormData2(clientData[index]);
    setEditedClientIndex(index);
    setClientEdit(true);
  };
  const handleEditInsurance = (index) => {
    setFormData3(InsuranceData[index]);
    setEditedInsuranceIndex(index);
    setInsuranceEdit(true);
  };
  const handleEditLab = (index) => {
    setFormData4(LabData[index]);
    setEditedLabIndex(index);
    setLabEdit(true);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    const location = userRecord?.location;
    axios
      .get(
        `https://clinic.vesoftometic.co.in/usercontrol/get_doctor_info?location=${location}`
      )
      .then((response) => {
        const fetchedDoctors = response.data.map((doctor, index) => ({
          id: index,
          name: doctor,
        }));
        setDoctorOptions(fetchedDoctors);
      })
      .catch((error) => {
        console.error("Error fetching doctor information:", error);
      });
  }, [userRecord]);

  console.log(formData2);
  console.log(clientData);

  const handleSubmit = () => {
    if (ServiceData.length !== 0) {
      const nnn = ServiceData.map((row) => ({
        ...row,
        Location: userRecord?.location,
      }));
      axios
        .post(
          "https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_Service_Charge",
          nnn
        )
        .then((response) => {
          console.log("Service added successfully:", response.data);
          successMsg("Saved Successfully");
          setServiceData([]);
        })
        .catch((error) => {
          console.error("Error adding service:", error);
        });
    }
    if (clientData.length !== 0) {
      const mmm = clientData.map((row) => ({
        ...row,
        Location: userRecord?.location,
      }));

      axios
        .post(
          "https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_client_Charge",
          mmm
        )
        .then((response) => {
          console.log(response.data);
          successMsg("Saved Successfully");
          setClientData([]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (InsuranceData.length !== 0) {
      const insdata = InsuranceData.map((row) => ({
        ...row,
        Location: userRecord?.location,
      }));
      axios
        .post(
          "https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_insurance_Charge",
          insdata
        )
        .then((response) => {
          console.log(response.data);
          setInsuranceData([]);
          successMsg("Saved Successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (LabData.length !== 0) {
      const LabDatas = LabData.map((row) => ({
        ...row,
        Location: userRecord?.location,
      }));

      axios
        .post(
          "https://clinic.vesoftometic.co.in/usercontrol/insert_RateCard_lab_Charge",
          LabDatas
        )
        .then((response) => {
          console.log(response.data);
          setLabData([]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleFileChange = (event) => {
    setSelectedFile(null);
    const { name } = event.target;
    setSelectedFile(event.target.files[0]);
    console.log("Service file selected:", event.target.files[0]);

    // Additional handling based on the name attribute
    if (name === "Service") {
      // Handle Service file
      console.log("Service file selected:", event.target.files[0]);
    } else if (name === "Client") {
      // Handle Client file
      console.log("Client file selected:", event.target.files[0]);
    } else if (name === "Insurance") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    }
  };

  const handleCsvupload = (type) => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);

    if (SelectedFile) {
      if (type === "Service") {
        axios
          .post(
            `https://clinic.vesoftometic.co.in/usercontrol/post_RateCard_Service_Charge_csvto_sql_link_route`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (type === "Client") {
        axios
          .post(
            `https://clinic.vesoftometic.co.in/usercontrol/post_RateCard_Client_Charge_csvto_sql_link_route`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (type === "Insurance") {
        axios
          .post(
            `https://clinic.vesoftometic.co.in/usercontrol/post_RateCard_Insurance_Charge_csvto_sql_link_route`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  // const handleExportToExcel = () => {
  //   if(InsuranceData.length !==0){
  //       const columns = [
  //           { header: 'Service Type', dataKey: 'ServiceType' },
  //           { header: 'DoctorName', dataKey: 'DoctorName' },
  //           { header: 'ProcedureName', dataKey: 'ProcedureName' },
  //           // { header: 'GeneralCharge', dataKey: 'GeneralCharge' },
  //           // { header: 'SpecialCharge', dataKey: 'SpecialCharge' },
  //           // { header: 'VIPCharge', dataKey: 'VIPCharge' },
  //           { header: 'InsuranceName', dataKey: 'InsuranceName' },
  //           { header: 'Charge', dataKey: 'Charge' },
  //         ];

  //         const header = columns.map(col => col.header);

  //         const csv = [
  //           '\ufeff' + header.join(","), // BOM + header row first
  //           ...InsuranceData.map(row => columns.map(col => row[col.dataKey])),
  //         ].join("\r\n");

  //         var data = new Blob([csv], { type: "text/csv" });
  //         saveAs(data, "InsuranceReport.csv");
  //   }else{
  //       alert('No Data to Save')
  //   }
  // };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Rate Card Charges</h4>
      </div>
  
      <Accordion
        expanded={
          expanded !== "panel2" &&
          expanded !== "panel3" &&
          expanded !== "panel4"
        }
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div sx={{ width: "45%", flexShrink: 0 }}>Service Charges</div>
        </AccordionSummary>
        <AccordionDetails>
          <div sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Service Type<span>:</span>{" "}
                </label>
                <select
                  name="ServiceType"
                  value={formData1.ServiceType}
                  onChange={handleFormChange1}
                >
                  <option value="">Select</option>
                  <option value="DoctorConsultation">
                    Doctor Consultation
                  </option>
                  <option value="Procedure">Procedure</option>
                </select>
              </div>
              {formData1.ServiceType === "Procedure" ? (
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Procedure Name<span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                
                    name="ProcedureName"
                    required
                    value={formData1.ProcedureName}
                    onChange={handleFormChange1}
                  />
                </div>
              ) : (
                <div className="RegisForm_1">
                  <label htmlFor="Title">
                    Doctor Name <span>:</span>
                  </label>
                  <select
                    name="DoctorName"
                    value={formData1.DoctorName}
                    onChange={handleFormChange1}
                  >
                    <option value="">Select</option>
                    {doctorOptions.map((doctor) => (
                      <option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="RegisForm_1">
                <label>
                  {" "}
                  General Charge<span>:</span>{" "}
                </label>
                <input
                  type="number"
           
                  name="GeneralCharge"
                  required
                  value={formData1.GeneralCharge}
                  onChange={handleFormChange1}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  {" "}
                  Special Charge <span>:</span>{" "}
                </label>
                <input
                  type="number"
               
                  name="SpecialCharge"
                  required
                  value={formData1.SpecialCharge}
                  onChange={handleFormChange1}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  {" "}
                  VIP Charge <span>:</span>{" "}
                </label>
                <input
                  type="number"
                 
                  name="VIPCharge"
                  required
                  value={formData1.VIPCharge}
                  onChange={handleFormChange1}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  GST Charge <span>:</span>{" "}
                </label>
                <input
                  type="number"
               
                  name="GSTCharge"
                  required
                  value={formData1.GSTCharge}
                  onChange={handleFormChange1}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  id="Servicechoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="Servicechoose" 
                 className="RegisterForm_1_btns choose_file_update">
                  Choose File
                </label>

                <button
                   className="RegisterForm_1_btns choose_file_update"
                  onClick={() => handleCsvupload("Service")}
                >
                  Upload
                </button>
              </div>
            </div>
            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={ServiceEdit ? handleUpdateService : handleAddService}
              >
                {ServiceEdit ? "Update" : "Add"}
              </button>
            </div>
            <div className="Selected-table-container">
              <div className="selected-medicine-table2">
                <table>
                  <thead>
                    <tr>
                      <th id="slectbill_ins">Service Type</th>
                      <th id="slectbill_ins">Doctor Name</th>
                      <th id="slectbill_ins">Procedure Name</th>
                      <th id="slectbill_ins">General Charge</th>
                      <th id="slectbill_ins">Special Charge</th>
                      <th id="slectbill_ins">GST Charge</th>
                      <th id="slectbill_ins">VIP Charge</th>
                      <th id="slectbill_ins">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ServiceData.map((client, index) => (
                      <tr key={index}>
                        <td>{client.ServiceType}</td>
                        <td>{client.DoctorName}</td>
                        <td>{client.ProcedureName}</td>
                        <td>{client.GeneralCharge}</td>
                        <td>{client.SpecialCharge}</td>
                        <td>{client.GSTCharge}</td>
                        <td>{client.VIPCharge}</td>
                        <td>
                          <button
                            onClick={() => handleEditService(index)}
                            className="ratecarededitbutton"
                          >
                            <EditIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel1bh-header"
        >
          <div sx={{ width: "45%", flexShrink: 0 }}>Client Charges</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Service Type<span>:</span>{" "}
                </label>
                <select
                  name="ServiceType"
                  value={formData2.ServiceType}
                  onChange={handleFormChange2}
                >
                  <option value="">Select</option>
                  <option value="DoctorConsultation">
                    Doctor Consultation
                  </option>
                  <option value="Procedure">Procedure</option>
                </select>
              </div>
              {formData2.ServiceType === "Procedure" ? (
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Procedure Name<span>:</span>{" "}
                  </label>
                  <input
                    type="text"
              
                    name="ProcedureName"
                    required
                    value={formData2.ProcedureName}
                    onChange={handleFormChange2}
                  />
                </div>
              ) : (
                <div className="RegisForm_1">
                  <label htmlFor="Title">
                    Doctor Name <span>:</span>
                  </label>
                  <select
                    name="DoctorName"
                    value={formData2.DoctorName}
                    onChange={handleFormChange2}
                  >
                    <option value="">Select</option>
                    {doctorOptions.map((doctor) => (
                      <option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="RegisForm_1">
                <label htmlFor="Title">
                  Client Name <span>:</span>
                </label>
                <input
                  type="text"
           
                  name="ClientName"
                  required
                  value={formData2.ClientName}
                  onChange={handleFormChange2}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  {" "}
                  Charge <span>:</span>{" "}
                </label>
                <input
                  type="number"
            
                  name="Charge"
                  required
                  value={formData2.Charge}
                  onChange={handleFormChange2}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  GST Charge <span>:</span>{" "}
                </label>
                <input
                  type="number"
     
                  name="GSTCharge"
                  required
                  value={formData2.GSTCharge}
                  onChange={handleFormChange2}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  id="Clientchoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="Clientchoose" 
                 className="RegisterForm_1_btns choose_file_update">
                  Choose File
                </label>
                <button
           className="RegisterForm_1_btns choose_file_update"
                  onClick={() => handleCsvupload("Client")}
                >
                  Upload
                </button>
              </div>
            </div>

            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={clientEdit ? handleUpdateClient : handleAddClient}
              >
                {clientEdit ? "Update" : "Add"}
              </button>
            </div>
            <div className="Selected-table-container">
              <div className="selected-medicine-table2">
                <table>
                  <thead>
                    <tr>
                      <th id="slectbill_ins">Service Type</th>
                      <th id="slectbill_ins">Doctor Name</th>
                      <th id="slectbill_ins">Procedure Name</th>
                      <th id="slectbill_ins">Client Name</th>
                      <th id="slectbill_ins">Charge</th>
                      <th id="slectbill_ins">GST Charge</th>
                      <th id="slectbill_ins">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientData.map((client, index) => (
                      <tr key={index}>
                        <td>{client.ServiceType}</td>
                        <td>{client.DoctorName}</td>
                        <td>{client.ProcedureName}</td>
                        <td>{client.ClientName}</td>
                        <td>{client.Charge}</td>
                        <td>{client.GSTCharge}</td>
                        <td>
                          <button
                            onClick={() => handleEditClient(index)}
                            className="ratecarededitbutton"
                          >
                            <EditIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel1bh-header"
        >
          <div sx={{ width: "45%", flexShrink: 0 }}>Insurance Charges</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Service Type<span>:</span>{" "}
                </label>
                <select
                  name="ServiceType"
                  value={formData3.ServiceType}
                  onChange={handleFormChange3}
                >
                  <option value="">Select</option>
                  <option value="DoctorConsultation">
                    Doctor Consultation
                  </option>
                  <option value="Procedure">Procedure</option>
                </select>
              </div>

              {formData3.ServiceType === "Procedure" ? (
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Procedure Name<span>:</span>{" "}
                  </label>
                  <input
                    type="text"
              
                    name="ProcedureName"
                    required
                    value={formData3.ProcedureName}
                    onChange={handleFormChange3}
                  />
                </div>
              ) : (
                <div className="RegisForm_1">
                  <label htmlFor="Title">
                    Doctor Name <span>:</span>
                  </label>
                  <select
                    name="DoctorName"
                    value={formData3.DoctorName}
                    onChange={handleFormChange3}
                  >
                    <option value="">Select</option>
                    {doctorOptions.map((doctor) => (
                      <option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="RegisForm_1">
                <label htmlFor="Title">
                  Insurance Name <span>:</span>
                </label>
                <input
                  type="text"
               
                  name="InsuranceName"
                  required
                  value={formData3.InsuranceName}
                  onChange={handleFormChange3}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  {" "}
                  Charge <span>:</span>{" "}
                </label>
                <input
                  type="number"
             
                  name="Charge"
                  required
                  value={formData3.Charge}
                  onChange={handleFormChange3}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  GST Charge <span>:</span>{" "}
                </label>
                <input
                  type="number"
      
                  name="GSTCharge"
                  required
                  value={formData3.GSTCharge}
                  onChange={handleFormChange3}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  id="Insurancechoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="Insurancechoose"
                  className="RegisterForm_1_btns choose_file_update"
                >
                  Choose File
                </label>
                <button
                className="RegisterForm_1_btns choose_file_update"
                  onClick={() => handleCsvupload("Insurance")}
                >
                  Upload
                </button>
              </div>
            </div>
            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={
                  InsuranceEdit ? handleUpdateInsurance : handleAddInsurance
                }
              >
                {InsuranceEdit ? "Update" : "Add"}
              </button>
            </div>
            <div className="Selected-table-container">
              <div className="selected-medicine-table2">
                <table>
                  <thead>
                    <tr>
                      <th id="slectbill_ins">Service Type</th>
                      <th id="slectbill_ins">Doctor Name</th>
                      <th id="slectbill_ins">Procedure Name</th>
                      <th id="slectbill_ins">Insurance Name</th>
                      <th id="slectbill_ins">Charge</th>
                      <th id="slectbill_ins">GST Charge</th>
                      <th id="slectbill_ins">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {InsuranceData.map((client, index) => (
                      <tr key={index}>
                        <td>{client.ServiceType}</td>
                        <td>{client.DoctorName}</td>
                        <td>{client.ProcedureName}</td>
                        <td>{client.InsuranceName}</td>
                        <td>{client.Charge}</td>
                        <td>{client.GSTCharge}</td>
                        <td>
                          <button
                            onClick={() => handleEditInsurance(index)}
                            className="ratecarededitbutton"
                          >
                            <EditIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel1bh-header"
        >
          <div sx={{ width: "45%", flexShrink: 0 }}>Lab Charges</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="Title">
                  Test Name <span>:</span>
                </label>
                <input
                  type="text"
                
                  name="TestName"
                  required
                  value={formData4.TestName}
                  onChange={handleFormChange4}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Charge <span>:</span>{" "}
                </label>
                <input
                  type="number"
          
                  name="Charge"
                  required
                  value={formData4.Charge}
                  onChange={handleFormChange4}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  {" "}
                  GST Charge <span>:</span>{" "}
                </label>
                <input
                  type="number"
              
                  name="GSTCharge"
                  required
                  value={formData4.GSTCharge}
                  onChange={handleFormChange4}
                />
              </div>
            </div>
            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={LabEdit ? handleUpdateLab : handleAddLab}
              >
                {InsuranceEdit ? "Update" : "Add"}
              </button>
            </div>
            <div className="Selected-table-container">
              <div className="selected-medicine-table2">
                <table>
                  <thead>
                    <tr>
                      <th id="slectbill_ins">Test Name</th>
                      <th id="slectbill_ins">Charge</th>
                      <th id="slectbill_ins">GST Charge</th>
                      <th id="slectbill_ins">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {LabData.map((client, index) => (
                      <tr key={index}>
                        <td>{client.TestName}</td>
                        <td>{client.GSTCharge}</td>
                        <td>{client.Charge}</td>
                        <td>
                          <button
                            onClick={() => handleEditLab(index)}
                            className="ratecarededitbutton"
                          >
                            <EditIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleSubmit}>
          Submit
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}