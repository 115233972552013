import React, { useEffect, useState } from "react";
import "./ProductMaster.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
function GeneralMaster() {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const selectedProductIndex = useSelector(
    (state) => state.userRecord?.selectedProductIndex
  );

  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();
  const [formDataProduct, setFormDataProduct] = useState({
    ProductCategory: "Assets",
    ProductType: "",
    ProductQuantity: "",
    ItemCode: "",
    ItemName: "",
    GenericName: "",
    Strength: "",
    UOM: "",
    ItemRateWithouttax: "",
    Status: "Active",
    createdby: userRecord?.username,
    Location: userRecord?.location,
  });

  console.log("qa", userRecord);

  useEffect(() => {
    setFormDataProduct((prevData) => ({
      ...prevData,
      ProductType: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
    }));
    setIsInactive(false);
  }, [formDataProduct.ProductCategory]);

  useEffect(() => {
    if (selectedProductIndex && selectedProductIndex !== null) {
      if (selectedProductIndex.Productype === "Assets") {
        axios
          .get(
            "https://clinic.vesoftometic.co.in/SupplierMaster/get_Assets_ProductMaster_data"
          )
          .then((response) => {
            // Handle the successful response here
            console.log(response.data);
            const responsedata = response.data;
            const selectedSupplier = responsedata.find(
              (p) => selectedProductIndex.ItemCode === p.ItemCode
            );
            setFormDataProduct((prev) => ({
              ...prev,
              ProductCategory: selectedProductIndex.Productype,
              ...selectedSupplier,
            }));

            if (selectedSupplier.Status !== "Active") {
              setIsInactive(true);
            } else {
              setIsInactive(false);
            }
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (selectedProductIndex.Productype === "Stationary") {
        axios
          .get(
            "https://clinic.vesoftometic.co.in/SupplierMaster/get_Stationary_ProductMaster_data"
          )
          .then((response) => {
            // Handle the successful response here
            console.log(response.data);
            const responsedata = response.data;
            const selectedSupplier = responsedata.find(
              (p) => selectedProductIndex.ItemCode === p.ItemCode
            );
            setFormDataProduct((prev) => ({
              ...prev,
              ProductCategory: selectedProductIndex.Productype,
              ...selectedSupplier,
            }));

            if (selectedSupplier.Status !== "Active") {
              setIsInactive(true);
            } else {
              setIsInactive(false);
            }
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (selectedProductIndex.Productype === "Non_Stationary") {
        axios
          .get(
            "https://clinic.vesoftometic.co.in/SupplierMaster/get_NONStationary_ProductMaster_data"
          )
          .then((response) => {
            // Handle the successful response here
            console.log(response.data);
            const responsedata = response.data;
            const selectedSupplier = responsedata.find(
              (p) => selectedProductIndex.ItemCode === p.ItemCode
            );
            setFormDataProduct((prev) => ({
              ...prev,
              ProductCategory: selectedProductIndex.Productype,
              ...selectedSupplier,
            }));

            if (selectedSupplier.Status !== "Active") {
              setIsInactive(true);
            } else {
              setIsInactive(false);
            }
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
    } else {
      if (formDataProduct.ProductCategory === "Assets") {
        axios
          .get("https://clinic.vesoftometic.co.in/SupplierMaster/get_Assets_Product_code")
          .then((response) => {
            console.log("INV:", response.data.nextAssetsProductCode);
            setFormDataProduct((prevData) => ({
              ...prevData,
              ItemCode: response.data.nextAssetsProductCode,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (formDataProduct.ProductCategory === "Stationary") {
        axios
          .get(
            "https://clinic.vesoftometic.co.in/SupplierMaster/get_Stationary_Product_code"
          )
          .then((response) => {
            console.log("INV:", response.data.nextStationaryProductCode);

            setFormDataProduct((prevData) => ({
              ...prevData,
              ItemCode: response.data.nextStationaryProductCode,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (formDataProduct.ProductCategory === "Non_Stationary") {
        axios
          .get(
            "https://clinic.vesoftometic.co.in/SupplierMaster/get_NONStationary_Product_code"
          )
          .then((response) => {
            console.log("INV:", response.data.nextNonStationaryProductCode);

            setFormDataProduct((prevData) => ({
              ...prevData,
              ItemCode: response.data.nextNonStationaryProductCode,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [selectedProductIndex, formDataProduct.ProductCategory]);

  const handleInactiveChange = () => {
    setIsInactive(!isInactive);
    setFormDataProduct((prevData) => ({
      ...prevData,
      Status: !isInactive ? "Inactive" : "Active",
    }));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormDataProduct((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveOrUpdate = () => {
    const newData = { ...formDataProduct };
    let requiredFields;
    if (newData.ProductCategory === "Assets") {
      requiredFields = [
        "ProductCategory",
        "ProductType",
        "ItemName",
        "ItemCode",
      ];
    } else if (newData.ProductCategory === "Stationary") {
      requiredFields = [
        "ProductCategory",
        "ProductType",
        "ItemName",
        "ItemCode",
      ];
    } else if (newData.ProductCategory === "Non_Stationary") {
      requiredFields = [
        "ProductCategory",
        "ProductType",
        "ItemName",
        "ItemCode",
      ];
    }

    const missingFields = requiredFields.filter(
      (field) => !formDataProduct[field]
    );

    if (missingFields.length === 0) {
      if (selectedProductIndex) {
        axios
          .post(
            `https://clinic.vesoftometic.co.in/SupplierMaster/Update_productMaster_All`,
            newData
          )
          .then((response) => {
            console.log(response.data);
            clearFormInputs();
            dispatchvalue({ type: "selectedProductIndex", value: null });

            // After successfully updating data, navigate to General_MasterList
            navigate("/Home/General_MasterList");
          })
          .catch((error) => {
            console.error("Error post Supplier data:", error);
          });
      } else {
        if (formDataProduct.ProductCategory === "Assets") {
          axios
            .post(
              "https://clinic.vesoftometic.co.in/SupplierMaster/insert_Assets_ProductMaster_data",
              newData
            )
            .then((response) => {
              console.log(response.data);
              clearFormInputs();
              navigate("/Home/General_MasterList");
            })
            .catch((error) => {
              console.error("Error post Supplier data:", error);
            });
        } else if (formDataProduct.ProductCategory === "Stationary") {
          axios
            .post(
              "https://clinic.vesoftometic.co.in/SupplierMaster/insert_Stationary_ProductMaster_data",
              newData
            )
            .then((response) => {
              console.log(response.data);
              clearFormInputs();
              navigate("/Home/General_MasterList");
            })
            .catch((error) => {
              console.error("Error post Supplier data:", error);
            });
        } else if (formDataProduct.ProductCategory === "Non_Stationary") {
          axios
            .post(
              "https://clinic.vesoftometic.co.in/SupplierMaster/insert_NONStationary_ProductMaster_data",
              newData
            )
            .then((response) => {
              console.log(response.data);
              clearFormInputs();
              navigate("/Home/General_MasterList");
            })
            .catch((error) => {
              console.error("Error post Supplier data:", error);
            });
        }
      }
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };
  const clearFormInputs = () => {
    setFormDataProduct({
      ProductCategory: "Medical",
      ProductType: "",
      ItemCode: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
    });
    setIsInactive(false);
  };
  return (
    <div className="appointment">
      <div className="h_head">
        <h4>General Master</h4>
      </div>

     
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="ProductType">
            Product Category <span>:</span>
          </label>
          <select
            value={formDataProduct.ProductCategory}
            name="ProductCategory"
            onChange={handleInputChange}
            // readOnly={selectedProductIndex}
            disabled={selectedProductIndex}
          >
            <option value="Assets">Assets</option>
            <option value="Stationary">Stationary</option>
            <option value="Non_Stationary">Non Stationary</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="code">
            Item Code <span>:</span>
          </label>
          <input
            type="text"
            name="ItemCode"
            value={formDataProduct.ItemCode}
            required
            readOnly
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="itemName">
            Item Name <span>:</span>
          </label>
          <input
            type="text"
            name="ItemName"
            value={formDataProduct.ItemName}
            onChange={handleInputChange}
            // readOnly={selectedProductIndex}
          />
        </div>

        {formDataProduct.ProductCategory === "Medical" && (
          <div className="RegisForm_1">
            <label htmlFor="generic">
              Generic Name<span>:</span>
            </label>
            <input
              type="text"
              name="GenericName"
              value={formDataProduct.GenericName}
              onChange={handleInputChange}
              // readOnly={selectedProductIndex}
            />
          </div>
        )}

        {formDataProduct.ProductCategory !== "Medical" &&
          formDataProduct.ProductCategory !== "Assets" && (
            <div className="RegisForm_1">
              <label htmlFor="uom">
                Product Type<span>:</span>
              </label>
              <input
                type="text"
                name="ProductType"
                value={formDataProduct.ProductType}
                onChange={handleInputChange}
                // readOnly={selectedProductIndex}
              />
            </div>
          )}

        {formDataProduct.ProductCategory !== "Medical" &&
          formDataProduct.ProductCategory === "Assets" && (
            <div className="RegisForm_1">
              <label htmlFor="uom">
                Product Type<span>:</span>
              </label>
              {/* <input
                                type="text"
                                name="ProductType"
                                placeholder="Enter Product Type"
                                value={formDataProduct.ProductType}
                                onChange={handleInputChange}
                                // readOnly={selectedProductIndex}
                                className='Stoct_Product_master_form_Input'
                            /> */}

              <select
                value={formDataProduct.ProductType}
                name="ProductType"
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Movable_Assets">Movable Assets</option>
                <option value="Non_Movable_Assets">Non Movable Assets</option>
              </select>
            </div>
          )}

        {formDataProduct.ProductCategory === "Medical" && (
          <>
            <div className="RegisForm_1">
              <label htmlFor="strength">
                Strength<span>:</span>
              </label>
              <input
                type="text"
                name="Strength"
                value={formDataProduct.Strength}
                onChange={handleInputChange}
                // readOnly={selectedProductIndex}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="strength">
                UOM<span>:</span>
              </label>
              <input
                type="text"
                name="UOM"
                value={formDataProduct.UOM}
                onChange={handleInputChange}
                // readOnly={selectedProductIndex}
              />
            </div>
          </>
        )}

        {formDataProduct.ProductCategory === "Medical" && (
          <div className="RegisForm_1">
            <label htmlFor="uom">
              Product Type<span>:</span>
            </label>
            <input
              type="text"
              name="ProductType"
              value={formDataProduct.ProductType}
              onChange={handleInputChange}
              // readOnly={selectedProductIndex}
            />
          </div>
        )}

        <div className="RegisForm_1">
          <label htmlFor="uom">
            Product Quantity<span>:</span>
          </label>
          <input
            type="number"
            name="ProductQuantity"
            value={formDataProduct.ProductQuantity}
            onChange={handleInputChange}
            // readOnly={selectedProductIndex}
          />
        </div>

        {formDataProduct.ProductCategory !== "Medical" && (
          <div className="RegisForm_1">
            <label htmlFor="inactive">
              Inactive<span>:</span>{" "}
            </label>
            <input
              type="checkbox"
              name="inactive"
              checked={isInactive}
              onChange={handleInactiveChange}
            />
          </div>
        )}

        {formDataProduct.ProductCategory === "Medical" && (
          <div className="Stoct_Product_master_form_row">
            <div className="RegisForm_1">
              <label htmlFor="uom">
                Selling Rate Without Tax(Per Nos)<span>:</span>
              </label>
              <input
                type="number"
                name="ItemRateWithouttax"
                value={formDataProduct.ItemRateWithouttax}
                onChange={handleInputChange}
                // readOnly={selectedProductIndex}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="inactive">
                Inactive<span>:</span>
              </label>
              <input
                type="checkbox"
                name="inactive"
                checked={isInactive}
                onChange={handleInactiveChange}
              />
            </div>
          </div>
        )}
      </div>

      <div className="Register_btn_con">
        <button className=" RegisterForm_1_btns" onClick={handleSaveOrUpdate}>
          {selectedProductIndex ? "Update" : "Save"}
        </button>
      </div>
    </div>
  );
}

export default GeneralMaster;
