import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PastHistory from "./PastHistory";
import Prescription from "./Prescription";
import TreatmentComponent from "./TreatmentComponent";
import Vital from "./Vital";
import Procedure from "./Procedure";
import axios from "axios";
import LabMaster from "./LabMaster";
import profileimg from "../assets/profileimg.jpeg";
import LabReport from "./LabReport";
import Procedure2 from "../Therophist/Procedure2";
import { useDispatch, useSelector } from "react-redux";
import VisitingDoctor1 from "./VisitingDoctor1";

function Navigation() {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const Data = useSelector((state) => state.userRecord?.Data);
  console.log(Data);

  const [activeTab, setActiveTab] = useState("vitalForm");
  const [isToggled, setIsToggled] = useState(false);
  const [workbenchformData, setFormData] = useState({
    SerialNo: "",
    PatientID: "",
    AppointmentID: "",
    visitNo: "",
    firstName: "",
    lastName: "",
    AppointmentDate: "",
    Complaint: "",
    PatientPhoto: "",
    DoctorName: "",
    Age: "",
    Gender: "",
    Location: "",
  });

  console.log(workbenchformData);
  dispatchvalue({
    type: "workbenchformData",
    value: workbenchformData,
  });

  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  // useEffect(() => {
  //   const handleBodyClick = (event) => {
  //     if (!event.target.closest(".new-kit")) {
  //       closeToggle();
  //     }
  //   };

  //   document.body.addEventListener("click", handleBodyClick);

  //   return () => {
  //     document.body.removeEventListener("click", handleBodyClick);
  //   };
  // });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://clinic.vesoftometic.co.in/appointmentmanagement/get_appointments_Navigation?patientid=${Data.serialid}&location=${userRecord.location}`
        );
        console.log(response.data);

        const matchingAppointment = response.data[0];
        console.log(matchingAppointment);

        setFormData({
          SerialNo: matchingAppointment.appointment_patientregisterID,
          PatientID: matchingAppointment.PatientID,
          AppointmentID: matchingAppointment.AppointmentID,
          visitNo: matchingAppointment.VisitID,
          firstName: matchingAppointment.FirstName,
          lastName: matchingAppointment.LastName,
          DoctorName: matchingAppointment.DoctorName,
          Status: matchingAppointment.Status,
          AppointmentDate: matchingAppointment.AppointmentDate,
          Complaint: matchingAppointment.Complaint,
          PatientPhoto: `data:image/*;base64,${matchingAppointment.PatientPhoto}`,
          Age: matchingAppointment.Age,
          Gender: matchingAppointment.Gender,
          Location: matchingAppointment.Location,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    };

    //   // Call the fetchData function when the component mounts
    fetchData(userRecord?.location);
  }, [Data, userRecord?.location]);


  return (
    <>

      <div className="new-patient-registration-form">
        <br />
        <div className="dctr_info_up_head">
          <div className="RegisFormcon ">
            <div className="dctr_info_up_head22">
              <img src={workbenchformData.PatientPhoto || profileimg} alt="Patient Photo" />
              <label>Profile</label>
            </div>
          </div>

          <div className="RegisFormcon">
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient Name <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.firstName + " " + workbenchformData.lastName}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient ID <span>:</span>
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.PatientID}{" "}
              </span>
            </div>
            {/* <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Date of birth <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.firstName + " " + workbenchformData.lastName}{" "}
              </span>
            </div> */}
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Age <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.Age}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Gender <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.Gender}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Primary Doctor <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.DoctorName}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Location <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.Location}{" "}
              </span>
            </div>
          </div>
        </div>

        <br />
        <div className="new-patient-registration-form1">
          <div className="new-navigation">
            <h2>
              <button onClick={() => handleTabChange("vitalForm")}>
                Vital Form
              </button>
              |
              <button onClick={() => handleTabChange("pastHistory")}>
                Past History
              </button>
              |
              <button onClick={() => handleTabChange("Treatment")}>
                Treatment
              </button>
              |
              <button onClick={() => handleTabChange("Prescription")}>
                Prescription
              </button>
              |
              <button onClick={() => handleTabChange("Procedure")}>
                Procedure
              </button>
              |
              <div class="Lab_dropdown">
                <button class="Lab_button">Lab</button>
                <div class="Lab_dropdown_content">
                  <button onClick={() => handleTabChange("Lab")}>
                    Lab Test
                  </button>
                  <button onClick={() => handleTabChange("LabReport")}>
                    Lab Test Preview
                  </button>
                </div>
              </div>
              {/* Add buttons for other tabs */}|
              <button onClick={() => handleTabChange("TherapistProcedure")}>
                Therapist Procedure
              </button>
              |
              <button onClick={() => handleTabChange("VisitingDoctor1")}>
                Visiting Doctor
              </button>
            </h2>
          </div>

          <div className="new-kit ">
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>

            <div>
              {isToggled && (
                <div className="new-navigation-toggle">
                  <h2>
                    <button onClick={() => handleTabChange("vitalForm")}>
                      Vital Form
                    </button>
                    |
                    <button onClick={() => handleTabChange("pastHistory")}>
                      Past History
                    </button>
                    |
                    <button onClick={() => handleTabChange("Treatment")}>
                      Treatment
                    </button>
                    |
                    <button onClick={() => handleTabChange("Prescription")}>
                      Prescription
                    </button>
                    |
                    <button onClick={() => handleTabChange("Procedure")}>
                      Procedure
                    </button>
                    {/* Add buttons for other tabs */}|
                    <div class="Lab_dropdown">
                      <button class="Lab_button">Lab</button>
                      <div class="Lab_dropdown_content">
                        <button onClick={() => handleTabChange("Lab")}>
                          Lab Test
                        </button>
                        <button onClick={() => handleTabChange("LabReport")}>
                          Lab Test Preview
                        </button>
                        |
                      </div>
                      |
                    </div>
                    <button
                      onClick={() => handleTabChange("TherapistProcedure")}
                    >
                      Therapist Procedure
                    </button>
                    |
                    <button onClick={() => handleTabChange("VisitingDoctor1")}>
                      Visiting Doctor
                    </button>
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeTab === "vitalForm" && <Vital />}
      {activeTab === "pastHistory" && <PastHistory />}
      {activeTab === "Treatment" && <TreatmentComponent />}
      {activeTab === "Prescription" && <Prescription />}
      {activeTab === "Procedure" && <Procedure />}
      {activeTab === "Lab" && <LabMaster />}
      {activeTab === "LabReport" && <LabReport />}
      {activeTab === "TherapistProcedure" && <Procedure2 />}
      {activeTab === "VisitingDoctor1" && <VisitingDoctor1 />}
    </>
  );
}

export default Navigation;
