import React, { useState, useEffect } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';

const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeader: {
                    backgroundColor: "var(--ProjectColor)",
                },
                root: {
                    "& .MuiDataGrid-window": {
                        overflow: "hidden !important",
                    },
                },
                cell: {
                    borderTop: "0px !important",
                    borderBottom: "1px solid  var(--ProjectColor) !important",
                },
            },
        },
    },
});

const EmployeeAttendance = ( ) => {

  const dispatchvalue = useDispatch();


    const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen )

     const userRecord = useSelector(state=>state.userRecord?.UserData)


    const [rows, setRows] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [filteredRows, setFilteredRows] = useState([]);
    const [department, setdepartment] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [intime, setInTime] = useState('');
    const [outtime, setOutTime] = useState( '' )
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');

    const [rolename, setRolename] = useState([])

    const navigate = useNavigate()
    const totalPages = Math.ceil(rows.length / 10);
    // const [selectedRows, setSelectedRows] = React.useState([]);
  
  
  
    const [page, setPage] = useState(0);
    // const [page1, setPage1] = useState(0);
    const pageSize = 10;

    
  const showdown = rows.length;
//   const showdown1 = rows1.length;
  const handlePageChange = (params) => {
    setPage(params.page);
  };


    useEffect(() => {
        axios
          .get(
            'https://clinic.vesoftometic.co.in/HRmanagement/getRole_all'
          )
          .then((response) => {
            console.log(response.data);
            setRolename(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    
    
      }, [userRecord?.location]);



    const [columns] = React.useState([
        { field: 'id', headerName: 'Serial No', width: 80 },
        { field: "employeeid", headerName: "Employee ID", width: 140 },
        { field: "employeename", headerName: "Employee Name", width: 180 },
        { field: "designation", headerName: "Designation", width: 200 },
        { field: "location", headerName: "Branch", width: 150 },
        {
            field: "Status",
            headerName: "Status",
            width: 80,
            renderCell: (params) => (
                <Button className='cell_btn' onClick={() => handleEditClick1(params.row)}>
                <EditIcon />
                </Button>
            ),
        },
        {
            field: 'actions',
            headerName: 'Calender',
            width: 80,
            renderCell: (params) => (
              <>
                <Button className='cell_btn' onClick={()=>handlecalenderview(params.row)}>
                  <CalendarMonthIcon />
                </Button>
      
              </>
            ),
          },
    ]);

    const handleEditClick1 = (params) => {
        setOpenModal(true);
        setSelectedRowData(params)
    
      };


    const handlecalenderview =(params)=>{

         dispatchvalue({type:'employeeIdget' , value :(params.employeeid)});

         console.log('setEmployeeIdGet :',params.employeeid)
         navigate('/Home/Employee-AttendanceCalendar');
    }

    useEffect(() => {
        axios.get(`https://clinic.vesoftometic.co.in/HRmanagement/get_employee_personaldetails?location=${userRecord?.location}`)
            .then((response) => {
                const data = response.data;
                const Records = data.map((userdata, index) => ({
                    id: index + 1,
                    employeeid: userdata.EmployeeID,
                    employeephoto: userdata.EmployeePhoto,
                    employeename: userdata.EmployeeName,
                    phone: userdata.PhoneNumber,
                    designation: userdata.Designation,
                    location: userdata.Locations,
                    department: userdata.Department,
                    date :new Date().toISOString().split('T')[0],
                    createdby : userRecord?.username
                }));
                setRows(Records);
            })
            .catch((error) => {
                console.log(error);
            });


            axios
            .get(`https://clinic.vesoftometic.co.in/usercontrol/getDepartment`)
            .then((response) => {
              console.log(response.data);
              setdepartment(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
      
    }, [userRecord]);


    useEffect(()=>{

        setSelectedRowData(rows)
    },[rows])

    console.log('selectedRowData',selectedRowData)
    const handleSubmission = () => {
        {
          const apiUrl = 'https://clinic.vesoftometic.co.in/HRmanagement/insert_attendance_report';
    
          const submissionData = {
            employeeid: selectedRowData.employeeid,
            employeename: selectedRowData.employeename,
            location : selectedRowData.location,
            department: selectedRowData.department,
            outtime: outtime,
            intime : intime,
            createdby: userRecord?.username
        };
          console.log(submissionData)
          fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(submissionData),
          })
            .then(response => response.json())
            .then(data => {
              console.log('Submission response:', data);
              setInTime('');
              setOutTime('');
          
            })
            .catch(error => {
              console.error('Error submitting data:', error);
            });
        }
      };
    

      useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const lowerCaseQuery1 = searchQuery1.toLowerCase();
    
        const filterRow = (row) => {
          // Ensure the row has necessary properties before accessing them
          if (row.employeename && row.designation) {
            const lowerCaseEmployeeName = row.employeename.toLowerCase();
            const lowerCaseDesignation = row.designation.toLowerCase();
            const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
            const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);
    
            if (!searchQuery && !searchQuery1) {
              // If both search queries are empty, do not filter out any data
              return true;
            }
    
            return nameMatches && designationMatches;
          }
          return false;
        };
    
        const filteredData = rows.filter(filterRow);
    
        setFilteredRows(filteredData);
    
      }, [searchQuery, searchQuery1, rows]);

    return (
        <div>
            <div className="appointment">
                <div className="h_head">
                    <h4>Daily Attendance</h4>
                </div>

                <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">Employee Name <span>:</span></label>
                <input
                  type="text"
                  id="date"
                  name='employeeName'
                  placeholder='Enter Employee Name'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="input">Designation <span>:</span></label>
                <select
                  name="designation"
                  value={searchQuery1}
                  onChange={(e) => setSearchQuery1(e.target.value)}
                  className="new-custom-input-phone vital_select"
                  required
                >
                  <option value="select">Select </option>
                  {rolename.map((role) => (
                    <option key={role.role_id} value={role.role_name}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
              </div>
                    
                   
                </div>

                <ThemeProvider theme={theme}>
            <div className=" grid_1">
              <DataGrid
                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                columns={columns}
                pageSize={100}
                hideFooter={true}
                isCellEditable={(params) => params.row.id !== 1}
                onEditCellChange={(params) => {
                  const updatedRows = rows.map((row) => {
                    if (row.id === params.id) {
                      return { ...row, [params.field]: params.props.value };
                    }
                    return row;
                  });
                  setRows(updatedRows);
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" data_grid"
              />
              {showdown > 10 && filteredRows.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
            {/* <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleSubmission}>
                Submit
              </button>
            </div> */}
          </ThemeProvider>
            </div>
           
            {openModal && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModal(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
                <div className="RegisFormcon leavecon">
                  <div className="RegisForm_1 leaveform_1">
                    <label htmlFor="intime">In Time<span>:</span></label>
                    <input type='time' name='intime' value={intime} onChange={(e)=>{setInTime(e.target.value)}}/>
                  </div>
                </div>
           
                  <div className="RegisFormcon leavecon" >
                    <div className="RegisForm_1 leaveform_1 ">
                      <label htmlFor="outtime">Out Time <span>:</span></label>
                      <input
                        type="time" name="outtime" id="outtime" value={outtime} onChange={(e)=>{setOutTime(e.target.value)}}/>
                    </div>
                  </div>
          
                <div className="Register_btn_con regster_btn_contsai">
                  <button className="RegisterForm_1_btns" onClick={handleSubmission}>Submit</button>
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
    );
};

export default EmployeeAttendance;
