
import React, { useState, useEffect, useCallback } from 'react';
// import "./Prescription.css";
import axios from 'axios';

const Prescription2 = (props) => {

  const formData = props.formData;
  const Location = props.userRecord.location;
  // const create = props.userRecord.username;

  const [selectedMedicines, setSelectedMedicines] = useState([]);
  // const [medicine, setMedicine] = useState('');
  // const [dose, setDose] = useState('');
  // const [route, setRoute] = useState('');
  // const [qty, setQty] = useState('');
  // const [instruction, setInstruction] = useState('');
  // const [frequency, setfrequency] = useState('');
  // const [durationNumber, setDurationNumber] = useState('');
  // const [durationUnit, setDurationUnit] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');

  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    setAppointmentDate(currentDate);
  }, []);

  // const handleAddMedicine = () => {
  //   if (medicine) {       
  //     // Prepare the data you want to send to the backend
  //   const dataToSend = {
  //     PatientID: formData.PatientID,
  //       appointmentDate: appointmentDate,
  //       AppointmentID: formData.SerialNo,
  //       visitNo: formData.visitNo,
  //       medicine,
  //       dose,
  //       route,
  //       frequency,
  //       duration: `${durationNumber} ${durationUnit}`,
  //       qty,
  //       instruction,
  //       createdBy: create,
  //   };

  //   console.log(dataToSend)

  //   // Make an Axios POST request to send the data to the backend
  //   axios.post('https://clinic.vesoftometic.co.in/doctorsworkbench/insert_prescription', dataToSend, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then(response => {
  //       // Handle the response from the backend, e.g., show a success message
  //       alert(response.data.message)
  //     //  console.log(response.data);
  //       fetchData();
  //       // Clear individual fields after adding medicine
  //     setMedicine('');
  //     setDose('');
  //     setRoute('');
  //     setfrequency('');
  //     setDurationNumber('');
  //     setDurationUnit('')
  //     setQty('');
  //     setInstruction('');
  //     })
  //     .catch(error => {
  //       // Handle any errors that occur during the POST request
  //       console.error('Error sending data to the backend:', error);
  //     });


  //   }

  // };

  // const [selectedMedicineData, setSelectedMedicineData] = useState([]);

  // const [medicineOptions, setMedicineOptions] = useState([]); // To store medicine options

  // Fetch medicine options from the PHP script when the component mounts
  // useEffect(() => {
  //   axios.get('http://localhost/Backend-trail/medicine-Fetch_medicinename-genericname.php')
  //     .then(response => {
  //       if (Array.isArray(response.data)) { // Check if response.data is an array
  //         setMedicineOptions(response.data);
  //       } else {
  //         console.error('Response data is not an array:', response.data);
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error fetching medicine options:', error);
  //     });
  // }, []);



  const fetchData = useCallback(() => {

    const PatientID = formData.PatientID;

    
    axios.get(`https://clinic.vesoftometic.co.in/doctorsworkbench/get_prescription?PatientID=${PatientID}&location=${Location}`)
      .then((response) => {
        // console.log(response.data)
        if (response.data) {
          const data = response.data;
          console.log("222222222", data);
          const filteredData = data.filter(item => item.PatientID === formData.PatientID);
          console.log(filteredData);
          setSelectedMedicines(filteredData);
        } else {
          alert("no data found")
        }
      })
      .catch((error) => {
        console.error(error)
      });
  }, [formData, appointmentDate]);




  useEffect(() => {
    fetchData(); // Call fetchData immediately when formData or appointmentDate changes

  }, [fetchData]);



  // const fetchMedicineData = (medicineName, genericName) => {
  //   // Make an Axios POST request to fetch route and dose

  //   axios
  //     .post(
  //       'http://localhost/Backend-trail/medicine-FetchByMedicineNameGenericName-allrecords.php',
  //       {
  //         medicine_name: medicineName,
  //         generic_name: genericName, // You may need to provide the generic name here if necessary
  //       }
  //     )
  //     .then((response) => {
  //       // Push the fetched data into the array
  //      // console.log(response);
  //       setSelectedMedicineData([
  //         {
  //           route: response.data.data.route,
  //           dose: response.data.data.dose,
  //         },
  //       ]);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching medicine data:', error);
  //     });
  // };


  // const handleMedicineChange = (e) => {
  //   const selectedMedicine = e.target.value;
  //   setMedicine(selectedMedicine);


  //   // Split the selectedMedicine into medicine name and generic name
  //   const [medicineName, genericName] = selectedMedicine.split('-');

  //   // Fetch medicine data (route and dose) based on the selected medicine
  //   if (medicineName && genericName) {
  //     fetchMedicineData(medicineName, genericName);
  //   } else {
  //     // Clear the medicine data when no medicine is selected
  //     setSelectedMedicineData([]);
  //   }
  // };




  return (


    <div className="for">
      <div className="custom-header">
        <h4>Selected Medicine</h4>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id='slectbill_ins'>Generic Name</th>
              <th id='slectbill_ins'>Item Name</th>
              <th id='slectbill_ins'>Medicine</th>
              <th id="slectbill_ins">Dose</th>
              <th id="slectbill_ins">Route</th>
              <th id="slectbill_ins">Frequency</th>
              <th id="slectbill_ins">Duration</th>
              <th id="slectbill_ins">Qty</th>
              <th id="slectbill_ins">Instruction</th>
            </tr>
          </thead>

          <tbody>
            {selectedMedicines.map((medicineInfo, index) => (
              <tr key={index}>
                <td>{medicineInfo.GenericName}</td>
                <td>{medicineInfo.ItemName}</td>
                <td>{medicineInfo.ItemName} - {medicineInfo.GenericName}</td>
                <td>{medicineInfo.Dose}</td>
                <td>{medicineInfo.Route}</td>
                <td>{medicineInfo.Frequency}</td>
                <td>{medicineInfo.Duration}</td>
                <td>{medicineInfo.Qty}</td>
                <td>{medicineInfo.Instruction}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


    </div>

  );
};

export default Prescription2;
