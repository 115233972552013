import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";


function VisitingDoctor() {
    
    const visitingdotor = useSelector((state) => state.userRecord?.vistingdoctordata);
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const Location = userRecord?.location
    const username = userRecord?.username


    const [formData, setFormData] = useState({
        doctorName: '',
        phoneNumber: '',
        email: '',
        location: '',
        specialist: '',
        experience: '',
        dateOfVisit: '',
        treatmentProcedure: '',
        duration: '',
        // numberOfPatients: '',
        numberOfSessions: '',
        numberOfCraft: '',

    });

    useEffect(()=>{
        setFormData(prev => ({ ...prev, ...visitingdotor[0] }));
    },[visitingdotor])

    const handlePhoneNumberChange = (e) => {
        const inputPhoneNumber = e.target.value;

        // Ensure that the phone number is only 10 digits
        if (/^\d{0,10}$/.test(inputPhoneNumber)) {
            setFormData({ ...formData, phoneNumber: inputPhoneNumber });
        } else {
            alert("Phone Number must contain 10 digits")
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Initialize FormData
        const newformData = new FormData();
        
        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                newformData.append(key, formData[key]);
            }
        }   
             // Assuming Location is a variable with some value
             newformData.append('branchlocation', Location);
            newformData.append('createdby',username);



        console.log("Form submitted:", formData);
    
        axios.post(`https://clinic.vesoftometic.co.in/VisitingDoctor/insert_visitingdoctor_procedure`, newformData)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    


    
    return (
        <>
            <div className='new-patient-registration-form'>
                <div className='user_patienthead'>
                    <h3>Visiting Doctor</h3>
                </div>
                <br />

            <div className="RegisFormcon">
              <div className="RegisForm_1">
                        <label >
                            Visit Doctor <span> Dr.</span>
                        </label>
                        <select
                            name="doctorName"
                
                            value={formData.doctorName}
                            onChange={(e) => setFormData({ ...formData, doctorName: e.target.value })}
                            required
                        >
                            <option value="" disabled>Select</option>
                            <option value="BalaChandran"> BalaChandran</option>
                            <option value="Rajesh"> Rajesh</option>
                            <option value="Hemalatha"> Hemalatha</option>

                        </select>
                    </div>
                    <div className="RegisForm_1">
                        <label className="new-custom-label-title12">
                            Phone Number <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}

                            onChange={handlePhoneNumberChange}
                            required

                        />
                    </div>
                    <div className="RegisForm_1">
                        <label >
                            Email <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
 
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            required

                        />
                    </div>
                </div>
                <div className="new-patient-info-container ">
                <div className="RegisForm_1">
                        <label >
                            Location <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="location"
                            value={formData.location}

                            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                            required

                        />
                    </div>
                    <div className="RegisForm_1">
                        <label>
                            Specialist <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="specialist"
                            value={formData.specialist}

                            onChange={(e) => setFormData({ ...formData, specialist: e.target.value })}
                            required

                        />
                    </div>
                    <div className="RegisForm_1">
                        <label >
                            Experience <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="experience"
                            value={formData.experience}

                            onChange={(e) => setFormData({ ...formData, experience: e.target.value })}
                            required

                        />
                    </div>
                </div>
                <div className="new-patient-info-container ">
                <div className="RegisForm_1">
                        <label >
                            Date of Visit <span>:</span>
                        </label>
                        <input
                            type="date"
                            name="dateOfVisit"
                            value={formData.dateOfVisit}
               
                            onChange={(e) => setFormData({ ...formData, dateOfVisit: e.target.value })}
                            required

                        />
                    </div>
                    <div className="RegisForm_1">
                        <label>
                            Treatment Procedure <span>:</span>
                        </label>
                        <select
                            name="treatmentProcedure"

                            value={formData.treatmentProcedure}
                            onChange={(e) => setFormData({ ...formData, treatmentProcedure: e.target.value })}
                            required
                        >
                            <option value="">Select Procedure</option>
                            <option value="HT">HT</option>
                            <option value="PRP">PRP</option>
                        </select>
                    </div>

                    <div className="RegisForm_1">
                        <label>
                            Number of Sessions <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="numberOfSessions"
                            value={formData.numberOfSessions}

                            onChange={(e) => setFormData({ ...formData, numberOfSessions: e.target.value })}
                            required

                        />
                    </div>
                </div>
                <div className="new-patient-info-container ">
                    {/* <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Number of Patients <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="numberOfPatients"
                            value={formData.numberOfPatients}
                            placeholder="Enter Number of Patients"
                            onChange={(e) => setFormData({ ...formData, numberOfPatients: e.target.value })}
                            required

                        />
                    </div> */}

<div className="RegisForm_1">
                        <label>
                            Number of Crafts <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="numberOfCraft"
                            value={formData.numberOfCraft}

                            onChange={(e) => setFormData({ ...formData, numberOfCraft: e.target.value })}
                            required

                        />
                    </div>
                    <div className="RegisForm_1">
                        <label>
                            Duration <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="duration"
                            value={formData.duration}

                            onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
                            required

                        />
                    </div>

                </div>
                <div className="new-button-container">
                <button className="btncon_add prs-ad-sub-btn" type="submit" onClick={handleSubmit}>
                    Submit
                </button>
            </div>

            </div>
        </>
    );
}

export default VisitingDoctor;

