import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
// import './Navigation.css';
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import Vital3 from "./Vital3";
import PastHistory3 from "./PastHistory3";
import LabReportFN from "../AppointmentManagement/LabReportFN";
import Concern3 from "./Concern3";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import profileimg from "../assets/profileimg.jpeg";
import axios from "axios";
// import Procedure3 from './Procedure3';

function Navigation3() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const navigate = useNavigate();
  const nursedata=useSelector((state)=>state.userRecord?.formData)

  const Data = useSelector((state) => state.userRecord?.Data);
  console.log(nursedata)
  useEffect(() => {
    if (nursedata.length === 0 ) {
      // Navigate to other page
      navigate('/Home/Treament-QueueList3');
    }
  }, [nursedata, navigate]);
  console.log(nursedata)
  const [activeTab, setActiveTab] = useState("vitalForm3");
  const [isToggled, setIsToggled] = useState(false);
  // const [formData, setFormData] = useState({
  //   SerialNo: "",
  //   PatientID: "",
  //   AppointmentID: "",
  //   visitNo: "",
  //   firstName: "",
  //   lastName: "",
  //   DoctorName: "",
  // });

  const [nursequelistdata, setNursequelistData] = useState({
    SerialNo: "",
    PatientID: "",
    AppointmentID: "",
    visitNo: "",
    firstName: "",
    lastName: "",
    AppointmentDate: "",
    Complaint: "",
    PatientPhoto: "",
    DoctorName: "",
    Age: "",
    Gender: "",
    Location: "",
  });



  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  // useEffect(() => {
  //   const handleBodyClick = (event) => {
  //     if (!event.target.closest(".new-kit")) {
  //       closeToggle();
  //     }
  //   };

  //   document.body.addEventListener("click", handleBodyClick);

  //   return () => {
  //     document.body.removeEventListener("click", handleBodyClick);
  //   };
  // });

  // useEffect(() => {
  //   // Function to fetch data using Axios
  //   const fetchData = async () => {
  //     try {
  //       // Define the data to send in the POST request
  //       const data = {
  //         serialid: localStorage.getItem("appointment_patientregisterid"), // The SerialNo you want to retrieve
  //       };

  //       console.log(data);

  //       // Send a POST request to your PHP endpoint
  //       const response = await axios.get(
  //         `https://clinic.vesoftometic.co.in/appointmentmanagement/get_appointments?location=${userRecord?.location}`
  //       );
  //       console.log(response);
  //       // Handle the response from the server
  //       // if (response.data.success) {
  //       //   // Data retrieval successful
  //       //   const appointmentData = response.data.data[0]; // Assuming there's only one record
  //       const matchingAppointment = response.data.find(
  //         (appointment) => appointment.PatientID.toString() === data.serialid
  //       );

  //       console.log(matchingAppointment);

  //       setFormData({
  //         SerialNo: matchingAppointment.appointment_patientregisterID,
  //         PatientID: matchingAppointment.PatientID,
  //         AppointmentID: matchingAppointment.AppointmentID,
  //         visitNo: matchingAppointment.VisitID,
  //         firstName: matchingAppointment.FirstName,
  //         lastName: matchingAppointment.LastName,
  //         DoctorName: matchingAppointment.DoctorName,
  //         Status: matchingAppointment.Status,
  //         AppointmentDate: matchingAppointment.AppointmentDate,
  //       });

  //       console.log(formData);

  //       dispatchvalue({
  //         type: "formData",
  //         value: formData,
  //       });
  //       //       } else {
  //       //         // Data retrieval failed
  //       //         console.error('Data retrieval failed:', response.data.message);
  //       //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  //   //   // Call the fetchData function when the component mounts
  //   fetchData(userRecord?.location);
  // }, [userRecord?.location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://clinic.vesoftometic.co.in/appointmentmanagement/get_appointments_Navigation?patientid=${nursedata.PatientID}&location=${userRecord.location}`
        );
        console.log(response.data);

        const matchingAppointment = response.data[0];
        console.log(matchingAppointment);

        setNursequelistData({
          SerialNo: matchingAppointment.appointment_patientregisterID,
          PatientID: matchingAppointment.PatientID,
          AppointmentID: matchingAppointment.AppointmentID,
          visitNo: matchingAppointment.VisitID,
          firstName: matchingAppointment.FirstName,
          lastName: matchingAppointment.LastName,
          DoctorName: matchingAppointment.DoctorName,
          Status: matchingAppointment.Status,
          AppointmentDate: matchingAppointment.AppointmentDate,
          Complaint: matchingAppointment.Complaint,
          PatientPhoto: `data:image/*;base64,${matchingAppointment.PatientPhoto}`,
          Age: matchingAppointment.Age,
          Gender: matchingAppointment.Gender,
          Location: matchingAppointment.Location,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    };

    //   // Call the fetchData function when the component mounts
    fetchData(userRecord?.location);
  }, [Data, userRecord?.location]);

  return (
    <>
      <Helmet>
        <title>Navigation</title>
      </Helmet>
      <div className="new-patient-registration-form">
        <br />
        <div className="dctr_info_up_head">
          <div className="RegisFormcon ">
            <div className="dctr_info_up_head22">
            <img src={nursequelistdata.PatientPhoto || profileimg} alt="Patient Photo" />
              <label>Profile </label>
            </div>
          </div>

          <div className="RegisFormcon">
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient Name <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
    {nursedata.PatientName}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient ID <span>:</span>
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
    {nursequelistdata.PatientID}
              </span>
            </div>
            {/* <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Date of birth <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
    {nursequelistdata.}
              </span>
            </div> */}
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Age <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
    {nursequelistdata.Age}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Gender <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
    {nursequelistdata.Gender}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Location <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
    {nursequelistdata.Location}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Primary Doctor <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
    {nursequelistdata.DoctorName}
              </span>
            </div>
          </div>
        </div>

        <br />
        <div className="new-patient-registration-form1">
          <div className="new-navigation">
            <h2>
              <button onClick={() => handleTabChange("vitalForm3")}>
                Vital Form
              </button>
              |
              <button onClick={() => handleTabChange("pastHistory3")}>
                Past History
              </button>
              {/* |
              <button onClick={() => handleTabChange('Treatment')}>Treatment</button>
              |
              <button onClick={() => handleTabChange('Prescription')}>Prescription</button> */}
              |
              {/* <button onClick={() => handleTabChange('Procedure3')}>Procedure</button> */}
              <button onClick={() => handleTabChange("Lab")}>Lab Report</button>
              |
              <button onClick={() => handleTabChange("Concern")}>Consent Form</button>
              {/* <div class="Lab_dropdown">
              <button class="Lab_button">Lab</button>
              <div class="Lab_dropdown_content">
                <button onClick={() => handleTabChange('Lab')}>Lab Test</button>
                <button onClick={() => handleTabChange('LabReport')}>Lab Test Preview</button>
              </div>
              </div> */}
              {/* Add buttons for other tabs */}
            </h2>
          </div>

          <div className="new-kit ">
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>

            <div>
              {isToggled && (
                <div className="new-navigation-toggle">
                  <h2>
                    <button onClick={() => handleTabChange("vitalForm3")}>
                      Vital Form
                    </button>
                    |
                    <button onClick={() => handleTabChange("pastHistory3")}>
                      Past History
                    </button>
                    {/* |
                  <button onClick={() => handleTabChange('Treatment')}>Treatment</button>
                  |
                  <button onClick={() => handleTabChange('Prescription')}>Prescription</button> */}
                    |
                    <button onClick={() => handleTabChange("Lab")}>
                      Lab Report
                    </button>
                    |
                    <button onClick={() => handleTabChange("Concern")}>
                      Consent Form
                    </button>
                    {/* <button onClick={() => handleTabChange('Procedure3')}>Procedure</button> */}
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeTab === "vitalForm3" && (
        <Vital3 />
      )}
      {activeTab === "pastHistory3" && (
        <PastHistory3/>
      )}
      {activeTab === "Lab" && (
        <LabReportFN />
      )}
      {
        activeTab === "Concern" && (
          <Concern3  />
        )
      }
      {/* {activeTab === 'Treatment' && <TreatmentComponent formData={formData} userRecord={userRecord} isSidebarOpen={isSidebarOpen} />}
      {activeTab === 'Prescription' && <Prescription formData={formData} userRecord={userRecord} />} */}
      {/* {activeTab === 'Procedure3' && <Procedure3 formData={formData} userRecord={userRecord} />} */}
      {/* {activeTab === 'Lab' && <LabMaster formData={formData} userRecord={userRecord} />}    
      {activeTab === 'LabReport' && <LabReport formData={formData} userRecord={userRecord} />}  */}
    </>
  );
}

export default Navigation3;
