import React, { useEffect, useState } from "react";
import './AppointmentCalendar.css';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  addWeeks,
  subWeeks
} from "date-fns";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';


const Weeks =  ( ) => {

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [appointmentData, setAppointmentData] = useState([]); // Initialize with an empty array
  const [counts, setCounts] = useState({});
  const [selectedDoctor, setSelectedDoctor] = useState("all");
  const [Showthelist, setShowthelist] = useState('Register');
  const [totalBookedCount, setTotalBookedCount] = useState(0);

  const [Doctors, setDoctors] = useState([]);
  const [weekdays, setWeekdays] = useState({});
  const [weekview, setWeekview] = useState(null);
  const navigate =useNavigate()

  const dispatchvalue = useDispatch();

  const reduxvalueUsercord = useSelector(state => state)
  const userRecord = reduxvalueUsercord.userRecord?.UserData

  useEffect(() => {
    axios.get(`https://clinic.vesoftometic.co.in/usercontrol/get_doctor_info?location=${userRecord?.location}`)
      .then((response) => {
        console.log(response)
        setDoctors(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
    setSelectedDate(new Date());
  }, [userRecord,userRecord?.location]);

  useEffect(() => {
    axios
      .get(`https://clinic.vesoftometic.co.in/appointmentmanagement/get_doctor_slots_per_day?doctor_name=${selectedDoctor}&date=${format(selectedDate, 'yyyy-MM-dd')}&location=${userRecord?.location}`)
      .then((response) => {
        console.log(response)
        const data =response.data
        if (data.length === 1) {
          setAppointmentData(data);
          setCounts({
            Total: data[0].no_of_slot,
            Available: data[0].AvailableSlot,
            Booked: data[0].Registerslot,
            Canceled: data[0].Canceledslot,
            Requested: data[0].Requestslot,
          });
        } else {
          // Combine all data when length is not 1
          const combinedData = data.reduce((acc, doctorData) => {
            acc.Register_patient_data.push(...doctorData.Register_patient_data);
            acc.Request_patient_data.push(...doctorData.Request_patient_data);
            acc.Canceled_Register_patient_data.push(...doctorData.Canceled_Register_patient_data);
            acc.no_of_slot += doctorData.no_of_slot;
            acc.AvailableSlot += doctorData.AvailableSlot;
            acc.Registerslot += doctorData.Registerslot;
            acc.Canceledslot += doctorData.Canceledslot;
            acc.Requestslot += doctorData.Requestslot;
            return acc;
          }, {
            Register_patient_data: [],
            Request_patient_data: [],
            Canceled_Register_patient_data: [],
            no_of_slot: 0,
            AvailableSlot: 0,
            Registerslot: 0,
            Canceledslot: 0,
            Requestslot: 0,
          });
  
          setAppointmentData([combinedData]);
          setCounts({
            Total: combinedData.no_of_slot,
            Available: combinedData.AvailableSlot,
            Booked: combinedData.Registerslot,
            Canceled: combinedData.Canceledslot,
            Requested: combinedData.Requestslot,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }, [selectedDoctor,selectedDate,userRecord,userRecord?.location]);

  // console.log(weekdays)

  // console.log(weekview)
 
  useEffect(() => {
   if(weekdays.startdayweek !== undefined && weekdays.enddayweek !== undefined){
    axios.get(`https://clinic.vesoftometic.co.in/appointmentmanagement/get_doctor_slots_by_week?doctor_name=${selectedDoctor}&start_date=${weekdays.startdayweek}&end_date=${weekdays.enddayweek}&location=${userRecord?.location}`)
    .then((response)=>{
      console.log(response.data);
      const data = response.data;
      let totalCount = 0;

      data.forEach((entry) => {
        totalCount += entry.booked_slot;
      });
  
      setWeekview(data);
      setTotalBookedCount(totalCount);
    })
    .catch((error)=>{
      console.log(error)
    })
   }
  }, [selectedDoctor, weekdays, weekdays.startdayweek, weekdays.enddayweek,userRecord,userRecord?.location]);
  
  useEffect(() => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    setWeekdays({
      startdayweek: format(startDate, 'yyyy-MM-dd'),
      enddayweek: format(endDate, 'yyyy-MM-dd')
    });

    // // Check if appointmentData and appointmentData.patient_data are defined
    // if (appointmentData && appointmentData.patient_data) {
    //   const totalAppointments = appointmentData.patient_data.reduce((count, appointment) => {
    //     const appointmentDate = new Date(appointment.time); // Assuming appointment.time is the date
    //     if (appointmentDate >= startDate && appointmentDate <= endDate) {
    //       return count + 1;
    //     }
    //     return count;
    //   }, 0);

    //   setTotalBookedCount(totalAppointments);
    // }
  }, [currentMonth, selectedDoctor, appointmentData,userRecord,userRecord?.location]);

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
    }
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    axios
    .get(`https://clinic.vesoftometic.co.in/appointmentmanagement/get_doctor_slots_per_day?doctor_name=${selectedDoctor}&date=${format(day, 'yyyy-MM-dd')}&location=${userRecord?.location}`)
    .then((response) => {
      console.log(response.data)
      const data =response.data
      if (data.length === 1) {
        setAppointmentData(data);
        setCounts({
          Total: data[0].total_no_of_slot,
          Available: data[0].available_slot,
          Booked: data[0].booked_slot,
          Canceled: data[0].Cancel,
          Requested: data[0].request_slot,
        });
      } else {
        // Combine all data when length is not 1
        const combinedData = data.reduce((acc, doctorData) => {
          acc.Register_patient_data.push(...doctorData.Register_patient_data);
          acc.Request_patient_data.push(...doctorData.Request_patient_data);
          acc.Canceled_Register_patient_data.push(...doctorData.Canceled_Register_patient_data);
          acc.no_of_slot += doctorData.AvailableSlot;
          acc.AvailableSlot += doctorData.AvailableSlot;
          acc.Registerslot += doctorData.Registerslot;
          acc.Canceledslot += doctorData.Canceledslot;
          acc.Requestslot += doctorData.Requestslot;
          return acc;
        }, {
          Register_patient_data: [],
          Request_patient_data: [],
          Canceled_Register_patient_data: [],
          no_of_slot: 0,
          AvailableSlot: 0,
          Registerslot: 0,
          Canceledslot: 0,
          Requestslot: 0,
        });

        setAppointmentData([combinedData]);
        setCounts({
          Total: combinedData.AvailableSlot,
          Available: combinedData.AvailableSlot,
          Booked: combinedData.Registerslot,
          Canceled: combinedData.Canceledslot,
          Requested: combinedData.Requestslot,
        });
        setShowthelist('Register')
      }
    })
    .catch((error) => {
      console.log(error);
    });
    
  };
  const renderHeader = () => {
    return (
      <div className="calendar">
        <div className="cal_mon_1">
          <button onClick={() => changeWeekHandle("prev")}>
            <KeyboardDoubleArrowLeftIcon />
          </button>
          <h3>{currentMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</h3>
          <button onClick={() => changeWeekHandle("next")}>
            <KeyboardDoubleArrowRightIcon />
          </button>
        </div>
        <div className="select_items_appointment">
          <div className="doctor_select appoint_booked_count">
            <label htmlFor="names">Doctor Name <span>:</span> </label>
            <select
              name="Doctor Name"
              onChange={(e) => {
                setSelectedDate(new Date());
                setSelectedDoctor(e.target.value);
              }}
              value={selectedDoctor}
            >
              <option value="all">Select</option>
              {Doctors.map((p, index) => (
                <option key={index} value={p}>
                  {p}
                </option>
              ))}
            </select>
          </div>
          <div className="appoint_booked_count"> 
          <label>Booked <span>:</span> <span>{totalBookedCount}</span>
          </label>
          </div>
        </div>
      </div>
    );
  };
  
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col row" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days ">{days}</div>;
  };


  
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });

    // console.log(format(startDate, 'MMM d, yyyy'))
    // console.log(format(endDate, 'MMM d, yyyy'))
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      formattedDate = format(day, dateFormat);
      const cloneDay = day;
      // Ensure weekview is not null before trying to access its properties
      const dateData = weekview ? weekview.find((data) => data.date === format(cloneDay, 'yyyy-MM-dd')) : [];
     

      // Get the counts or set them to default values
      const bookedCount = dateData ? dateData.booked_slot : 0;
      const requestCount = dateData ? dateData.request_slot : 0;
      const canceledCount = dateData ? dateData.cancel : 0;

      days.push(
        <div
          className={`row ${isSameDay(day, new Date()) ? "today" : isSameDay(day, selectedDate) ? "selected" : ""}`}
          key={day}
          onClick={() => {
            const dayStr = format(cloneDay, "ccc dd MMM yy");
            onDateClickHandle(cloneDay, dayStr);
          }}
        >
          <div className="calendar_app">
            <div className="number">{formattedDate}</div>
            <div style={{width:'100%'}}>
              {/* <div className="appointment_cal">
                <h3>Total Appointments &rArr; {totalCount}</h3>
              </div> */}
              <div className="appointment_body_1">
                {/* <div className="appointment_data_1">
                  <div>
                    <span>Available</span>
                    <span>:</span>
                  </div>
                  <span className='appointment_count_1'>{availableCount}</span>
                </div> */}
                <div className="appointment_data_1">
                  <div>
                    <span>Booked</span>
                    <span>:</span>
                  </div>
                  <span className='appointment_count_1'>{bookedCount}</span>
                </div>
                <div className="appointment_data_1">
                  <div>
                    <span>Requested</span>
                    <span>:</span>
                  </div>
                  <span className='appointment_count_1'>{requestCount}</span>
                </div>
                <div className="appointment_data_1">
                  <div>
                    <span>Canceled</span>
                    <span>:</span>
                  </div>
                  <span className='appointment_count_1'>{canceledCount}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

      day = addDays(day, 1);
    }

    rows.push(
      <div className="row" key={day}>
        {days}
      </div>
    );
    days = [];

    return <div className="body">{rows}</div>;
  };
  



const renderAppointmentDetails = () => {
 

    return (
      <>
        
          <div className="appointment_details_week">
            <div className="app_d_week_1">
              <h4>Appointment Details for <span>:</span> </h4>
              <p>  {format(selectedDate, "ccc dd MMM yy")}</p>
            </div>
            {/* <div className="app_d_week2">
              <h3>Total Appointments <span>:</span> </h3>
              <p> {counts.Total}</p>
            </div>
            <div className="app_d_week2">
              <h3>Available Slot <span>:</span> </h3>
              <p> {counts.Available}</p>
            </div> */}
            <div className="app_d_week_1" onClick={() => setShowthelist('Register')}>
              <h4>Registered Slot <span>:</span> </h4>
              <p> {counts.Booked}</p>
            </div>
            <div className="app_d_week_1" onClick={() => setShowthelist('Request')}>
              <h4>Requested Slot <span>:</span> </h4>
              <p> {counts.Requested}</p>
            </div>
            <div className="app_d_week_1" onClick={() => setShowthelist('Cancelled')}>
              <h4>Canceled Slot<span>:</span> </h4>
              <p> {counts.Canceled}</p>
            </div>
            <h4 style={{ color: 'var(--ProjectColor)',marginTop:'15px' }}>{Showthelist} List</h4>
            {appointmentData.length > 0 ? (
              Showthelist === 'Register' ? (
                <div className="app_week_det_1">
                  {appointmentData[0].Register_patient_data.length!==0?
                  appointmentData[0].Register_patient_data.map((appointment, index) => (
                    <div key={index} className="app_week_det_2" onClick={() => handlePatients(appointment.patientname)}>
                      <span>{index + 1}.</span>
                      <span style={{ color: "#000", fontSize: "14px" }}>{appointment.patientname}</span>
                      <span style={{ color: "grey" }}>{appointment.time}</span>
                    </div>
                  )):
                  <div > No Appoinment Register</div>
                }
                </div>
              ):Showthelist === 'Request' ?(
                <div className="app_week_det_1">
                  {appointmentData[0].Request_patient_data.length!== 0?
                  appointmentData[0].Request_patient_data.map((appointment, index) => (
                    <div key={index} className="app_week_det_2" onClick={() => handlePatients(appointment.patientname)}>
                      <span>{index + 1}.</span>
                      <span style={{ color: "hsl(33,100%,50%)", fontSize: "14px" ,width:'calc(100% - 40px)',textAlign:'center'}}>{appointment.patientname}</span>
                    </div>
                  ))
                  : 
                  <div > No Appoinment Request</div>
                }
                </div>
              ):
              <div className="app_week_det_1">
                   {appointmentData[0].Canceled_Register_patient_data.length!== 0?
                  appointmentData[0].Canceled_Register_patient_data.map((appointment, index) => (
                    <div key={index} className="app_week_det_2" onClick={() => handlePatients(appointment.patientname)}>
                      <span>{index + 1}.</span>
                      <span style={{ color: "hsl(33,100%,50%)", fontSize: "14px" ,width:'calc(100% - 40px)',textAlign:'center'}}>{appointment.patientname}</span>
                    </div>
                  ))
                  : 
                  <div > No Cancelled Appoinment </div>
                }
                </div>
            ) : (
              <div className="app_week_det_1">No appointment for this date.</div>
            )}
          </div>
        
      </>
    );
};




  const handlePatients=(patient)=>{
    if(Showthelist ===  'Register'){
      navigate('/Home/Appoinment-RegisterList')

      dispatchvalue({type:'SelectedPatientCalender' , value: (patient) })
    }else if(Showthelist === 'Request'){
      navigate('/Home/Appoinment-RequestList')

      dispatchvalue({type:'SelectedPatientCalender' , value: (patient) })
    }
    
  }
  return (
    <>
      <div className="calendar_weekly">
        {renderHeader()}
        <div className=" days_date">
          <div className="new_days_date">
          {renderDays()}
          {renderCells()}
          </div>
        </div>
        {renderAppointmentDetails()}
      </div>
    </>
  );
};

export default React.memo(Weeks);