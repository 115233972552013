import {combineReducers} from 'redux';

import userRecorddata from './UserrecordStore';
import FinanceStore from './FinanceStore';
import IpManagement from './IpManagement';
import InsuranceStore from './InsuranceStore';


const Rootreducer = combineReducers({
    userRecord : userRecorddata,
    InPatients:IpManagement,
    FinanceStore: FinanceStore,
    InsuranceStore: InsuranceStore,


});
export default Rootreducer;