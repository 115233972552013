import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
// import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const IndentRecieveMaster = ( ) => {

  const userRecord = useSelector(state=>state.userRecord?.UserData)

  const IndentRecieveListData = useSelector(state=>state.userRecord?.IndentRecieveListData)

  const navigate = useNavigate();

  const [datalist, setdatalist] = useState([]);

  const cu_date = format(new Date(), 'yyyy-MM-dd');

  console.log('vathuruchu', IndentRecieveListData)

console.log(datalist)

  const [isedit, setisedit] = useState(null)

  const [OverallPurchaseRecieveFormData, setOverallPurchaseRecieveFormData] = useState({
    Indent_Issue_Invoice_NO: '',
    Indent_Issue_Date: cu_date,
    ProductCategory: '',
    ProductCategoryType: '',
    IndentRaiseInvoice: '',
    Request_From: '',
    Request_Raise_Date: '',
    Request_To: '',
    CreatedBy: '',
    Location: userRecord?.location,
    RecievedBy: userRecord?.username,
    Finel_NetAmount: '',
    Issue_From: '',
    Issue_To: '',

  })



  const [PurchaseRecieveFormData, setPurchaseRecieveFormData] = useState({
    Item_Code: '',
    Item_Name: '',
    Generic_Name: '',
    Strength: '',
    UOM: '',
    Pack_type: '',
    Pack_Quantity: '',
    Order_Quantity: '',
    TotalQuantity: '',
    Issue_Quantity: '',
    Issue_TotalQuantity: '',
    HSNCode: '',
    BatchNo: '',
    ManufactureDate: '',
    ExpiryDate: '',
    MRP: '',
    Tax_Percentage: '',
    Selling_Rate: '',
    Taxable_Selling_Rate: '',
    TotalAmount: '',
    RecievedQuantity: '',
    RecievedTotalQuantity: '',
    PendingQuantity: '',
    Reason: '',
    RecievedQuantityAmount: ''

  })

  console.log('qqq', OverallPurchaseRecieveFormData)

  const [PurchaseRecieveFormData1, setPurchaseRecieveFormData1] = useState({
    Item_Code: '',
    Item_Name: '',
    Generic_Name: '',
    Pack_type: '',
    Pack_Quantity: '',
    Order_Quantity: '',
    TotalQuantity: '',
    Issue_Quantity: '',
    Issue_TotalQuantity: '',
    HSNCode: '',
    BatchNo: '',
    ManufactureDate: '',
    ExpiryDate: '',
    MRP: '',
    Tax_Percentage: '',
    Selling_Rate: '',
    Taxable_Selling_Rate: '',
    TotalAmount: '',
    RecievedQuantity: '',
    RecievedTotalQuantity: '',
    PendingQuantity: '',
    Reason: '',
    RecievedQuantityAmount: ''

  })

  useEffect(()=>{

    if(+PurchaseRecieveFormData.PendingQuantity === 0){
        setPurchaseRecieveFormData((prev)=>({
            ...prev,
            Reason:'Nill',
        }))
    }else{
        setPurchaseRecieveFormData((prev)=>({
            ...prev,
            Reason:'',
        }))
    }

},[PurchaseRecieveFormData.PendingQuantity])


  useEffect(()=>{

    if(+PurchaseRecieveFormData1.PendingQuantity === 0){
        setPurchaseRecieveFormData1((prev)=>({
            ...prev,
            Reason:'Nill',
        }))
    }else{
        setPurchaseRecieveFormData1((prev)=>({
            ...prev,
            Reason:'',
        }))
    }

},[PurchaseRecieveFormData1.PendingQuantity])




  const [datalist1, setdatalist1] = useState([])
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const showdown = datalist.length;
  const showdown1 = datalist1.length;
  const totalPages = Math.ceil(datalist.length / 10);
  const totalPages1 = Math.ceil(datalist1.length / 10);

  console.log('cu_date:', datalist1)


  useEffect(() => {

    console.log('qwe', IndentRecieveListData)


    if (IndentRecieveListData && Object.keys(IndentRecieveListData).length !== 0) {


      setOverallPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        Indent_Issue_Invoice_NO: IndentRecieveListData.IndentIssue_InvoiceNo,
        ProductCategory: IndentRecieveListData.Product_Category,
        ProductCategoryType: IndentRecieveListData.ProductCategoryType,
        IndentRaiseInvoice: IndentRecieveListData.Raise_Invoice_No,
        Request_From: IndentRecieveListData.Request_From,
        Request_Raise_Date: IndentRecieveListData.Request_Rise_Date,
        Request_To: IndentRecieveListData.Request_To,
        Issue_To: IndentRecieveListData.Issue_To,
        RecievedBy: userRecord?.username,
        Finel_NetAmount: IndentRecieveListData.Finel_NetAmount,
        Issue_From: IndentRecieveListData.Issue_From,
      }));

      const A_data = IndentRecieveListData.items

      const b_data = A_data.map((p, index) => {
        const {Order_Quantity, Approved_Quantity, Pack_Quantity, ...rest } = p;

        // Calculate TotalQuantity based on Approved_Quantity and Pack_Quantity
        const TotalQuantity = Order_Quantity * Pack_Quantity;

        return {
          id: index + 1,
          ...rest,
          Order_Quantity:Order_Quantity,
          Pack_Quantity: Pack_Quantity,  // Include Pack_Quantity in b_data
          TotalQuantity: TotalQuantity
        };
      });

      console.log('A_data', b_data)

      setdatalist(b_data)




    }

  }, [IndentRecieveListData])













  const dynamicColumns = () => {
    if (OverallPurchaseRecieveFormData.ProductCategoryType === 'MedicalConsumable' ||
    OverallPurchaseRecieveFormData.ProductCategoryType === 'MedicalNonConsumable') {
      return (
        [
          { field: 'Item_Code', headerName: 'Item Code', width: 200 },
          { field: 'Item_Name', headerName: 'Item Name', width: 200 },
          { field: 'Generic_Name', headerName: 'Generic Name', width: 150 },
          { field: 'Strength', headerName: 'Strength', width: 120 },
          { field: 'UOM', headerName: 'UOM', width: 120 },
          { field: 'Pack_type', headerName: 'Pack Type', width: 150 },
          { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 140 },
          { field: 'Issue_Quantity', headerName: 'Issue Quantity', width: 140 },
          { field: 'Issue_TotalQuantity', headerName: 'Issue TotalQuantity', width: 140 },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
              <>
                <Button className='cell_btn' onClick={() => handleRequestEdit(params.row)}>
                  <EditIcon />
                </Button>
              </>
            ),
          },
        ]
      )
    } else if (OverallPurchaseRecieveFormData.ProductCategoryType === 'NonMedicalConsumable' ||
    OverallPurchaseRecieveFormData.ProductCategoryType === 'NonMedicalNonConsumable' ||
    OverallPurchaseRecieveFormData.ProductCategoryType === 'Surgical') {
      return (
        [
          { field: 'Item_Code', headerName: 'Item Code', width: 200 },
          { field: 'Item_Name', headerName: 'Item Name', width: 200 },
          { field: 'Generic_Name', headerName: 'Generic Name', width: 150 },
          { field: 'Pack_type', headerName: 'Pack Type', width: 150 },
          { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 140 },
          { field: 'Issue_Quantity', headerName: 'Issue Quantity', width: 140 },
          { field: 'Issue_TotalQuantity', headerName: 'Issue TotalQuantity', width: 140 },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
              <>
                <Button className='cell_btn' onClick={() => handleRequestEditNon(params.row)}>
                  <EditIcon />
                </Button>
              </>
            ),
          },
        ]
      )
    }  
    
    
    
    else {
      return (
        [
          { field: 'Item_Code', headerName: 'Item Code', width: 200 },
          { field: 'Item_Name', headerName: 'Item Name', width: 200 },
          { field: 'Pack_type', headerName: 'Pack Type', width: 170 },
          { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 170 },
          { field: 'Order_Quantity', headerName: 'Order_Quantity', width: 170 },
          { field: 'TotalQuantity', headerName: 'Total Quantity', width: 140 },
          { field: 'Issue_Quantity', headerName: 'Issued Quantity', width: 140 },
          { field: 'Issue_TotalQuantity', headerName: 'Issued Total Quantity', width: 140 },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
              <>
                <Button className='cell_btn' onClick={() => handleRequestEditNon(params.row)}>
                  <EditIcon />
                </Button>
              </>
            ),
          },
        ]
      )
    }
  };
  const dynamicColumns1 = () => {
    if (OverallPurchaseRecieveFormData.ProductCategoryType === 'MedicalConsumable' ||
    OverallPurchaseRecieveFormData.ProductCategoryType === 'MedicalNonConsumable') {
      return (
        [
          { field: 'Item_Code', headerName: 'Item Code', width: 200 },
          { field: 'Item_Name', headerName: 'Item Name', width: 200 },
          { field: 'Generic_Name', headerName: 'Generic Name', width: 150 },
          { field: 'Strength', headerName: 'Strength', width: 120 },
          { field: 'UOM', headerName: 'UOM', width: 100 },
          { field: 'ManufactureDate', headerName: 'Manufacture Date', width: 170 },
          { field: 'ExpiryDate', headerName: 'Expiry Date', width: 150 },
          { field: 'HSNCode', headerName: 'HSN Code', width: 170 },
          { field: 'BatchNo', headerName: 'Batch No', width: 170 },
          { field: 'Pack_type', headerName: 'Pack Type', width: 150 },
          { field: 'Order_Quantity', headerName: 'Order Quantity', width: 150 },
          { field: 'Issue_Quantity', headerName: 'Recieved Quantity', width: 170 },
          { field: 'MRP', headerName: 'MRP', width: 150 },
          { field: 'Tax_Percentage', headerName: 'Tax Percentage', width: 170 },
          { field: 'Taxable_Selling_Rate', headerName: 'Taxable Selling Rate', width: 170 },
          { field: 'TotalAmount', headerName: 'Total Amount', width: 170 },
          { field: 'RecievedQuantity', headerName: 'Recieved Quantity', width: 170 },
          { field: 'RecievedTotalQuantity', headerName: 'Recieved Total Quantity', width: 170 },
          { field: 'PendingQuantity', headerName: 'Pending Quantity', width: 170 },
          { field: 'Reason', headerName: 'Reason', width: 170 },
          { field: 'RecievedQuantityAmount', headerName: 'Recieved Quantity Amount', width: 170 },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
              <>
                <Button className='cell_btn' onClick={() => handleRequestEdit1(params.row)}>
                  <EditIcon />
                </Button>
              </>
            ),
          },
        ]
      )
    } else if (OverallPurchaseRecieveFormData.ProductCategoryType === 'NonMedicalConsumable' ||
    OverallPurchaseRecieveFormData.ProductCategoryType === 'NonMedicalNonConsumable' ||
    OverallPurchaseRecieveFormData.ProductCategoryType === 'Surgical') {
      return (
        [
          { field: 'Item_Code', headerName: 'Item Code', width: 200 },
          { field: 'Item_Name', headerName: 'Item Name', width: 200 },
          { field: 'Generic_Name', headerName: 'Generic Name', width: 150 },
          { field: 'ManufactureDate', headerName: 'Manufacture Date', width: 170 },
          { field: 'ExpiryDate', headerName: 'Expiry Date', width: 150 },
          { field: 'HSNCode', headerName: 'HSN Code', width: 170 },
          { field: 'BatchNo', headerName: 'Batch No', width: 170 },
          { field: 'Pack_type', headerName: 'Pack Type', width: 150 },
          { field: 'Order_Quantity', headerName: 'Order Quantity', width: 150 },
          { field: 'Issue_Quantity', headerName: 'Recieved Quantity', width: 170 },
          { field: 'MRP', headerName: 'MRP', width: 150 },
          { field: 'Tax_Percentage', headerName: 'Tax Percentage', width: 170 },
          { field: 'Taxable_Selling_Rate', headerName: 'Taxable Selling Rate', width: 170 },
          { field: 'TotalAmount', headerName: 'Total Amount', width: 170 },
          { field: 'RecievedQuantity', headerName: 'Recieved Quantity', width: 170 },
          { field: 'RecievedTotalQuantity', headerName: 'Recieved Total Quantity', width: 170 },
          { field: 'PendingQuantity', headerName: 'Pending Quantity', width: 170 },
          { field: 'Reason', headerName: 'Reason', width: 170 },
          { field: 'RecievedQuantityAmount', headerName: 'Recieved Quantity Amount', width: 170 },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
              <>
                <Button className='cell_btn' onClick={() => handleRequestEditNon1(params.row)}>
                  <EditIcon />
                </Button>
              </>
            ),
          },
        ]
      )
    } 
    
    
    
    else {
      return (
        [
          { field: 'Item_Code', headerName: 'Item Code', width: 200 },
          { field: 'Item_Name', headerName: 'Item Name', width: 200 },
          { field: 'Pack_type', headerName: 'Pack Type', width: 170 },
          { field: 'Order_Quantity', headerName: 'Pack Quantity', width: 170 },
          { field: 'Issue_Quantity', headerName: 'Recieved Quantity', width: 170 },
          { field: 'MRP', headerName: 'MRP', width: 170 },
          { field: 'TotalAmount', headerName: 'Total Amount', width: 170 },
          { field: 'RecievedQuantity', headerName: 'Recieved Quantity', width: 170 },
          { field: 'RecievedTotalQuantity', headerName: 'Recieved Total Quantity', width: 170 },
          { field: 'Reason', headerName: 'Reason', width: 170 },
          { field: 'RecievedQuantityAmount', headerName: 'Recieved Quantity Amount', width: 170 },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
              <>
                <Button className='cell_btn' onClick={() => handleRequestEditNon1(params.row)}>
                  <EditIcon />
                </Button>
              </>
            ),
          },
        ]
      )
    }
  };


  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };


  const handlechangeissueto = (e) => {
    const { name, value } = e.target;

    setOverallPurchaseRecieveFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

  }


  const handleInputFormChange = (e) => {
    const { name, value } = e.target;

    if (name === 'RecievedQuantity') {
      if (+PurchaseRecieveFormData.Order_Quantity >= +value) {
        if (+value <= +PurchaseRecieveFormData.Order_Quantity) {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
        RecievedTotalQuantity: +value * +prev.Pack_Quantity,

        PendingQuantity: +prev.Order_Quantity >= +value ? +prev.Order_Quantity - +value : 0,
        RecievedQuantityAmount: ((+value * +prev.Pack_Quantity) * parseFloat(prev.Selling_Rate)).toFixed(3)
      }));
    }else {
      setPurchaseRecieveFormData((prev) => ({
          ...prev,
          [name]: value,
          PendingQuantity: 0,
      }));
  } 
}
else{
    alert('Enter Correct Recieved Quantity')
}
}else {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }



  };










  const handleInputFormNonChange = (e) => {
    const { name, value } = e.target
    if (name === 'RecievedQuantity') {
      if (+PurchaseRecieveFormData1.Order_Quantity >= +value) {
        if (+value <= +PurchaseRecieveFormData1.Order_Quantity) {
      setPurchaseRecieveFormData1((prev) => ({
        ...prev,
        [name]: value,
        RecievedTotalQuantity: +value * +prev.Pack_Quantity,
        PendingQuantity: +prev.Order_Quantity >= +value ? +prev.Order_Quantity - +value : 0,
        RecievedQuantityAmount: ((+value * +prev.Pack_Quantity) * parseFloat(prev.Selling_Rate||1)).toFixed(3)
      }));
    }else {
      setPurchaseRecieveFormData1((prev) => ({
          ...prev,
          [name]: value,
          PendingQuantity: 0,
      }));
  } 
} else{
  alert('Enter Correct Recieved Quantity')
}
}else if(name==='Reason'){
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

  }






  const handleRequestEdit = (params) => {
    console.log('deede',params)
    setPurchaseRecieveFormData({
      Item_Code: params.Item_Code,
      Item_Name: params.Item_Name,
      Generic_Name: params.Generic_Name,
      Strength: params.Strength,
      UOM: params.UOM,
      Pack_type: params.Pack_type,
      Pack_Quantity: params.Pack_Quantity,
      Order_Quantity: params.Order_Quantity,
      TotalQuantity: params.TotalQuantity,
      Issue_Quantity: params.Issue_Quantity,
      Issue_TotalQuantity: params.Issue_TotalQuantity,
      HSNCode: params.HSN_Code,
      BatchNo: params.Batch_No,
      ManufactureDate: params.ManufactureDate,
      ExpiryDate: params.ExpiryDate,
      MRP: params.MRP_Per_Quantity,
      Tax_Percentage: params.Tax_Percentage,
      Selling_Rate: params.Selling_Rate,
      Taxable_Selling_Rate: params.Taxable_Selling_Rate,
      TotalAmount: params.TotalAmount,
      RecievedQuantity: '',
      RecievedTotalQuantity: '',
      PendingQuantity: '',
      Reason: '',
      RecievedQuantityAmount: ''
    })
  }
  const handleRequestEdit1 = (params) => {
    setPurchaseRecieveFormData({ ...params });
    setisedit(params);
  }

  const handleAdd = () => {
    const requiredFields = [

      'RecievedQuantity',
      'RecievedTotalQuantity',
      'RecievedQuantityAmount'
    ];



    if(PurchaseRecieveFormData.PendingQuantity!==0){
      requiredFields.push('Reason')
    }


    const missingFields = requiredFields.filter((field) => !PurchaseRecieveFormData[field]);

    if (missingFields.length === 0) {
      console.log('PurchaseRecieveFormData', PurchaseRecieveFormData)
      let id = 0
      const data = { 'id': datalist1.length + 1, ...PurchaseRecieveFormData }

      console.log('dataArray', data)
      setdatalist1((prev) => [...prev, data]);
      setdatalist((prev) => prev.filter((item) => item.Item_Code !== PurchaseRecieveFormData.Item_Code));
      handleClear()
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
    }
  };

  const handleUpdate = () => {
    const requiredFields = [
      'RecievedQuantity',
      'RecievedTotalQuantity',
      'RecievedQuantityAmount'
    ];


    if(PurchaseRecieveFormData.PendingQuantity!==0){
      requiredFields.push('Reason')
    }


    const missingFields = requiredFields.filter((field) => !PurchaseRecieveFormData[field]);

    if (missingFields.length === 0) {
      const indexOfItemToUpdate = datalist1.findIndex((item) => item.Item_Code === isedit.Item_Code);
      setdatalist1((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData;
        return updatedCart;
      });
      setisedit(null);
      handleClear()
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
    }
  }


  // non medical
  const handleRequestEditNon = (params) => {
    console.log(params)
    setPurchaseRecieveFormData1({
      Item_Code: params.Item_Code,
      Item_Name: params.Item_Name,
      Generic_Name: params.Generic_Name,
      Pack_type: params.Pack_type,
      Pack_Quantity: params.Pack_Quantity,
      Order_Quantity: params.Order_Quantity,
      TotalQuantity: params.TotalQuantity,
      Issue_Quantity: params.Issue_Quantity,
      Issue_TotalQuantity: params.Issue_TotalQuantity,
      HSNCode: params.HSN_Code,
      BatchNo: params.Batch_No,
      ManufactureDate: params.ManufactureDate,
      ExpiryDate: params.ExpiryDate,
      MRP: params.MRP_Per_Quantity,
      Tax_Percentage: params.Tax_Percentage,
      Selling_Rate: params.Selling_Rate,
      Taxable_Selling_Rate: params.Taxable_Selling_Rate,
      TotalAmount: params.TotalAmount,
      RecievedQuantity: '',
      RecievedTotalQuantity: '',
      PendingQuantity: '',
      Reason: '',
      RecievedQuantityAmount: ''
    })
  }
  const handleRequestEditNon1 = (params) => {
    setPurchaseRecieveFormData1({ ...params });
    setisedit(params);
  }

  const handleClear = () => {
    if (OverallPurchaseRecieveFormData.ProductCategoryType === 'MedicalConsumable' ||
    OverallPurchaseRecieveFormData.ProductCategoryType === 'MedicalNonConsumable'  
   ) {
      setPurchaseRecieveFormData({
        Item_Code: '',
        Item_Name: '',
        Generic_Name: '',
        Strength: '',
        UOM: '',
        Pack_type: '',
        Pack_Quantity: '',
        Order_Quantity: '',
        TotalQuantity: '',
        Issue_Quantity: '',
        Issue_TotalQuantity: '',
        HSNCode: '',
        BatchNo: '',
        ManufactureDate: '',
        ExpiryDate: '',
        MRP: '',
        Tax_Percentage: '',
        Selling_Rate: '',
        Taxable_Selling_Rate: '',
        TotalAmount: '',
        RecievedQuantity: '',
        RecievedTotalQuantity: '',
        PendingQuantity: '',
        Reason: '',
        RecievedQuantityAmount: ''
      })
    } else if (OverallPurchaseRecieveFormData.ProductCategoryType === 'NonMedicalConsumable' ||
    OverallPurchaseRecieveFormData.ProductCategoryType === 'NonMedicalNonConsumable' ||
    OverallPurchaseRecieveFormData.ProductCategoryType === 'Surgical'  
   ) {
      setPurchaseRecieveFormData1({
        Item_Code: '',
        Item_Name: '',
        Generic_Name: '',
        Pack_type: '',
        Pack_Quantity: '',
        Order_Quantity: '',
        TotalQuantity: '',
        Issue_Quantity: '',
        Issue_TotalQuantity: '',
        HSNCode: '',
        BatchNo: '',
        ManufactureDate: '',
        ExpiryDate: '',
        MRP: '',
        Tax_Percentage: '',
        Selling_Rate: '',
        Taxable_Selling_Rate: '',
        TotalAmount: '',
        RecievedQuantity: '',
        RecievedTotalQuantity: '',
        PendingQuantity: '',
        Reason: '',
        RecievedQuantityAmount: ''
      })
    }
    
    
    else {
      setPurchaseRecieveFormData1({
        Item_Code: '',
        Item_Name: '',
        Pack_type: '',
        Pack_Quantity: '',
        Order_Quantity: '',
        TotalQuantity: '',
        Issue_Quantity: '',
        Issue_TotalQuantity: '',
        MRP: '',
        TotalAmount: '',
        RecievedQuantity: '',
        RecievedTotalQuantity: '',
        PendingQuantity: '',
        Reason: '',
        RecievedQuantityAmount: ''
      })
    }
  }


  const handleAddNon = () => {
    const requiredFields = [
      'RecievedQuantity',
      'RecievedTotalQuantity',
      'RecievedQuantityAmount'
    ];
    if(PurchaseRecieveFormData1.PendingQuantity!==0){
      requiredFields.push('Reason')
    }


    const missingFields = requiredFields.filter((field) => !PurchaseRecieveFormData1[field]);

    if (missingFields.length === 0) {
      const data = { 'id': datalist1.length + 1, ...PurchaseRecieveFormData1 }
      setdatalist1((prev) => [...prev, data]);
      setdatalist((prev) => prev.filter((item) => item.Item_Code !== PurchaseRecieveFormData1.Item_Code));
      handleClear()
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
    }
  };
  const handleUpdateNon = () => {
    const requiredFields = [
      'RecievedQuantity',
      'RecievedTotalQuantity',
      'RecievedQuantityAmount'
    ];


    if(PurchaseRecieveFormData1.PendingQuantity!==0){
      requiredFields.push('Reason')
    }


    const missingFields = requiredFields.filter((field) => !PurchaseRecieveFormData1[field]);

    if (missingFields.length === 0) {
      const indexOfItemToUpdate = datalist1.findIndex((item) => item.Item_Code === isedit.Item_Code);
      setdatalist1((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData1;
        return updatedCart;
      });
      setisedit(null);
      handleClear()
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
    }
  }

  // const handleExportToExcel = () => {
  //     if(datalist1.length !==0){
  //         let columns;
  //         if(OverallPurchaseRecieveFormData.ProductCategory === 'Medical'){
  //            columns = [
  //                 { header: 'Item Code', dataKey: 'Item_Code' },
  //                 { header: 'Item Name', dataKey: 'Item_Name' },
  //                 { header: 'Generic_Name', dataKey: 'Generic_Name' },
  //                 { header: 'Strength', dataKey: 'Strength' },
  //                 { header: 'UOM', dataKey: 'UOM' },
  //                 { header: 'ManufactureDate', dataKey: 'ManufactureDate' },
  //                 { header: 'ExpiryDate', dataKey: 'ExpiryDate' },
  //                 { header: 'HSNCode', dataKey: 'HSNCode' },
  //                 { header: 'BatchNo', dataKey: 'BatchNo' },
  //                 { header: 'Pack_type', dataKey: 'Pack' },
  //                 { header: 'Order_Quantity', dataKey: 'Order_Quantity' },
  //                 { header: 'PerOrder_Quantity', dataKey: 'PerOrder_Quantity' },
  //                 { header: 'RecievedPack', dataKey: 'RecievedPack' },
  //                 { header: 'Issue_Quantity', dataKey: 'Issue_Quantity' },
  //                 { header: 'RecievedPerOrder_Quantity', dataKey: 'RecievedPerOrder_Quantity' },
  //                 { header: 'PendingQuantity', dataKey: 'PendingQuantity' },
  //                 { header: 'Reason', dataKey: 'Reason' },
  //                 { header: 'MRP', dataKey: 'MRP' },
  //                 { header: 'GST', dataKey: 'GST' },
  //                 { header: 'IGST', dataKey: 'IGST' },
  //                 { header: 'DiscountType', dataKey: 'DiscountType' },
  //                 { header: 'Discount', dataKey: 'Discount' },
  //                 { header: 'TotalAmount', dataKey: 'TotalAmount' },
  //               ];
  //         }else{
  //             columns = [
  //                 { header: 'Item Code', dataKey: 'Item_Code' },
  //                 { header: 'Item Name', dataKey: 'Item_Name' },
  //                 { header: 'Pack_type', dataKey: 'Pack' },
  //                 { header: 'Order_Quantity', dataKey: 'Order_Quantity' },
  //                 { header: 'PerOrder_Quantity', dataKey: 'PerOrder_Quantity' },
  //                 { header: 'RecievedPack', dataKey: 'RecievedPack' },
  //                 { header: 'Issue_Quantity', dataKey: 'Issue_Quantity' },
  //                 { header: 'RecievedPerOrder_Quantity', dataKey: 'RecievedPerOrder_Quantity' },
  //                 { header: 'PendingQuantity', dataKey: 'PendingQuantity' },
  //                 { header: 'Reason', dataKey: 'Reason' },
  //                 { header: 'MRP', dataKey: 'MRP' },
  //                 { header: 'GST', dataKey: 'GST' },
  //                 { header: 'IGST', dataKey: 'IGST' },
  //                 { header: 'DiscountType', dataKey: 'DiscountType' },
  //                 { header: 'Discount', dataKey: 'Discount' },
  //                 { header: 'TotalAmount', dataKey: 'TotalAmount' },
  //               ];
  //         }

  //         const header = columns.map(col => col.header);

  //         const csv = [
  //             `ProductCategory : ${OverallPurchaseRecieveFormData.ProductCategory}`,
  //             `IndentRaiseInvoice : ${OverallPurchaseRecieveFormData.IndentRaiseInvoice}`, 
  //             `PurchaseRaiseDate : ${OverallPurchaseRecieveFormData.Purchaserise_date}`, 
  //             `Raised By : ${OverallPurchaseRecieveFormData.CreatedBy}`,
  //             `SupplierCode : ${OverallPurchaseRecieveFormData.SupplierCode}`,
  //             `Request_From : ${OverallPurchaseRecieveFormData.Request_From}`,
  //             `GRN Invoice : ${OverallPurchaseRecieveFormData.PurchaseRecieveInvoice}`,
  //             `GRN Date : ${OverallPurchaseRecieveFormData.Purchaserecieve_date}`,
  //             `GRN Recieved By : ${OverallPurchaseRecieveFormData.RecievedBy}`,
  //             " ",
  //             '\ufeff' + header.join(","), // BOM + header row
  //             ...datalist1.map(row => columns.map(col => row[col.dataKey])),
  //             '', // Empty row for spacing
  //         ].join("\r\n");

  //         var data = new Blob([csv], { type: "text/csv" });
  //         // Fix the template literal for saveAs function
  //         saveAs(data, `Report_${new Date().toISOString()}.csv`);
  //     }else{
  //         alert('No Data to Save')
  //     }
  // };





  const handleSubmit = () => {


    const newdataa = {

      ...OverallPurchaseRecieveFormData,
      Status: 'Recieved',
      Location: userRecord?.location,
      Items: [...datalist1]
    };
    console.log(newdataa)
    const updatedate={
      ProductType:OverallPurchaseRecieveFormData.ProductCategory,
      ProductCategoryType: OverallPurchaseRecieveFormData.ProductCategoryType,
      IndentIssue_InvoiceNo:OverallPurchaseRecieveFormData.Indent_Issue_Invoice_NO,
      Status:'Recieved'
    }
    axios.post(`https://clinic.vesoftometic.co.in/IndentRaise/Update_indent_Recieve_confirm_to_approve`,updatedate)
        .then((response)=>{
            console.log(response.data)
  })
  .catch((error)=>{
    console.log(error)
  })
    axios.post('https://clinic.vesoftometic.co.in/IndentRaise/Insert_Indent_Recieve_detials', newdataa) 
        .then((response) => {
        console.log(response.data);
        navigate('/Home/Indent_Recieve_list');
        })
        .catch((error) => {
        console.error('Error posting data:', error);

        });

    console.log(newdataa);



  };


  return (
    <>

      <div className="Supplier_Master_Container">
        <div className="Product_Master_h_head">
          <h3>Indent Recieve Invoice</h3>
        </div>
        <div className='Stoct_Product_master_Container'>
          <div className='Stoct_Product_master_form'>
            <div className="Stoct_Product_master_form_row">

              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Indent Issue Invoice No :</label>
                <input
                  type="text"
                  name="Indent_Issue_Invoice_NO"
                  placeholder="Enter Product Category"
                  value={OverallPurchaseRecieveFormData.Indent_Issue_Invoice_NO}
                  readOnly
                  className='Stoct_Product_master_form_Input'
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Indent Issue Date :</label>
                <input
                  type="date"
                  name="Indent_Issue_Date"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.Indent_Issue_Date}
                  readOnly
                  className='Stoct_Product_master_form_Input'
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Product Category :</label>
                <input
                  type="text"
                  name="ProductCategory"
                  placeholder="Enter Product Category"
                  value={OverallPurchaseRecieveFormData.ProductCategory}
                  readOnly
                  className='Stoct_Product_master_form_Input'
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Raise Invoice No :</label>
                <input
                  type="text"
                  name="IndentRaiseInvoice"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.IndentRaiseInvoice}
                  readOnly
                  className='Stoct_Product_master_form_Input'
                />
              </div>

            </div>

          </div>
        </div>

        <div className='Stoct_Product_master_Container'>
          <div className='Stoct_Product_master_form'>
            <div className="Stoct_Product_master_form_row">

              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Request From :</label>
                <input
                  type="text"
                  name="Request_From"
                  placeholder="Enter Supplier Name"
                  value={OverallPurchaseRecieveFormData.Request_From}
                  readOnly
                  className='Stoct_Product_master_form_Input'
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Request Raise Date :</label>
                <input
                  type="date"
                  name="Request_Raise_Date"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.Request_Raise_Date}
                  className='Stoct_Product_master_form_Input'
                  readOnly
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Request To :</label>
                <input
                  type="text"
                  name="Request_To"
                  placeholder="Enter Supplier Code"
                  value={OverallPurchaseRecieveFormData.Request_To}
                  autoComplete='off'
                  className='Stoct_Product_master_form_Input'
                  readOnly
                />


              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Net Amount :</label>
                <input
                  type="number"
                  name="Finel_NetAmount"
                  placeholder="Enter Total Amount"
                  value={OverallPurchaseRecieveFormData.Finel_NetAmount}
                  readOnly
                  required
                  className='Stoct_Product_master_form_Input'
                />
              </div>


            </div>

          </div>
        </div>

        <div className='Stoct_Product_master_Container'>
          <div className='Stoct_Product_master_form'>
            <div className="Stoct_Product_master_form_row">

              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Issue From :</label>

                <input
                  type="text"
                  name="Issue_From"
                  placeholder="Enter Issue From "
                  onChange={handlechangeissueto}
                  value={OverallPurchaseRecieveFormData.Issue_From}
                  readOnly
                  required
                  className='Stoct_Product_master_form_Input'
                />
              </div>


              <div className="Stoct_Product_master_form_row_div">
                <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Issue To :</label>
                <input
                  type="text"
                  name="Issue_To"
                  placeholder="Enter Issue To"
                  value={OverallPurchaseRecieveFormData.Issue_To}
                  className='Stoct_Product_master_form_Input'
                  readOnly
                />
              </div>

            </div>

          </div>
        </div>


        <div className='IP_grid'>
          <ThemeProvider theme={theme}>
            <div className='IP_grid_1'>
              <DataGrid
                rows={datalist.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns()} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=' Ip_data_grid'
              />
              {showdown > 0 && datalist.length > 10 && (
                <div className='IP_grid_foot'>
                  <button
                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && datalist.length !== 0 ? (
            ''
          ) : (
            <div className='IP_norecords'>
              <span>No Records Found</span>
            </div>
          )}
        </div>

        {
          (OverallPurchaseRecieveFormData.ProductCategoryType === 'MedicalConsumable' ||
          OverallPurchaseRecieveFormData.ProductCategoryType === 'MedicalNonConsumable')  &&(

            <div className='Stoct_Product_master_Container'>
              <div className='Stoct_Product_master_form'>
                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Item Code :</label>
                    <input
                      type="text"
                      name="Item_Code"
                      placeholder="Enter Item Code"
                      value={PurchaseRecieveFormData.Item_Code}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Item Name :</label>
                    <input
                      type="text"
                      name="Item_Name"
                      placeholder="Enter Item Name"
                      value={PurchaseRecieveFormData.Item_Name}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Generic Name :</label>
                    <input
                      type="text"
                      name="Generic_Name"
                      placeholder="Enter Generic Name"
                      value={PurchaseRecieveFormData.Generic_Name}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Strength :</label>
                    <input
                      type="text"
                      name="Strength"
                      placeholder="Enter Strength"
                      value={PurchaseRecieveFormData.Strength}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                </div>


                <div className="Stoct_Product_master_form_row">

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">UOM :</label>
                    <input
                      type="text"
                      name="UOM"
                      placeholder="Enter UOM"
                      value={PurchaseRecieveFormData.UOM}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Pack Type :</label>
                    <input
                      type="text"
                      name="Pack_type"
                      placeholder="Enter Pack Type"
                      value={PurchaseRecieveFormData.Pack_type}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Pack Quantity :</label>
                    <input
                      type="number"
                      name="Pack_Quantity"
                      placeholder="Enter Pack Quantity"
                      value={PurchaseRecieveFormData.Pack_Quantity}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Order Quantity :</label>
                    <input
                      type="number"
                      name="Order_Quantity"
                      placeholder="Enter Order Quantity"
                      value={PurchaseRecieveFormData.Order_Quantity}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>






                </div>
                <div className="Stoct_Product_master_form_row">

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Total Quantity :</label>
                    <input
                      type="number"
                      name="TotalQuantity"
                      placeholder="Enter Total Quantity"
                      value={PurchaseRecieveFormData.TotalQuantity}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Batch No :</label>
                    <input
                      id="browser"
                      type="text"
                      name="BatchNo"
                      placeholder="Enter Batch No"
                      value={PurchaseRecieveFormData.BatchNo}
                      onChange={handleInputFormChange}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                      autoComplete='off'
                    />


                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">HSN Code :</label>
                    <input
                      type="text"
                      name="HSNCode"
                      placeholder="Enter HSNCode"
                      value={PurchaseRecieveFormData.HSNCode}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Manufacture Date :</label>
                    <input
                      type="date"
                      name="ManufactureDate"
                      placeholder="Enter ManufactureDate"
                      value={PurchaseRecieveFormData.ManufactureDate}
                      readOnly
                      required
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                </div>


                <div className="Stoct_Product_master_form_row">

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Expiry Date :</label>
                    <input
                      type="date"
                      name="ExpiryDate"
                      placeholder="Enter ExpiryDate"
                      value={PurchaseRecieveFormData.ExpiryDate}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>





                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Issue Quantity :</label>
                    <input
                      type="number"
                      name="Issue_Quantity"
                      placeholder="Enter Issue Pack Quantity "
                      value={PurchaseRecieveFormData.Issue_Quantity}
                      onChange={handleInputFormChange}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Issue Total Quantity :</label>
                    <input
                      type="number"
                      name="Issue_TotalQuantity"
                      placeholder="Enter Issue TotalQuantity"
                      value={PurchaseRecieveFormData.Issue_TotalQuantity}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">MRP (Per Quantity) :</label>
                    <input
                      type="number"
                      name="MRP"
                      placeholder="Enter MRP"
                      value={PurchaseRecieveFormData.MRP}
                      readOnly
                      required
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                </div>


                <div className="Stoct_Product_master_form_row">




                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Selling Rate (Per Quantity):</label>
                    <input
                      type="number"
                      name="Selling_Rate"
                      placeholder="Enter Selling Rate"
                      value={PurchaseRecieveFormData.Selling_Rate}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Tax Percentage</label>
                    <input
                      type="number"
                      name="Tax_Percentage"
                      placeholder="Enter Tax Percentage"
                      value={PurchaseRecieveFormData.Tax_Percentage}
                      readOnly
                      required
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>


                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Taxable Selling Rate :</label>
                    <input
                      type="number"
                      name="Taxable_Selling_Rate"
                      placeholder="Enter Taxable Selling Rate"
                      value={PurchaseRecieveFormData.Taxable_Selling_Rate}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>


                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Amount :</label>
                    <input
                      type="number"
                      name="TotalAmount"
                      placeholder="Enter Total Amount"
                      value={PurchaseRecieveFormData.TotalAmount}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                </div>
                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Recieved Quantity :</label>
                    <input
                      type="number"
                      name="RecievedQuantity"
                      placeholder="Enter Recieved Quantity "
                      value={PurchaseRecieveFormData.RecievedQuantity}
                      required
                      onChange={handleInputFormChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Pending Quantity :</label>
                    <input
                      type="number"
                      name="PendingQuantity"
                      placeholder="Enter Pending Quantity "
                      value={PurchaseRecieveFormData.PendingQuantity}
                      required
                      onChange={handleInputFormChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Pending Reason :</label>
                    <input
                      type="text"
                      name="Reason"
                      placeholder="Enter Reason "
                      value={PurchaseRecieveFormData.Reason}
                      required
                      disabled={PurchaseRecieveFormData.PendingQuantity===0 ||PurchaseRecieveFormData.PendingQuantity===''}
                      onChange={handleInputFormChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Recieved Quantity :</label>
                    <input
                      type="number"
                      name="RecievedTotalQuantity"
                      placeholder="Enter Total Recieved Quantity "
                      value={PurchaseRecieveFormData.RecievedTotalQuantity}
                      required
                      readOnly
                      onChange={handleInputFormChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  
                </div>
                <div className='Stoct_Product_master_form_row'>
                <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType"> Recieved Quantity Amount :</label>
                    <input
                      type="number"
                      name="RecievedQuantityAmount"
                      placeholder="Enter  Recieved Quantity Amount "
                      value={PurchaseRecieveFormData.RecievedQuantityAmount}
                      required
                      readOnly
                      onChange={handleInputFormChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                </div>


                <div className='Stoct_Product_master_form_Save'>
                  <button className=' Stoct_Product_master_form_Save_button' onClick={isedit ? handleUpdate : handleAdd} >
                    {isedit ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </div>
  )}



{
          (OverallPurchaseRecieveFormData.ProductCategoryType === 'NonMedicalConsumable' ||
          OverallPurchaseRecieveFormData.ProductCategoryType === 'NonMedicalNonConsumable' || 
          OverallPurchaseRecieveFormData.ProductCategoryType === 'Surgical')  &&(

            <div className='Stoct_Product_master_Container'>
              <div className='Stoct_Product_master_form'>
                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Item Code :</label>
                    <input
                      type="text"
                      name="Item_Code"
                      placeholder="Enter Item Code"
                      value={PurchaseRecieveFormData1.Item_Code}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Item Name :</label>
                    <input
                      type="text"
                      name="Item_Name"
                      placeholder="Enter Item Name"
                      value={PurchaseRecieveFormData1.Item_Name}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Generic Name :</label>
                    <input
                      type="text"
                      name="Generic_Name"
                      placeholder="Enter Generic Name"
                      value={PurchaseRecieveFormData1.Generic_Name}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Pack Type :</label>
                    <input
                      type="text"
                      name="Pack_type"
                      placeholder="Enter Pack Type"
                      value={PurchaseRecieveFormData1.Pack_type}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                </div>


                <div className="Stoct_Product_master_form_row">

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Pack Quantity :</label>
                    <input
                      type="number"
                      name="Pack_Quantity"
                      placeholder="Enter Pack Quantity"
                      value={PurchaseRecieveFormData1.Pack_Quantity}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Order Quantity :</label>
                    <input
                      type="number"
                      name="Order_Quantity"
                      placeholder="Enter Order Quantity"
                      value={PurchaseRecieveFormData1.Order_Quantity}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>



                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Total Quantity :</label>
                    <input
                      type="number"
                      name="TotalQuantity"
                      placeholder="Enter Total Quantity"
                      value={PurchaseRecieveFormData1.TotalQuantity}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Batch No :</label>
                    <input
                      id="browser"
                      type="text"
                      name="BatchNo"
                      placeholder="Enter Batch No"
                      value={PurchaseRecieveFormData1.BatchNo}
                      onChange={handleInputFormChange}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                      autoComplete='off'
                    />


                  </div>


                </div>
                <div className="Stoct_Product_master_form_row">

                

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">HSN Code :</label>
                    <input
                      type="text"
                      name="HSNCode"
                      placeholder="Enter HSNCode"
                      value={PurchaseRecieveFormData1.HSNCode}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Manufacture Date :</label>
                    <input
                      type="date"
                      name="ManufactureDate"
                      placeholder="Enter ManufactureDate"
                      value={PurchaseRecieveFormData1.ManufactureDate}
                      readOnly
                      required
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Expiry Date :</label>
                    <input
                      type="date"
                      name="ExpiryDate"
                      placeholder="Enter ExpiryDate"
                      value={PurchaseRecieveFormData1.ExpiryDate}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Issue Quantity :</label>
                    <input
                      type="number"
                      name="Issue_Quantity"
                      placeholder="Enter Issue Pack Quantity "
                      value={PurchaseRecieveFormData1.Issue_Quantity}
                      onChange={handleInputFormChange}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                </div>


                <div className="Stoct_Product_master_form_row">

                 
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Issue Total Quantity :</label>
                    <input
                      type="number"
                      name="Issue_TotalQuantity"
                      placeholder="Enter Issue TotalQuantity"
                      value={PurchaseRecieveFormData1.Issue_TotalQuantity}
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">MRP (Per Quantity) :</label>
                    <input
                      type="number"
                      name="MRP"
                      placeholder="Enter MRP"
                      value={PurchaseRecieveFormData1.MRP}
                      readOnly
                      required
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Selling Rate (Per Quantity):</label>
                    <input
                      type="number"
                      name="Selling_Rate"
                      placeholder="Enter Selling Rate"
                      value={PurchaseRecieveFormData1.Selling_Rate}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Tax Percentage</label>
                    <input
                      type="number"
                      name="Tax_Percentage"
                      placeholder="Enter Tax Percentage"
                      value={PurchaseRecieveFormData1.Tax_Percentage}
                      readOnly
                      required
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>


                </div>


                <div className="Stoct_Product_master_form_row">

                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Taxable Selling Rate :</label>
                    <input
                      type="number"
                      name="Taxable_Selling_Rate"
                      placeholder="Enter Taxable Selling Rate"
                      value={PurchaseRecieveFormData1.Taxable_Selling_Rate}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>


                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Amount :</label>
                    <input
                      type="number"
                      name="TotalAmount"
                      placeholder="Enter Total Amount"
                      value={PurchaseRecieveFormData1.TotalAmount}
                      required
                      readOnly
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Recieved Quantity :</label>
                    <input
                      type="number"
                      name="RecievedQuantity"
                      placeholder="Enter Recieved Quantity "
                      value={PurchaseRecieveFormData1.RecievedQuantity}
                      required
                      onChange={handleInputFormNonChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Pending Quantity :</label>
                    <input
                      type="number"
                      name="PendingQuantity"
                      placeholder="Enter Pending Quantity "
                      value={PurchaseRecieveFormData1.PendingQuantity}
                      required
                      onChange={handleInputFormNonChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                </div>
                <div className="Stoct_Product_master_form_row">
                  
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Pending Reason :</label>
                    <input
                      type="text"
                      name="Reason"
                      placeholder="Enter Reason "
                      value={PurchaseRecieveFormData1.Reason}
                      required
                      disabled={PurchaseRecieveFormData1.PendingQuantity===0 ||PurchaseRecieveFormData1.PendingQuantity===''}
                      onChange={handleInputFormNonChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Recieved Quantity :</label>
                    <input
                      type="number"
                      name="RecievedTotalQuantity"
                      placeholder="Enter Total Recieved Quantity "
                      value={PurchaseRecieveFormData1.RecievedTotalQuantity}
                      required
                      readOnly
                      onChange={handleInputFormNonChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType"> Recieved Quantity Amount :</label>
                    <input
                      type="number"
                      name="RecievedQuantityAmount"
                      placeholder="Enter  Recieved Quantity Amount "
                      value={PurchaseRecieveFormData1.RecievedQuantityAmount}
                      required
                      readOnly
                      onChange={handleInputFormNonChange}
                      className='Stoct_Product_master_form_Input'
                    />
                  </div>
                </div>
               


                <div className='Stoct_Product_master_form_Save'>
                  <button className=' Stoct_Product_master_form_Save_button' onClick={isedit ? handleUpdateNon : handleAddNon} >
                    {isedit ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </div>
  )}

  {
      (OverallPurchaseRecieveFormData.ProductCategoryType !== 'MedicalConsumable' &&
      OverallPurchaseRecieveFormData.ProductCategoryType !== 'MedicalNonConsumable' &&
      OverallPurchaseRecieveFormData.ProductCategoryType !== 'NonMedicalConsumable' &&
      OverallPurchaseRecieveFormData.ProductCategoryType !== 'NonMedicalNonConsumable' &&
      OverallPurchaseRecieveFormData.ProductCategoryType !== 'Surgical') &&(


        <div className='Stoct_Product_master_Container'>
        <div className='Stoct_Product_master_form'>
          <div className="Stoct_Product_master_form_row">
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Item Code :</label>
              <input
                type="text"
                name="Item_Code"
                placeholder="Enter Item Code"
                value={PurchaseRecieveFormData1.Item_Code}
                required
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="code">Item Name :</label>
              <input
                type="text"
                name="Item_Name"
                placeholder="Enter Item Name"
                value={PurchaseRecieveFormData1.Item_Name}
                required
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="code">Pack Type :</label>
              <input
                type="text"
                name="Pack_type"
                placeholder="Enter Pack Type"
                value={PurchaseRecieveFormData1.Pack_type}
                required
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>


            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Pack Quantity :</label>
              <input
                type="number"
                name="Pack_Quantity"
                placeholder="Enter Pack Quantity"
                value={PurchaseRecieveFormData1.Pack_Quantity}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>



          </div>



          <div className="Stoct_Product_master_form_row">

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Order Quantity :</label>
              <input
                type="number"
                name="Order_Quantity"
                placeholder="Enter Order Quantity"
                value={PurchaseRecieveFormData1.Order_Quantity}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>


            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Total Quantity :</label>
              <input
                type="number"
                name="TotalQuantity"
                placeholder="Enter Total Quantity"
                value={PurchaseRecieveFormData1.TotalQuantity}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>




            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="Item_Name">Issue Quantity :</label>
              <input
                type="number"
                name="Issue_Quantity"
                placeholder="Enter Issue Quantity"
                value={PurchaseRecieveFormData1.Issue_Quantity}
                onChange={handleInputFormNonChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="code">Issue Total Quantity :</label>
              <input
                type="number"
                name="Issue_TotalQuantity"
                placeholder="Enter Issue TotalQuantity"
                value={PurchaseRecieveFormData1.Issue_TotalQuantity}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>




          </div>

          <div className="Stoct_Product_master_form_row">




            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">MRP (Per Quantity) :</label>
              <input
                type="text"
                name="MRP"
                placeholder="Enter MRP"
                value={PurchaseRecieveFormData1.MRP}
                required
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Amount :</label>
              <input
                type="text"
                name="TotalAmount"
                placeholder="Enter Total Amount"
                value={PurchaseRecieveFormData1.TotalAmount}
                required
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Recieved Quantity :</label>
              <input
                type="number"
                name="RecievedQuantity"
                placeholder="Enter Recieved Quantity "
                value={PurchaseRecieveFormData1.RecievedQuantity}
                required
                onChange={handleInputFormNonChange}
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Pending Quantity :</label>
              <input
                type="number"
                name="PendingQuantity"
                placeholder="Enter Pending Quantity "
                value={PurchaseRecieveFormData1.PendingQuantity}
                required
                onChange={handleInputFormNonChange}
                className='Stoct_Product_master_form_Input'
              />
            </div>
          </div>
          <div className="Stoct_Product_master_form_row">
          <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="Reason">Pending Reason :</label>
              <input
                type="text"
                name="Reason"
                placeholder="Enter Reason "
                value={PurchaseRecieveFormData1.Reason}
                required
                disabled={PurchaseRecieveFormData1.PendingQuantity===0||PurchaseRecieveFormData1.PendingQuantity===''}
                onChange={handleInputFormNonChange}
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Recieved Quantity :</label>
              <input
                type="number"
                name="RecievedTotalQuantity"
                placeholder="Enter Total Recieved Quantity "
                value={PurchaseRecieveFormData1.RecievedTotalQuantity}
                required
                readOnly
                onChange={handleInputFormNonChange}
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType"> Recieved Quantity Amount :</label>
              <input
                type="number"
                name="RecievedQuantityAmount"
                placeholder="Enter  Recieved Quantity Amount "
                value={PurchaseRecieveFormData1.RecievedQuantityAmount}
                required
                readOnly
                onChange={handleInputFormNonChange}
                className='Stoct_Product_master_form_Input'
              />
            </div>
          </div>


          <div className='Stoct_Product_master_form_Save'>
            <button className=' Stoct_Product_master_form_Save_button' onClick={isedit ? handleUpdateNon : handleAddNon} >
              {isedit ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
      )
  }

        

        {datalist1.length !== 0 &&
          <div className='IP_grid'>
            <ThemeProvider theme={theme}>
              <div className='IP_grid_1'>
                <DataGrid
                  rows={datalist1.slice(page1 * pageSize, (page1 + 1) * pageSize)} // Display only the current page's data
                  columns={dynamicColumns1()} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange1}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=' Ip_data_grid'
                />
                {showdown1 > 0 && datalist1.length > 10 && (
                  <div className='IP_grid_foot'>
                    <button
                      onClick={() => setPage1((prevPage) => Math.max(prevPage - 1, 0))}
                      disabled={page1 === 0}
                    >
                      Previous
                    </button>
                    Page {page1 + 1} of {totalPages1}
                    <button
                      onClick={() =>
                        setPage1((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                      }
                      disabled={page1 === totalPages1 - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown1 !== 0 && datalist1.length !== 0 ? (
              ''
            ) : (
              <div className='IP_norecords'>
                <span>No Records Found</span>
              </div>
            )}
          </div>}
        {datalist1.length !== 0 &&
          <div className='Stoct_Product_master_form_Save'>
            <button className=' Stoct_Product_master_form_Save_button' onClick={handleSubmit}>
              Submit
            </button>
          </div>}

      </div>



    </>
  )
}

export default IndentRecieveMaster;