import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useSelector } from 'react-redux';


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});
const HandOverSummary = ( ) => {

    const userRecord=useSelector(state=>state.userRecord?.UserData)

    const [Select_namedata, setSelect_namedata] = useState([])

    const [formData, setFormData] = useState({
        BranchName: userRecord?.location,
        FromStaff: '',
        ToStaff: '',
        DateType: '',
        CurrentDate: '',
        FromDate: '',
        ToDate: '',
    });
    const [formDataPrint, setFormDataPrint] = useState(null);
    const [page, setPage] = useState(0);
    const [filteredRows, setFilteredRows] = useState([]);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'DateType') {
            const currentDate = new Date();
            const formattedDate = format(currentDate, 'yyyy-MM-dd');
            if (value === 'Current') {
                setFormData({
                    ...formData,
                    CurrentDate: formattedDate,
                    [name]: value
                });
            } else {
                setFormData({
                    ...formData,
                    CurrentDate: '',
                    [name]: value
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }



    };
    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = format(currentDate, 'dd-MM-yyyy');
        setFormData((prev) => ({
            ...prev,
            date: formattedDate
        }))

    }, [])

    const dynamicColumns = [
        { field: 'id', headerName: 'S.NO', width: 70 },
        { field: 'SCD_Date', headerName: 'Date', width: 100 },
        { field: 'Start_time', headerName: 'Shift Start', width: 100 },
        { field: 'End_time', headerName: 'Shift End', width: 100 },
        { field: 'Issue_from_Name', headerName: 'Issue From Name', width: 150 },
        { field: 'Issue_To_Name', headerName: 'Issue To Name', width: 150 },
        { field: 'Opening_Cash', headerName: 'Opening Cash', width: 120 },
        { field: 'Collected_Cash', headerName: 'Collected Cash', width: 120 },
        { field: 'Total_Cash', headerName: 'Total Cash', width: 120 },
        { field: 'Expenses_Cash', headerName: 'Expenses Cash', width: 120 },
        { field: 'HandOver_Cash', headerName: 'Hand Over Cash', width: 130 },

    ];


    useEffect(() => {

        axios.get(`https://clinic.vesoftometic.co.in/usercontrol/getuserregisterID_NAME?location=${userRecord?.location}`)
            .then((response) => {
                setSelect_namedata(response.data)
                console.log(response.data)
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])


    const handletoSearch = () => {
        const params = {
            BranchName: formData.BranchName,  // Replace with actual values or variables
            FromStaff: formData.FromStaff,
            ToStaff: formData.ToStaff,
            DateType: formData.DateType,
            CurrentDate: formData.CurrentDate,
            FromDate: formData.FromDate,
            ToDate: formData.ToDate
        }
        setFormDataPrint(params)
        if (params.DateType === '' && params.FromStaff === '' && params.ToStaff === '') {
            alert('Enter Data')
        }
        else {
            axios.get(`https://clinic.vesoftometic.co.in/Pettycash_link/get_shift_closing_data`, { params })
                .then((response) => {
                    console.log('111', response.data)
                    const A_data = response.data
                    const B_data = A_data.map((p, index) => ({
                        id: index + 1,
                        ...p
                    }))
                    setFilteredRows(B_data)
                    setFormData({
                        BranchName: 'Blossom',
                        FromStaff: '',
                        ToStaff: '',
                        DateType: '',
                        CurrentDate: '',
                        FromDate: '',
                        ToDate: '',
                    })
                })
                .catch((error) => {
                    console.error(error);
                });
        }

    }
    const handleExportToExcel = () => {
        if (filteredRows.length !== 0) {
            const columns = [
                { header: 'ID', dataKey: 'id' },
                { header: 'Date', dataKey: 'SCD_Date' },
                { header: 'Start Time', dataKey: 'Start_time' },
                { header: 'End Time', dataKey: 'End_time' },
                { header: 'Issue From', dataKey: 'Issue_from_Name' },
                { header: 'Issue To', dataKey: 'Issue_To_Name' },
                { header: 'Opening Amount', dataKey: 'Opening_Cash' },
                { header: 'Collected Amount', dataKey: 'Collected_Cash' },
                { header: 'Total Amount', dataKey: 'Total_Cash' },
                { header: 'Expense Amount', dataKey: 'Expenses_Cash' },
                { header: 'HandOver Amount', dataKey: 'HandOver_Cash' },
            ];

            const header = columns.map(col => col.header);


            const csv = [
                '\ufeff' + header.join(","), // BOM + header row first
                ...filteredRows.map(row => columns.map(col => row[col.dataKey])),
                '', // Empty row for spacing
                `Location: ${formDataPrint.BranchName}`,
                formDataPrint.DateType === 'Day' ?
                    `Date : ${formDataPrint.CurrentDate}` :
                    `From: ${formDataPrint.FromDate}  To: ${formDataPrint.ToDate}`,
                `Staff From: ${formDataPrint.FromStaff}`,
                `Staff To: ${formDataPrint.ToStaff}`
            ].join("\r\n");

            var data = new Blob([csv], { type: "text/csv" });
            saveAs(data, "Report.csv");
        } else {
            alert('No Data to Save')
        }
    };
    const handlePDF = () => {
        if (filteredRows.length !== 0) {
            const doc = new jsPDF();
            const columns = [
                { header: 'ID', dataKey: 'id' },
                { header: 'Date', dataKey: 'SCD_Date' },
                { header: 'Start Time', dataKey: 'Start_time' },
                { header: 'End Time', dataKey: 'End_time' },
                { header: 'Issue From', dataKey: 'Issue_from_Name' },
                { header: 'Issue To', dataKey: 'Issue_To_Name' },
                { header: 'Opening Amount', dataKey: 'Opening_Cash' },
                { header: 'Collected Amount', dataKey: 'Collected_Cash' },
                { header: 'Total Amount', dataKey: 'Total_Cash' },
                { header: 'Expense Amount', dataKey: 'Expenses_Cash' },
                { header: 'HandOver Amount', dataKey: 'HandOver_Cash' },
            ];
            const columnStyles = {
                id: { width: 10 },
                DCD_Date: { width: 30 },
                Start_time: { width: 30 },
                End_time: { width: 30 },
                Issue_from_Name: { width: 30 },
                Issue_To_Name: { width: 30 },
                Opening_Cash: { width: 30 },
                Collected_Cash: { width: 30 },
                Total_Cash: { width: 30 },
                Expenses_Cash: { width: 30 },
                HandOver_Cash: { width: 30 },
            };
            let additionalInfo = '';
            let additionalInfo1 = '';
            if (formDataPrint) {
                if (formDataPrint.DateType === 'Day') {
                    additionalInfo = `Location : ${formDataPrint.BranchName} | Date : ${formDataPrint.CurrentDate}`;
                    additionalInfo1 = `Staff From : ${formDataPrint.FromStaff}  | Staff To : ${formDataPrint.ToStaff} `;
                } else {
                    additionalInfo = `Location : ${formDataPrint.BranchName} | From : ${formDataPrint.FromDate}  |  To : ${formDataPrint.ToDate} `;
                    additionalInfo1 = `Staff From : ${formDataPrint.FromStaff}  | Staff To : ${formDataPrint.ToStaff} `;
                }
            }
            const pageWidth = doc.internal.pageSize.getWidth();
            doc.setFontSize(12);

            doc.text(additionalInfo, pageWidth - 10, 20, { align: 'right' });
            doc.text(additionalInfo1, pageWidth - 10, 10, { align: 'right' });
            doc.setFontSize(14);

            doc.autoTable({ columns, body: filteredRows, startY: 30, styles: columnStyles });

            const pdfBlob = doc.output('blob');
            saveAs(pdfBlob, 'data.pdf');
        } else {
            alert('No Data to save')
        }
    };

    const handlePrintToPDF = () => {
        if (filteredRows.length !== 0) {

            const doc = new jsPDF();
            const columns = [
                { header: 'ID', dataKey: 'id' },
                { header: 'Date', dataKey: 'SCD_Date' },
                { header: 'Start Time', dataKey: 'Start_time' },
                { header: 'End Time', dataKey: 'End_time' },
                { header: 'Issue From', dataKey: 'Issue_from_Name' },
                { header: 'Issue To', dataKey: 'Issue_To_Name' },
                { header: 'Opening Amount', dataKey: 'Opening_Cash' },
                { header: 'Collected Amount', dataKey: 'Collected_Cash' },
                { header: 'Total Amount', dataKey: 'Total_Cash' },
                { header: 'Expense Amount', dataKey: 'Expenses_Cash' },
                { header: 'HandOver Amount', dataKey: 'HandOver_Cash' },
            ];
            const columnStyles = {
                id: { width: 10 },
                DCD_Date: { width: 30 },
                Start_time: { width: 30 },
                End_time: { width: 30 },
                Issue_from_Name: { width: 30 },
                Issue_To_Name: { width: 30 },
                Opening_Cash: { width: 30 },
                Collected_Cash: { width: 30 },
                Total_Cash: { width: 30 },
                Expenses_Cash: { width: 30 },
                HandOver_Cash: { width: 30 },
            };
            let additionalInfo = '';
            let additionalInfo1 = '';
            if (formDataPrint) {
                if (formDataPrint.DateType === 'Day') {
                    additionalInfo = `Location : ${formDataPrint.BranchName} | Date : ${formDataPrint.CurrentDate}`;
                    additionalInfo1 = `Staff From : ${formDataPrint.FromStaff}  | Staff To : ${formDataPrint.ToStaff} `;
                } else {
                    additionalInfo = `Location : ${formDataPrint.BranchName} | From : ${formDataPrint.FromDate}  |  To : ${formDataPrint.ToDate} `;
                    additionalInfo1 = `Staff From : ${formDataPrint.FromStaff}  | Staff To : ${formDataPrint.ToStaff} `;
                }
            }
            const pageWidth = doc.internal.pageSize.getWidth();
            doc.setFontSize(12);

            doc.text(additionalInfo, pageWidth - 10, 20, { align: 'right' });
            doc.text(additionalInfo1, pageWidth - 10, 10, { align: 'right' });
            doc.setFontSize(14);


            doc.autoTable({ columns, body: filteredRows, startY: 30, styles: columnStyles });

            // Get the data URI of the PDF
            const pdfDataUri = doc.output('datauristring');

            // Open the PDF in a new window for printing
            const printWindow = window.open();
            printWindow.document.write(`<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`);
        } else {
            alert('No Data to Print')
        }
    };

    return (
        <div className='ShiftClosing_over'>
            <div className="ShiftClosing_Container">
                <div className="ShiftClosing_header">
                    <h3>Hand Over Summary</h3>
                </div>
                <div className="ShiftClosing_Invoice_container">

                    <div className="ShiftClosing_Con">
                        <div className="ShiftClosing_Con_row">
                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Branch Name <span>:</span></label>
                                <input
                                    type="text"
                                    name="BranchName"
                                    value={formData.BranchName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Date Type <span>:</span></label>
                                <select
                                    name="DateType"
                                    value={formData.DateType}
                                    onChange={handleChange}
                                >
                                    <option value=" ">-Select-</option>
                                    <option value="Current">Current Date</option>
                                    <option value="Customize">Customize</option>
                                </select>
                            </div>
                        </div>
                        {formData.DateType === 'Customize' &&
                            <div className="ShiftClosing_Con_row">
                                <div className="ShiftClosing_Con_column">
                                    <label htmlFor="">From Date <span>:</span></label>
                                    <input
                                        type="date"
                                        name="FromDate"
                                        value={formData.FromDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="ShiftClosing_Con_column">
                                    <label htmlFor="">To Date <span>:</span></label>
                                    <input
                                        type="date"
                                        name="ToDate"
                                        value={formData.ToDate}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>}
                        <div className="ShiftClosing_Con_row">
                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">From Staff<span>:</span></label>
                                <input
                                    name="FromStaff"
                                    value={formData.FromStaff}
                                    onChange={handleChange}
                                    list='issueFrom_1'
                                />
                                <datalist id="issueFrom_1">
                                    {Select_namedata.map((item, index) => (
                                        <option key={index} value={item.username}></option>
                                    ))}
                                </datalist>

                            </div>
                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">To Staff <span>:</span></label>
                                <input
                                    name="ToStaff"
                                    value={formData.ToStaff}
                                    onChange={handleChange}
                                    list='issueTo_2'
                                />
                                <datalist id="issueTo_2">
                                    {Select_namedata.map((item, index) => (
                                        <option key={index} value={item.username}></option>
                                    ))}
                                </datalist>

                            </div>
                        </div>


                    </div>
                </div>
                <div >
                    <button className="btn_1" onClick={handletoSearch}>Search</button>
                </div>
            </div>
            <div className='grid_1'>
                <ThemeProvider theme={theme}>
                    <div className='grid_1'>
                        <DataGrid
                            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                            columns={dynamicColumns} // Use dynamic columns here
                            pageSize={10}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            onPageChange={handlePageChange}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className='data_grid'
                        />
                        {showdown > 0 && filteredRows.length > 10 && (
                            <div className='IP_grid_foot'>
                                <button
                                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() =>
                                        setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                    }
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </div>
                </ThemeProvider>
                {showdown !== 0 && filteredRows.length !== 0 ? (
                    ''
                ) : (
                    <div className='IP_norecords'>
                        <span>No Records Found</span>
                    </div>
                )}
            </div>
            {filteredRows.length !== 0 &&
                <div className='PrintExelPdf'>
                    <button onClick={handleExportToExcel}>Save Exel</button>
                    <button onClick={handlePDF}> Save PDF</button>
                    <button onClick={handlePrintToPDF}>Print PDF</button>
                </div>
                }
        </div>
    )
}

export default HandOverSummary;

