import React, { useState, useEffect } from 'react';
// import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';

import axios from 'axios';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

const LeaveConsume = ({  userRecord }) => {

  const [leavecount,setLeaveCount] = useState({
  });
  const [page, setPage] = useState(0);
  const pageSize = 10;

  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const [columns] = React.useState([

    { field: "employeeid", headerName: "Employee ID", width: 120 },
    { field: "employeename", headerName: "Employee Name", width: 150 },
    { field: "leaveType", headerName: "Leave Type", width: 100 },
    { field: "fromdate", headerName: "From Date", width: 100 },
    { field: "todate", headerName: "To Date", width: 100 },
    { field: 'days', headerName: 'Days', width: 80 },
    { field: "reason", headerName: "Reason", width: 150 },
        {
            field: "status",
            headerName: "Status",
            width: 150
        },
        {
            field: "rejectstatus",
            headerName: "Reject Reason",
            width: 150
        },
       
    ]);

  const [leaveStatusrows, setLeaveStatusRows] = useState([]);

  const totalPages = Math.ceil(leaveStatusrows.length / 10);

  const fetchLeaveData = () => {
    const employeeid = userRecord.EmployeeId;
    fetch(`https://clinic.vesoftometic.co.in/HRmanagement/get_all_leave_register_by_employeeid?EmployeeID=${employeeid}&location=${userRecord.location}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            leaveType: userdata.LeaveType,
            fromdate: userdata.FromDate,
            todate: userdata.ToDate,
            days: userdata.days,
            reason: userdata.Reason,
            status: userdata.status,
            rejectstatus: userdata.rejectstatus,
          }));
          setLeaveStatusRows(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const showdown = leaveStatusrows.length;


  const [columns1] = React.useState([
    {field:'id', headerName:'Serial No', width:180},
    { field: "leavetype", headerName: "Leave Type", width: 190 },
    { field: "available", headerName: "Availed Leaves", width: 180 },
    { field: "remaining", headerName: "Remaining Leaves", width: 180 },

    ]);

    


// ... (rest of your component)

useEffect(() => {
  const employeeId = userRecord.EmployeeId;
  axios.get(`https://clinic.vesoftometic.co.in/HRmanagement/get_employee_leave_count?location=${userRecord.location}&EmployeeId=${employeeId}`)
    .then((response) => {
      const data = response.data;
      console.log('data',data)
      setLeaveCount({
        sickleave: data.sick_leave,
        casualleave: data.casual_leave,
        totalleave: data.total_leave,
        remainsick: data.remaining_sick,
        remaincasual: data.remaining_casual,
        remainleave: data.remaining_leave
      });
    })
    .catch((error) => {
      console.log(error);
    });
}, [userRecord]);

const [rows1, setRows1] = React.useState([]);

useEffect(() => {
  setRows1([
    {
      id: 1,
      leavetype: 'Sick Leave',
      available: leavecount.sickleave,
      remaining: leavecount.remainsick < 0 ? 'Limit Exceeded' : leavecount.remainsick,
    },
    {
      id: 2,
      leavetype: 'Casual Leave',
      available: leavecount.casualleave,
      remaining: leavecount.remaincasual < 0 ? 'Limit Exceeded' : leavecount.remaincasual,
    },
    {
      id: 3,
      leavetype: 'Total Leave',
      available: leavecount.totalleave,
      remaining: leavecount.remainleave < 0 ? 'Limit Exceeded' : leavecount.remainleave,
    },
  ]);
}, [leavecount]);





  return (
    <div>
      <ThemeProvider theme={theme}>
        <div className="grid_1">
          <DataGrid
            rows={leaveStatusrows.slice(page * pageSize, (page + 1) * pageSize)}
            columns={columns}
            pageSize={100}
            onSelectionModelChange={handleSelectionModelChange}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onPageChange={(params) => setPage(params.page)}
            hideFooterPagination
            hideFooterSelectedRowCount
            className="data_grid"
          />
          {showdown > 10 && (
            <div className="grid_foot">
              <button
                onClick={() =>
                  setPage((prevPage) => Math.max(prevPage - 1, 0))
                }
                disabled={page === 0}
              >
                Previous
              </button>
              Page {page + 1} of {totalPages}
              <button
                onClick={() =>
                  setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                }
                disabled={page === totalPages - 1}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </ThemeProvider>
      {showdown !== 0 && leaveStatusrows.length !== 0 ? (
        ""
      ) : (
        <div className="IP_norecords">
          <span>No Records Found</span>
        </div>
      )}

    <div style={{marginTop:'50px'}}>
      
<ThemeProvider theme={theme}>
        <div className="grid_1">
          <DataGrid
            rows={rows1}
            columns={columns1}
            pageSize={100}
            onSelectionModelChange={handleSelectionModelChange}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onPageChange={(params) => setPage(params.page)}
            hideFooterPagination
            hideFooterSelectedRowCount
            className="data_grid"
          />
        </div>
      </ThemeProvider>

    </div>

    </div>
  );
};

export default LeaveConsume;
