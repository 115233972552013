import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { saveAs } from 'file-saver';

const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: 'var(--ProjectColor)',
  
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
              {
                textAlign: "center",
                display: "flex !important",
                justifyContent: "center !important",
              },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: '0px !important',
            borderBottom: '1px solid  var(--ProjectColor) !important',
            display: "flex",
            justifyContent: "center",
            alignItems:'center'
          },
        },
      },
    },
  });


const DueReport = () => {

  

    const currentDate = new Date();
    const formattedDate = format(currentDate,'yyyy-MM-dd');

    // console.log(formattedDate)
    
    const [SearchByType,setSearchByType]=useState('')

    const [SearchLocation, setSearchLocation] = useState('')

    const [PatientdetailList, setPatientdetailList] = useState([])


    const [SearchPatientdetail, setSearchPatientdetail] = useState('')



    const [Location,setLocation]=useState([])

    const [page, setPage] = useState(0);
    const [filteredRows, setFilteredRows] = useState([]);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);


    console.log('111',filteredRows)


    useEffect(()=>{
        if(SearchByType !== '' && filteredRows.length !==0){
            setSearchLocation('')
            setSearchPatientdetail('')
            setFilteredRows([])
        }
        else{
            setSearchLocation('')
            setSearchPatientdetail('')
            setFilteredRows([])
        }
    },[SearchByType])



  





    useEffect(()=>{

        axios
      .get(`https://clinic.vesoftometic.co.in/usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data.map(p => p.location_name);

        console.log([...data,'CentralStore']);
        setLocation([...data,'CentralStore']);
      })
      .catch((error) => {
        console.log(error);
      });
        
    },[])


    useEffect(()=>{

        if(SearchByType === 'SinglePersion' && SearchLocation !== ''){

            const params ={
                Location:SearchLocation,
            }

            axios.get(`https://clinic.vesoftometic.co.in/FinanceMagement/get_Due_Name_reports_for_finance`, { params })
            .then((response) => {
                console.log('out',response.data)
    
                const A_data=response.data
                const B_data =A_data.map((p,index)=>({
                    id:index+1,
                    ...p
                  }))
                setPatientdetailList(B_data)
                
            })
            .catch((error) => {
                console.error(error);
            });
            

        }


       

    },[SearchByType,SearchLocation])

    

    const dynamicColumns = () => {
        if (SearchByType === 'ALL') {
            return [
                { field: 'id', headerName: 'S.NO', width: 100 },
                { field: 'PatientID', headerName: 'PatientID', width: 130 },
                { field: 'PatientName', headerName: 'PatientName', width: 180 },
                { field: 'PhoneNumber', headerName: 'PhoneNumber', width: 150 },
                { field: 'Remaining_Amount', headerName: 'Balance Due', width: 150 }
            ];
        } else {
            return [
                { field: 'id', headerName: 'S.NO', width: 50 },
                { field: 'CreatedAt', headerName: 'Date', width: 120 },
                { field: 'Location', headerName: 'Location', width: 150 },
                { field: 'BillingID', headerName: 'Document No', width: 150 },
                { field: 'ServiceType', headerName: 'Particulars / Narration', width: 150 },
                { field: 'SalesAmount', headerName: 'Sales Amount', width: 150 },
                { field: 'AmountReceived', headerName: 'Amount Received', width: 130 },
                { field: 'Findalltotal', headerName: 'Balance Amount', width: 130 },

            ];
        }
    };
    



    const handletoSearch=()=>{
       if(SearchByType !=='' && SearchLocation !==''){

       if( SearchByType === 'ALL'){
        const params={
            Location: SearchLocation,
            SearchByType:SearchByType
        }

        axios.get(`https://clinic.vesoftometic.co.in/FinanceMagement/get_Due_reports_for_finance`, { params })
        .then((response) => {
            // console.log('out',response.data)

            const A_data=response.data
            const B_data =A_data.map((p,index)=>({
                id:index+1,
                ...p
              }))
            setFilteredRows(B_data)
            
        })
        .catch((error) => {
            console.error(error);
        });
       }else{


        const [patientName, phoneNumber] = SearchPatientdetail.split(',')
        const params={
            Location: SearchLocation,
            SearchByType:SearchByType,
            patientName:patientName,
            phoneNumber:phoneNumber
        }

        console.log('out',params)


        axios.get(`https://clinic.vesoftometic.co.in/FinanceMagement/get_Due_reports_for_finance`, { params })
        .then((response) => {
            console.log('out',response.data)

            const A_data=response.data
            const B_data =A_data.map((p,index)=>({
                id:index+1,
                ...p
              }))
            setFilteredRows(B_data)
            
        })
        .catch((error) => {
            console.error(error);
        });

       }



       }else{

        alert('Please Fill The All Fields')
       }

    
       

    }

    const handleExportToExcel = () => {
        if (filteredRows.length !== 0) {
            const columns = SearchByType === 'ALL' ? 
            [
                { dataKey: 'id', header: 'S.NO'},
                { dataKey: 'PatientID', header: 'PatientID'},
                { dataKey: 'PatientName', header: 'PatientName'},
                { dataKey: 'PhoneNumber', header: 'PhoneNumber'},
                { dataKey: 'Remaining_Amount', header: 'Balance Due'}
            ]:
            [
                { dataKey: 'id', header: 'S.NO'},
                { dataKey: 'CreatedAt', header: 'Date'},
                { dataKey: 'Location', header: 'Location'},
                { dataKey: 'BillingID', header: 'Document No'},
                { dataKey: 'ServiceType', header: 'Particulars / Narration'},
                { dataKey: 'SalesAmount', header: 'Sales Amount'},
                { dataKey: 'AmountReceived', header: 'Amount Received'},
                { dataKey: 'Findalltotal', header: 'Balance Amount'}
            ]

            const header = columns.map(col => col.header);


            const csv = [
                '\ufeff' + header.join(","), // BOM + header row first
                ...filteredRows.map(row => columns.map(col => row[col.dataKey])),
                '', // Empty row for spacing
               
            ].join("\r\n");

            var data = new Blob([csv], { type: "text/csv" });
            saveAs(data, "AccountReceivable.csv");
        } else {
            alert('No Data to Save')
        }
    };
 
    



    return (
        <div className='ShiftClosing_over'>
            <div className="ShiftClosing_Container">
                <div className="ShiftClosing_header">
                    <h3></h3>
                </div>
                <div className="ShiftClosing_Invoice_container">
                   
                    <div className="ShiftClosing_Con">
                        <div className="ShiftClosing_Con_row">

                        <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Search By<span>:</span></label>
                                
                                <select
                                    name="SearchByType"
                                    value={SearchByType}
                                    onChange={(e) => { setSearchByType(e.target.value) }}
                                >
                                <option value=''>-Select-</option>
                                    <option value='ALL'>All Persion</option>
                                    <option value='SinglePersion'>Single Persion</option>
                                </select>
                            </div>
                        
                        <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Location <span>:</span></label>
                                <select
                                    name='Location'
                                    value={SearchLocation}
                                    onChange={(e)=>{setSearchLocation(e.target.value)}}
                                    disabled={SearchByType===''}
                                >
                                    <option value=''>Select Location</option>
                                    {Location.map((p) => (
                                        <option key={p} value={p}>
                                            {p.toUpperCase()}
                                        </option>
                                    ))}
                                </select>

                            </div>

                            
                           
                        </div>



                 {SearchByType=== 'SinglePersion' && SearchLocation !== ''  &&   <div className="ShiftClosing_Con_row">
                             
                        <div className="ShiftClosing_Con_column">
                        <label htmlFor="">Patient Name / Phone<span>:</span></label>
                        <input 
                            list="browsers"
                            name="SearchPatientdetail"
                            value={SearchPatientdetail}
                            onChange={(e)=>{setSearchPatientdetail(e.target.value)}}
                            disabled={SearchByType=== '' || SearchLocation === ''}
                        />
                            <datalist id="browsers">
                                {PatientdetailList.map((item, index) => (
                                    <option
                                        key={index}
                                        value={`${item.PatientName},${item.PhoneNumber}`}
                                    >
                                    </option>
                                ))}
                            </datalist>
                    </div>


                          
                            
                        
                    </div>}


                       
                    </div>
                </div>
                <div >
                    <button className="btn_1" onClick={handletoSearch}>Search</button>
                </div>
            </div>
            {filteredRows.length !== 0 && <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <div className='grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns()} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className='data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button 
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button 
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
            </div>}
            {filteredRows.length !== 0 &&
                <div className='PrintExelPdf'>
                    <button onClick={handleExportToExcel}>Save Exel</button>
                </div>
                }
        </div>
    )
}

export default DueReport;
