import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const SuplierPaidlist = () => {
  const [Select_namedata, setSelect_namedata] = useState([]);

  const [formData, setFormData] = useState({
    ProductType: "",
    SupplierName: "",
    SupplierCode: "",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });
  const [formDataPrint, setFormDataPrint] = useState(null);
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  useEffect(() => {
    axios
      .get("https://clinic.vesoftometic.co.in/SupplierMaster/get_supplier_Master_Name")
      .then((response) => {
        const data = response.data.filter((ele) => {
          return ele.SupplierType === formData.ProductType;
        });
        console.log("mm", data);
        setSelect_namedata(data);
      })
      .catch((error) => {
        console.error("Error fetching patients data:", error);
      });
  }, [formData.ProductType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");
      if (value === "Current") {
        setFormData({
          ...formData,
          CurrentDate: formattedDate,
          [name]: value,
        });
      } else {
        setFormData({
          ...formData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else if (name === "SupplierCode") {
      const Set_datas = Select_namedata.find((ele) => {
        return ele.SupplierCode === value;
      });
      if (Set_datas) {
        setFormData({
          ...formData,
          [name]: value,
          SupplierName: Set_datas.SupplierName,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
          SupplierName: "",
        });
      }
    } else if (name === "SupplierName") {
      const Set_datas = Select_namedata.find((ele) => {
        return ele.SupplierName === value;
      });
      if (Set_datas) {
        setFormData({
          ...formData,
          [name]: value,
          SupplierCode: Set_datas.SupplierCode,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
          SupplierName: "",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");
    setFormData((prev) => ({
      ...prev,
      date: formattedDate,
    }));
  }, []);

  const dynamicColumns = [
    { field: "id", headerName: "S No", width: 200 },
    { field: "ProductType", headerName: "Product Type ", width: 200 },
    { field: "SupplierCode", headerName: "Supplier Code ", width: 150 },
    { field: "SupplierName", headerName: "Supplier Name", width: 150 },
    { field: "GRN_Invoice_No", headerName: "GRN Invoice No ", width: 150 },
    { field: "GRN_Invoice_Date", headerName: "GRN Invoice Date ", width: 150 },
    {
      field: "Supplier_Bill_Date",
      headerName: "Supplier Bill Date ",
      width: 150,
    },
    { field: "GRN_Due_Date", headerName: "GRN Due Date  ", width: 150 },
    {
      field: "GRN_Invoice_Amount",
      headerName: "GRN Invoice Amount  ",
      width: 150,
    },
    { field: "GRN_Paid_Amount", headerName: "GRN Paid Amount ", width: 150 },
    {
      field: "GRN_Balance_Amount",
      headerName: "GRN Balance Amont",
      width: 150,
    },
    { field: "Paid_Amount", headerName: "Paid Amount  ", width: 150 },
    { field: "Balance_Amount", headerName: "Balance Amount  ", width: 150 },
    { field: "Bill_Paid_Date", headerName: "Bill Paid Date  ", width: 150 },
    { field: "Payment_Method", headerName: "Payment Method  ", width: 150 },
  ];

  const handletoSearch = () => {
    const params = {
      ProductType: formData.ProductType, // Replace with actual values or variables
      SupplierName: formData.SupplierName,
      SupplierCode: formData.SupplierCode,
      DateType: formData.DateType,
      CurrentDate: formData.CurrentDate,
      FromDate: formData.FromDate,
      ToDate: formData.ToDate,
    };
    setFormDataPrint(params);
    if (params.DateType !== "" && params.ProductType !== "") {
      axios
        .get(
          `https://clinic.vesoftometic.co.in/SupplierMaster/get_Full_supplierPay_detials_by_Date`,
          { params }
        )
        .then((response) => {
          console.log("111", response.data);
          const A_data = response.data;
          const B_data = A_data.map((p, index) => ({
            id: index + 1,
            ...p,
          }));
          setFilteredRows(B_data);
          setFormData({
            ProductType: "",
            SupplierName: "",
            SupplierCode: "",
            DateType: "",
            CurrentDate: "",
            FromDate: "",
            ToDate: "",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert("Please fill productType and DateType");
    }
  };
  const handleExportToExcel = () => {
    if (filteredRows.length !== 0) {
      const columns = [
        { dataKey: "id", header: "S No" },
        { dataKey: "ProductType", header: "Product Type " },
        { dataKey: "SupplierCode", header: "Supplier Code " },
        { dataKey: "SupplierName", header: "Supplier Name" },
        { dataKey: "GRN_Invoice_No", header: "GRN Invoice No " },
        { dataKey: "GRN_Invoice_Date", header: "GRN Invoice Date " },
        { dataKey: "Supplier_Bill_Date", header: "Supplier Bill Date " },
        { dataKey: "GRN_Due_Date", header: "GRN Due Date  " },
        { dataKey: "GRN_Invoice_Amount", header: "GRN Invoice Amount  " },
        { dataKey: "GRN_Paid_Amount", header: "GRN Paid Amount " },
        { dataKey: "GRN_Balance_Amount", header: "GRN Balance Amont" },
        { dataKey: "Paid_Amount", header: "Paid Amount  " },
        { dataKey: "Balance_Amount", header: "Balance Amount" },
        { dataKey: "Bill_Paid_Date", header: "Bill Paid Date" },
        { dataKey: "Payment_Method", header: "Payment Method" },
      ];

      const header = columns.map((col) => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...filteredRows.map((row) => columns.map((col) => row[col.dataKey])),
        "", // Empty row for spacing
        `ProductType: ${formDataPrint.ProductType}`,
        formDataPrint.DateType === "Current"
          ? `Date : ${formDataPrint.CurrentDate}`
          : `From: ${formDataPrint.FromDate}  To: ${formDataPrint.ToDate}`,
        `SupplierCode: ${formDataPrint.SupplierCode}`,
        `SupplierName: ${formDataPrint.SupplierName}`,
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, "Report.csv");
    } else {
      alert("No Data to Save");
    }
  };
  const handlePDF = () => {
    if (filteredRows.length !== 0) {
      const doc = new jsPDF();

      const columns = [
        { dataKey: "id", header: "S No" },
        { dataKey: "ProductType", header: "Product Type " },
        { dataKey: "SupplierCode", header: "Supplier Code " },
        { dataKey: "SupplierName", header: "Supplier Name" },
        { dataKey: "GRN_Invoice_No", header: "GRN Invoice No " },
        { dataKey: "GRN_Invoice_Date", header: "GRN Invoice Date " },
        { dataKey: "Supplier_Bill_Date", header: "Supplier Bill Date " },
        { dataKey: "GRN_Due_Date", header: "GRN Due Date  " },
        { dataKey: "GRN_Invoice_Amount", header: "GRN Invoice Amount  " },
        { dataKey: "GRN_Paid_Amount", header: "GRN Paid Amount " },
        { dataKey: "GRN_Balance_Amount", header: "GRN Balance Amont" },
        { dataKey: "Paid_Amount", header: "Paid Amount  " },
        { dataKey: "Balance_Amount", header: "Balance Amount" },
        { dataKey: "Bill_Paid_Date", header: "Bill Paid Date" },
        { dataKey: "Payment_Method", header: "Payment Method" },
      ];
      const columnStyles = {
        Id: { width: 10 },
        ProductType: { width: 30 },
        SupplierCode: { width: 30 },
        SupplierName: { width: 30 },
        GRN_Invoice_No: { width: 30 },
        GRN_Invoice_Date: { width: 30 },
        Supplier_Bill_Date: { width: 30 },
        GRN_Due_Date: { width: 30 },
        GRN_Invoice_Amount: { width: 30 },
        GRN_Paid_Amount: { width: 30 },
        GRN_Balance_Amount: { width: 30 },
        Paid_Amount: { width: 30 },
        Balance_Amount: { width: 30 },
        Bill_Paid_Date: { width: 30 },
        Payment_Method: { width: 30 },
      };

      let additionalInfo = "";
      let additionalInfo1 = "";
      if (formDataPrint) {
        if (formDataPrint.DateType === "Current") {
          additionalInfo = `ProductType : ${formDataPrint.ProductType} | Date : ${formDataPrint.CurrentDate}`;
          additionalInfo1 = `SupplierCode : ${formDataPrint.SupplierCode}  | SupplierName : ${formDataPrint.SupplierName} `;
        } else {
          additionalInfo = `ProductType : ${formDataPrint.ProductType} | From : ${formDataPrint.FromDate}  |  To : ${formDataPrint.ToDate} `;
          additionalInfo1 = `SupplierCode : ${formDataPrint.SupplierCode}  | SupplierName : ${formDataPrint.SupplierName} `;
        }
      }
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(12);

      doc.text(additionalInfo, pageWidth - 10, 20, { align: "right" });
      doc.text(additionalInfo1, pageWidth - 10, 10, { align: "right" });
      doc.setFontSize(14);

      doc.autoTable({
        columns,
        body: filteredRows,
        startY: 30,
        styles: columnStyles,
      });

      const pdfBlob = doc.output("blob");
      saveAs(pdfBlob, "data.pdf");
    } else {
      alert("No Data to save");
    }
  };

  const handlePrintToPDF = () => {
    if (filteredRows.length !== 0) {
      const doc = new jsPDF();
      const columns = [
        { dataKey: "id", header: "S No" },
        { dataKey: "ProductType", header: "Product Type " },
        { dataKey: "SupplierCode", header: "Supplier Code " },
        { dataKey: "SupplierName", header: "Supplier Name" },
        { dataKey: "GRN_Invoice_No", header: "GRN Invoice No " },
        { dataKey: "GRN_Invoice_Date", header: "GRN Invoice Date " },
        { dataKey: "Supplier_Bill_Date", header: "Supplier Bill Date " },
        { dataKey: "GRN_Due_Date", header: "GRN Due Date  " },
        { dataKey: "GRN_Invoice_Amount", header: "GRN Invoice Amount  " },
        { dataKey: "GRN_Paid_Amount", header: "GRN Paid Amount " },
        { dataKey: "GRN_Balance_Amount", header: "GRN Balance Amont" },
        { dataKey: "Paid_Amount", header: "Paid Amount  " },
        { dataKey: "Balance_Amount", header: "Balance Amount" },
        { dataKey: "Bill_Paid_Date", header: "Bill Paid Date" },
        { dataKey: "Payment_Method", header: "Payment Method" },
      ];
      const columnStyles = {
        Id: { width: 10 },
        ProductType: { width: 30 },
        SupplierCode: { width: 30 },
        SupplierName: { width: 30 },
        GRN_Invoice_No: { width: 30 },
        GRN_Invoice_Date: { width: 30 },
        Supplier_Bill_Date: { width: 30 },
        GRN_Due_Date: { width: 30 },
        GRN_Invoice_Amount: { width: 30 },
        GRN_Paid_Amount: { width: 30 },
        GRN_Balance_Amount: { width: 30 },
        Paid_Amount: { width: 30 },
        Balance_Amount: { width: 30 },
        Bill_Paid_Date: { width: 30 },
        Payment_Method: { width: 30 },
      };

      let additionalInfo = "";
      let additionalInfo1 = "";
      if (formDataPrint) {
        if (formDataPrint.DateType === "Current") {
          additionalInfo = `ProductType : ${formDataPrint.ProductType} | Date : ${formDataPrint.CurrentDate}`;
          additionalInfo1 = `SupplierCode : ${formDataPrint.SupplierCode}  | SupplierName : ${formDataPrint.SupplierName} `;
        } else {
          additionalInfo = `ProductType : ${formDataPrint.ProductType} | From : ${formDataPrint.FromDate}  |  To : ${formDataPrint.ToDate} `;
          additionalInfo1 = `SupplierCode : ${formDataPrint.SupplierCode}  | SupplierName : ${formDataPrint.SupplierName} `;
        }
      }
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(12);

      doc.text(additionalInfo, pageWidth - 10, 20, { align: "right" });
      doc.text(additionalInfo1, pageWidth - 10, 10, { align: "right" });
      doc.setFontSize(14);

      doc.autoTable({
        columns,
        body: filteredRows,
        startY: 30,
        styles: columnStyles,
      });

      // Get the data URI of the PDF
      const pdfDataUri = doc.output("datauristring");

      // Open the PDF in a new window for printing
      const printWindow = window.open();
      printWindow.document.write(
        `<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`
      );
    } else {
      alert("No Data to Print");
    }
  };

  return (
   
      <div className="appointment">
        <div className="h_head">
          <h4>Supplier Pay Summary</h4>
        </div>

        
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="">
                  Product Type <span>:</span>
                </label>

                <select
                  name="ProductType"
                  value={formData.ProductType}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="Medical">Medical</option>
                  <option value="Non_Medical">Non Medical</option>
                  <option value="Assets">Assets</option>
                  <option value="Stationary">Stationary</option>
                  <option value="Non_Stationary">Non Stationary</option>
                </select>
              </div>
             <div className="RegisForm_1">
                <label htmlFor="">
                  Date Type <span>:</span>
                </label>
                <select
                  name="DateType"
                  value={formData.DateType}
                  onChange={handleChange}
                >
                  <option value=" ">Select</option>
                  <option value="Current">Current Date</option>
                  <option value="Customize">Customize</option>
                </select>
              </div>
           
            
             <div className="RegisForm_1">
                <label htmlFor="">
                  Supplier Code<span>:</span>
                </label>
                <input
                  name="SupplierCode"
                  value={formData.SupplierCode}
                  onChange={handleChange}
                  list="issueFrom_1"
                />
                <datalist id="issueFrom_1">
                  {Select_namedata.map((item, index) => (
                    <option key={index} value={item.SupplierCode}></option>
                  ))}
                </datalist>
              </div>
             <div className="RegisForm_1">
                <label htmlFor="">
                  Supplier Name <span>:</span>
                </label>
                <input
                  name="SupplierName"
                  value={formData.SupplierName}
                  onChange={handleChange}
                  list="issueTo_2"
                />
                <datalist id="issueTo_2">
                  {Select_namedata.map((item, index) => (
                    <option key={index} value={item.SupplierName}></option>
                  ))}
                </datalist>
              </div>
           
            {formData.DateType === "Customize" && (
              <>
               <div className="RegisForm_1">
                  <label htmlFor="">
                    From Date <span>:</span>
                  </label>
                  <input
                    type="date"
                    name="FromDate"
                    value={formData.FromDate}
                    onChange={handleChange}
                  />
                </div>
               <div className="RegisForm_1">
                  <label htmlFor="">
                    To Date <span>:</span>
                  </label>
                  <input
                    type="date"
                    name="ToDate"
                    value={formData.ToDate}
                    onChange={handleChange}
                  />
                </div>
                </>
            )}
      
      </div>
        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handletoSearch}>
            Search
          </button>
        </div>
    
      <div className="grid_1">
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
              columns={dynamicColumns} // Use dynamic columns here
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="IP_grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      {filteredRows.length !== 0 && (
        <div className="PrintExelPdf">
          <button onClick={handleExportToExcel}>Save Exel</button>
          <button onClick={handlePDF}> Save PDF</button>
          <button onClick={handlePrintToPDF}>Print PDF</button>
        </div>
      )}
     </div>
  );
};

export default SuplierPaidlist;
